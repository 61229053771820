import React from "react";
import { Col, Image, Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  deleteFinalImage,
  getFinalImages,
  investById,
} from "../../reducer/investSlice"; // Modifica il percorso in base alla tua struttura
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css"; // Assicurati di importare il file CSS
import { useSession } from "../../middlewares/ProtectedRoutes";
import { toast } from "react-toastify";

const SingleImageFinal = ({ imageUrl, index, investId }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false); // Stato per la modale
  const session = useSession();
  React.useEffect(() => {
    Aos.init();
  }, [dispatch]);

  const handleDelete = async () => {
    console.log("Deleting image with investId:", investId); // Log dell'ID
    console.log("Image URL to delete:", imageUrl); // Log dell'URL dell'immagine
    if (!investId || !imageUrl) {
      console.error("Invest ID or image URL is undefined.");
      return;
    }
    try {
      // Attendi che l'immagine sia eliminata
      await dispatch(deleteFinalImage({ id: investId, imageUrl })).unwrap();
      // Aggiorna la lista delle immagini dopo la cancellazione
      dispatch(getFinalImages(investId));
      // Aggiorna i dettagli dell'investimento
      dispatch(investById(investId));
      toast.success("Immagine eliminata con successo", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.error("errore nell'eliminazione dell'immagine!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.error("Errore durante l'eliminazione dell'immagine:", error);
    }
    setShowModal(false); // Chiudi la modale dopo la cancellazione
  };

  const handleShowModal = () => setShowModal(true); // Mostra la modale
  const handleCloseModal = () => setShowModal(false); // Chiudi la modale

  return (
    <Col
      xs={12}
      sm={12}
      md={12}
      lg={6}
      className="mb-5 position-relative" // Aggiungi la classe 'position-relative'
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-easing="ease-in-sine"
    >
      <Image
        src={imageUrl}
        alt={`Final Image ${index + 1}`}
        className="img-fluid rounded shadow-sm dimension-image-gallery"
      />
      {session.role === "admin" ? (
        <>
          <Button
            onClick={handleShowModal} // Mostra la modale al clic
            variant="danger"
            className="delete-button-image-final " // Aggiungi una classe CSS per lo stile
          >
            🗑️
          </Button>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Conferma Cancellazione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Sei sicuro di voler eliminare questa immagine?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Annulla
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Elimina
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        ""
      )}
    </Col>
  );
};

export default SingleImageFinal;
