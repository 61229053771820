import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import article4Img from "../../asset/article4-img.png";

const Article4Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/articoli-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Investimenti immobiliari in italia nel 2023</title>
        <meta
          name="description"
          content="Analisi di come sono andati gli investimenti immobiliari in Italia nell’anno 2023."
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/investimenti-immobiliari-in-italia-2023"
        />
      </Helmet>

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article4Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Investimenti immobiliari in Italia, vediamo com'è andato l’anno 2023.
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center">
          Nel 2023 si sono registrati in Italia 6 miliardi di euro di
          investimenti capital markets, un volume in contrazione rispetto al
          2022. Lo dice l’ultimo report dell’Ufficio Studi di Gabetti secondo
          cui comunque il quarto trimestre 2023 ha visto un volume investito
          pari a 2,4 miliardi di euro e una variazione del +17% rispetto allo
          stesso trimestre del 2022, con un’inversione di tendenza in positivo
          rispetto ai primi nove mesi dell’anno.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Investimenti immobiliari e tassi Bce nel 2023
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Il 2023 secondo Gabetti conferma le previsioni di un anno in cui
            gli investimenti capital markets sono stati caratterizzati
            dall’instabilità del quadro economico e, soprattutto, dall’aumento
            dei tassi di interesse che hanno reso insostenibile, e quindi
            temporaneamente rimandato, molte operazioni di Asset allocation
            pianificate per il 2023. Tuttavia, anche grazie a una
            prima distensione della BCE sulla politica dei tassi, così come
            deciso nell’ultima riunione di politica monetaria, alla luce di una
            curva inflattiva che ha ormai imboccato la discesa verso la sua
            normalizzazione prevista nel 2025, nell’ultimo trimestre dell’anno
            gli investitori hanno ripreso fiducia posizionando il trimestre al
            +65% rispetto al precedente e al +17% rispetto allo stesso trimestre
            del 2022.
          </p>
        </Container>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Previsioni 2024 per gli investimenti immobiliari
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Il futuro degli investimenti capital markets dipenderà molto
          dai fondamentali economico- finanziari con cui si confronteranno gli
          investitori istituzionali, i cui contorni saranno caratterizzati
          dall’andamento dei tassi di interesse e anche dalla solidità dei
          fondamentali delle città italiane. Stando alle previsioni per il 2024,
          lo scenario per gli investimenti corporate sembrerebbe essere più
          favorevole rispetto al 2023, con le Asset class degli uffici e della
          logistica che consolideranno le loro performance e il living che,
          proprio per un rapporto sbilanciato tra domanda e offerta di prodotto,
          vedrà raggiungere un volume di investimenti che, molto probabilmente,
          potrà superare il record degli 1,1 miliardi raggiunti nel 2022.
        </p>
      </Container>
      <Container fluid className="my-5 bgBluOpacity05">
        <Container>
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Investimenti immobiliari, gli asset migliori
          </h2>
          <p className=" colortext-blunav fs-4 line-height2 fw-bold text-center">
            Guardando le diverse Asset class, ecco quali sono stati i migliori
            temi di investimento: <hr />{" "}
          </p>
          <div className="mb-4">
            <h2 className="colortext-blunav fs-1  fw-bold text-center">
              Logistica
            </h2>
            <p className=" colortext-blunav fs-5 line-height2 fw-bold text-center">
              La logistica ha totalizzato negli ultimi dodici mesi 1,5 miliardi
              di euro investiti, il 25% del totale, classificandosi quindi come
              l’Asset class preferita dagli investitori. Per questo settore la
              concentrazione delle operazioni si rileva per poco più dell’80%
              nel nord Italia, dove si localizzano nuovi progetti che prevedono
              la costruzione di edifici con certificazione Leed o Breeam.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="colortext-blunav fs-1  fw-bold text-center">
              Uffici
            </h2>
            <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
              Il settore degli uffici che totalizza nell’anno 1,2 miliardi di
              euro (il 20% del totale), con operazioni che si sono concentrate
              soprattutto nei principali mercati di Milano e Roma.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="colortext-blunav fs-1  fw-bold text-center">
              Hospitality
            </h2>
            <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
              Di una posizione poco inferiore agli uffici, si colloca il settore
              hospitality con 1,15 miliardi di euro investiti, il 19% del
              totale. Un volume che è frutto di un’operazione di oltre 200
              milioni di euro rilevata nel centro di Roma nell’ultimo trimestre
              dell’anno, oltre che da operazioni registrate a Milano e nelle
              regioni del Veneto e della Toscana. Negli ultimi anni si è resa
              chiara la necessità del comparto alberghiero di riposizionarsi
              alla luce di una domanda che è diventata sempre più esclusiva ed
              esigente. Un fenomeno che ha quindi caratterizzato nel 2023 le
              operazioni value-added e di rebranding.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="colortext-blunav fs-1  fw-bold text-center">
              Residenziale
            </h2>
            <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
              In termini di volumi di investimento segue il comparto living (€
              830 milioni) con il 14% del volume che ha riguardato
              prevalentemente nuovi sviluppi residenziali, con Milano al centro
              dell’interesse degli investitori. Un comparto che potenzialmente
              mostra tutte le caratteristiche per continuare a crescere, vista
              la carenza di prodotto rispetto a una domanda che è sempre più
              crescente, sia nel build to rent e build to sell, sia nelle varie
              forme di residenzialità collettiva come student e senior housing.
            </p>
          </div>
          <div className="align-items-start">
            <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
              <li>
                Manutenzione ordinaria: di giardini già esistenti e con
                regolarità periodica, non sostenendo alcuna innovazione;
              </li>
              <li>
                Acquisto di attrezzature specifiche: pale, picconi e tagliaerba;
              </li>
              <li>Interventi lavorativi da parte del proprietario.</li>
            </ul>
          </div>
          <div className="mb-4">
            <h2 className="colortext-blunav fs-1  fw-bold text-center">
              Retail
            </h2>
            <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
              Il settore retail con 630 milioni di euro ha pesato l’11% del
              totale investito. A questo volume hanno contribuito importanti
              operazioni di vendita riguardanti portafogli di immobili che si
              sono concentrati principalmente nella seconda parte dell’anno. A
              livello di localizzazione, nelle città primarie si concentrano
              operazioni di high street, mentre nelle città secondarie
              primeggiano operazioni su centri commerciali e warehouse.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="colortext-blunav fs-1  fw-bold text-center">
              Healthcare – assistenza sanitaria
            </h2>
            <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
              Il settore healthcare (€440 milioni, il 7% del totale investito)
              ha beneficiato di operazioni riguardanti ospedali, cliniche e
              residenze sanitarie assistenziali, che si sono concentrate, a
              livello di numero di transazioni, prevalentemente in Piemonte.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="colortext-blunav fs-1  fw-bold text-center">
              Mixed-use – uso misto
            </h2>
            <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
              Il comparto mixed-use (€140 milioni) ha pesato sul totale
              investito il 2%, infine l’alternative che include terreni, data
              center e centraline ha pesato il 2% sul totale con 126 milioni di
              euro.
            </p>
          </div>
        </Container>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article4Text;
