import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateInvest, investById } from "../../reducer/investSlice";
import { FaPencilAlt } from "react-icons/fa";
import { toast } from "react-toastify";

const PatchInvestimentModal = ({ invest }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    area: "",
    typeOfIntervent: "",
    duration: "",
    interventionAmount: "",
    roiEstimated: "",
    roiAnnuo: "",
    durationEffective: "",
    description: "",
    roiFinal: "",
    state: "",
    progress: "",
  });

  useEffect(() => {
    if (invest) {
      setFormData({
        name: invest.name,
        area: invest.area,
        typeOfIntervent: invest.typeOfIntervent,
        duration: invest.duration,
        interventionAmount: invest.interventionAmount,
        roiEstimated: invest.roiEstimated,
        durationEffective: invest.durationEffective,
        roiAnnuo: invest.roiAnnuo,
        description: invest.description,
        roiFinal: invest.roiFinal,
        state: invest.state,
        progress: invest.progress,
      });
    }
  }, [invest]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "progress") {
      const numericValue = Number(value);
      if (numericValue < 0 || numericValue > 100) {
        toast.error("Il progresso deve essere un numero tra 0 e 100.");
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Imposta il caricamento su true

    try {
      await dispatch(updateInvest({ id: invest._id, updates: formData }));
      await dispatch(investById(invest._id));
      handleClose();
      toast.success("Modifica investimento effettuata!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      console.error("Error updating investiment:", error);
      toast.error("Errore durante la modifica dell'investimento. Riprova.");
    } finally {
      setLoading(false); // Reimposta il caricamento su false
    }
  };

  return (
    <>
      <Button
        variant="primary"
        className="d-flex align-items-center justify-content-center me-4"
        onClick={handleShow}
      >
        <FaPencilAlt className="me-2" />
        Modifica investimento
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Modifica</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column gap-4">
          <Form className="d-flex flex-column gap-4" onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label className="fw-bold">Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci il nome"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formArea">
              <Form.Label className="fw-bold">Area</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci l'area"
                name="area"
                value={formData.area}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formTypeOfIntervent">
              <Form.Label className="fw-bold">Tipo di intervento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci tipo di intervento"
                name="typeOfIntervent"
                value={formData.typeOfIntervent}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formDuration">
              <Form.Label className="fw-bold">Durata stimata</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci la durata"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formInterventionAmount">
              <Form.Label className="fw-bold">Costo dell'intervento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci il costo dell'intervento"
                name="interventionAmount"
                value={formData.interventionAmount}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formRoiEstimated">
              <Form.Label className="fw-bold">ROI stimato</Form.Label>
              <Form.Control
                type="text"
                name="roiEstimated"
                value={formData.roiEstimated}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label className="fw-bold">Descrizione</Form.Label>
              <Form.Control
                as="textarea" // Modificato per utilizzare un'area di testo
                placeholder="Inserisci la descrizione"
                name="description"
                value={formData.description}
                onChange={handleChange}
                rows={7} // Imposta il numero di righe visibili
              />
            </Form.Group>

            <Form.Group controlId="formProgress">
              <Form.Label className="fw-bold">Progresso (%)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Inserisci il progresso"
                name="progress"
                value={formData.progress}
                min={0}
                max={100}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formRoiFinal">
              <Form.Label className="fw-bold">ROI FINALE</Form.Label>
              <Form.Control
                type="text"
                placeholder="ROI finale"
                name="roiFinal"
                value={formData.roiFinal}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formRoiAnnuo">
              <Form.Label className="fw-bold">ROI Annuo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci il ROI annuo"
                name="roiAnnuo"
                value={formData.roiAnnuo}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formDurationEffective">
              <Form.Label className="fw-bold">Durata effettiva</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci la durata effettiva"
                name="durationEffective"
                value={formData.durationEffective}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formState">
              <Form.Label className="fw-bold">
                Stato dell'investimento
              </Form.Label>
              <Form.Control
                as="select" // Specifica che si tratta di un input a tendina
                name="state"
                value={formData.state}
                onChange={handleChange}
              >
                <option value="ATTIVO">ATTIVO</option>
                <option value="IN CORSO">IN CORSO</option>
                <option value="CONCLUSO">CONCLUSO</option>
              </Form.Control>
            </Form.Group>

            <div className="d-flex align-items-center justify-content-center">
              <Button
                className="my-3"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? "Caricamento..." : "Salva Modifiche"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PatchInvestimentModal;
