import React from "react";
import { FaFilePdf } from "react-icons/fa6";

const PdfFilesList = ({ pdfFiles }) => {
  if (!pdfFiles || pdfFiles.length === 0) {
    return <p>Nessun file PDF disponibile per questo investimento.</p>;
  }

  return (
    <div>
      <h2 className="mt-3 mb-3 text-center">Businnes Plan e documenti</h2>
      <ul>
        {pdfFiles.map((pdf) => (
          <li key={pdf._id} className="mb-3">
            <a
              href={pdf.url}
              target="_blank"
              className="text-white text-decoration-none"
              rel="noopener noreferrer"
            >
              <FaFilePdf className="fs-5 me-2 red-logo" />
              {pdf.fileName}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PdfFilesList;
