import React from "react";
import Card from "react-bootstrap/Card";
import article19Img from "../../asset/article19-img.png";

import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Article19() {
  const navigate = useNavigate();

  const redirectArticle19 = () => {
    navigate("/variabili-da-considerare-quando-si-deve-vendere-casa");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article19Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Quanto tempo si impiega per vendere una casa? <br /> Le variabili da
            considerare
          </Card.Title>

          <Button onClick={redirectArticle19} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article19;
