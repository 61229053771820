import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateInvestImage } from "../../reducer/investSlice"; // Assicurati che il percorso sia corretto
import SpinnerLoading from "../SpinnerLoading";

const ChangeImageInvest = ({ show, handleClose, investId }) => {
  const [newImage, setNewImage] = useState(null);
  const [loading, setLoading] = useState(false); // Stato per lo spinner
  const dispatch = useDispatch();

  const handleImageChange = async () => {
    if (!newImage) {
      toast.error("Seleziona un'immagine da caricare!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    setLoading(true); // Mostra lo spinner

    // Nascondi lo spinner dopo 10 secondi
    setTimeout(() => {
      setLoading(false);
    }, 10000);
    // Debugging: Log del file immagine
    console.log("File selezionato:", newImage);

    const formData = new FormData();
    formData.append("image", newImage);

    try {
      // Debugging: Log dei dati inviati
      console.log("Dati FormData inviati:", formData.get("image"));

      const resultAction = await dispatch(
        updateInvestImage({ id: investId, image: newImage })
      );

      // Debugging: Log della risposta
      console.log("Risultato dell'azione:", resultAction);

      if (updateInvestImage.fulfilled.match(resultAction)) {
        handleClose(); // Chiude il modal
        toast.success("Immagine aggiornata con successo!", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        window.location.reload(); // Ricarica l'intera pagina
      } else {
        throw new Error("Errore durante l'aggiornamento dell'immagine");
      }
    } catch (error) {
      console.error(error);
      toast.error("Errore nell'aggiornare l'immagine!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cambia Immagine</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFile">
            <Form.Label>Seleziona una nuova immagine:</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                setNewImage(file);

                // Debugging: Log del file selezionato
                console.log("File selezionato:", file);
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annulla
        </Button>
        <Button variant="primary" onClick={handleImageChange}>
          Carica Immagine
        </Button>
      </Modal.Footer>
      {loading && (
        <div className="d-flex justify-content-center align-items-center my-3">
          <SpinnerLoading />

          <span className="mx-1"> Attendi qualche secondo..</span>
        </div>
      )}
    </Modal>
  );
};

export default ChangeImageInvest;
