// redux/emailSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// Thunk asincrono per inviare l'email
export const sendEmail = createAsyncThunk(
  "email/sendEmail",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post("/email/send-email", formData); // Aggiornato il percorso della route
      return response.data; // Restituisce il risultato dell'invio dell'email
    } catch (error) {
      toast.error("Errore durante l'invio della richiesta, riprova più tardi.");
      return rejectWithValue(
        error.response ? error.response.data : error.message
      ); // Restituisce l'errore
    }
  }
);

const emailSlice = createSlice({
  name: "email",
  initialState: {
    loading: false,
    success: false,
    error: null,
  },
  reducers: {
    resetEmailState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendEmail.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(sendEmail.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        toast.success("Richiesta inoltrata con successo!");
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Errore nell'invio dell'email";
      });
  },
});

export const { resetEmailState } = emailSlice.actions;
export default emailSlice.reducer;
