import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../reducer/userSlice"; // Assicurati di importare la tua azione
import { toast } from "react-toastify";
import SpinnerLoading from "../Components/SpinnerLoading"; // Importa il tuo componente spinner
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import { Button, Col, Container, Row } from "react-bootstrap";
import SfondoAnimato from "../Components/SfondoAnimatoOnde";
import "../Style/pageSecondary.css";
import { FaArrowDown } from "react-icons/fa";
import { FaExclamationTriangle } from "react-icons/fa";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // Icone dell'occhio

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Usa useNavigate per il redirect

  // Nuovo stato per mostrare/nascondere la password
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(resetPassword({ token, password }));
      toast.success("Password reimpostata con successo!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });

      // Reindirizza alla schermata di login dopo il successo
      setTimeout(() => {
        navigate("/login"); // Reindirizzamento alla pagina di login
      }, 3000); // Tempo di attesa per permettere al toast di essere visualizzato
    } catch (error) {
      toast.error("Errore nella reimpostazione della password!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <NavigationBar />
      <Container className="d-flex align-items-center justify-content-center flex-column VH80 mt-5">
        <div className="bg-white mb-3 shadow  p-2">
          <p className="fw-bold fs-5 text-center mt-5">
            Inserisci la tua nuova password e poi effettua il login
          </p>
          <p>
            <span className="d-flex align-items-center justify-content-center gap-2 fw-bold red-logo">
              {" "}
              <FaExclamationTriangle className="fs-5" />
              ATTENZIONE <FaExclamationTriangle className="fs-5" />
            </span>{" "}
            Il link ha una validità di un ora da quando e stato ricevuto via
            email, se è trascorso più tempo ripetere l'operazione da capo
          </p>
        </div>
        <Row>
          <Col className="p-5 bg-white rounded shadow mt-3">
            <h2>Nuova Password</h2>
            <form onSubmit={handleSubmit}>
              <input
                className="me-3 p-1 rounded shadow my-2"
                type={showPassword ? "text" : "password"}
                placeholder="Inserisci la nuova password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button
                variant=""
                className="ms-2"
                onClick={() => setShowPassword(!showPassword)}
                style={{ textDecoration: "none" }}
              >
                {showPassword ? (
                  <AiOutlineEye size={24} />
                ) : (
                  <AiOutlineEyeInvisible size={24} />
                )}
              </Button>
              <Button className="button-redLogo shadow" type="submit">
                Reimposta Password
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
      <Container className="d-flex align-items-center justify-content-center flex-column mt-5">
        <p className="text-center fw-bold fs-5">
          Se riscontri problemi non esitare a contattarci tramite il nostro
          numero verde gratuito per assistenza
        </p>
        <FaArrowDown className="fs-1" />

        <CallToActionNumberGreen />
      </Container>
      <Footer />
    </>
  );
};

export default ResetPassword;
