import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { patchSingleUpdate } from "../../reducer/updateInvestSlice";
import { toast } from "react-toastify";

const PatchUpdateModal = ({ show, handleClose, update }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false); // Stato per gestire il caricamento

  useEffect(() => {
    if (update) {
      setTitle(update.title);
      setText(update.text);
      setImage(null); // Reset dell'immagine all'apertura della modale
    }
  }, [update]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Inizia il caricamento

    const updatedData = new FormData();
    updatedData.append("title", title);
    updatedData.append("text", text);
    if (image) {
      updatedData.append("imageUpdate", image); // Aggiungi l'immagine se presente
    }

    try {
      await dispatch(
        patchSingleUpdate({ id: update._id, updateData: updatedData })
      ).unwrap();
      toast.success("Aggiornamento modificato con successo!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    } catch (error) {
      toast.error("Errore durante la modifica dell'aggiornamento!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    } finally {
      setLoading(false); // Fine caricamento
    }

    handleClose(); // Chiudi la modale dopo la modifica
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file); // Salva il file immagine
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica Aggiornamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Label>Titolo</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                disabled={loading} // Disabilita il campo durante il caricamento
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formText">
              <Form.Label>Testo</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={text}
                onChange={(e) => setText(e.target.value)}
                required
                disabled={loading} // Disabilita il campo durante il caricamento
              />
            </Form.Group>
            <Form.Group controlId="formImage">
              <Form.Label>Immagine</Form.Label>
              <Form.Control
                type="file"
                accept="image/,application/pdf*"
                onChange={handleImageChange}
                disabled={loading} // Disabilita il caricamento immagine
              />
            </Form.Group>
            <Button
              className="my-3"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" /> Attendi...
                </>
              ) : (
                "Salva Modifiche"
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PatchUpdateModal;
