import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";

import BarraLaterale from "../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";

import faqIntro from "../asset/faq.png";
import numberGreen2 from "../asset/numeroVerde1122SviluppoImmobiliare.png";
import SecurityInvestimentImg from "../asset/sicurityInvestiment.png";
import City from "../asset/city.png";
import ContactFormSmall from "../Components/ContactFormSmall";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";

import { Helmet } from "react-helmet";

const Faq = () => {
  const FaqPageStyle = {
    backgroundImage: `url(${faqIntro})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "3rem",
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Faq sul mondo degli investimenti immobiliari</title>
        <meta
          name="description"
          content="In questa sezione si trovano le domande più comuni riguardo le operazioni di trading immobiliare."
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/faq-investimenti-immobiliari"
        />
      </Helmet>

      <BarraLaterale />
      <Container
        fluid
        style={FaqPageStyle}
        className="img-zoom overflowHidden "
      >
        <div
          className="mt-5 pt-5 d-flex flex-column justify-content-center
         align-items-center  text-center fade-in  "
        >
          <h1 className="mt-5 fw-bold red-logo  ">
            FAQ INVESTIMENTI IMMOBILIARI
          </h1>
        </div>
      </Container>
      <Container fluid className="py-5 color-blunav ">
        <p className="  fs-3 text-white  text-center  p-lg-5">
          In questa sezione si trovano le domande più comuni riguardo le
          operazioni di trading immobiliare. Le risposte a queste domande
          offrono una panoramica approfondita dei benefici e delle opportunità
          offerte nel settore immobiliare.
        </p>
      </Container>

      <Container fluid className="overflowHidden">
        <Container>
          <Row>
            <Col
              className="text-white text-center d-flex flex-column justify-content-center align-items-center mb-5  rounded  "
              lg={6}
              md={12}
              sm={12}
              xs={12}
            >
              <p className="colortext-blunav fw-bold fs-5  line-height3 ">
                Qui è possibile trovare tutte le informazioni utili per prendere
                decisioni consapevoli e sicure per gli investimenti. Se hai
                domande specifiche che non trovano risposta nelle Faq, ti
                invitiamo a contattarci al nostro numero verde o tramite email
                all’indirizzo
              </p>
              <span
                className="text-center fw-bold  fs-5 mb-4 red-logo"
                data-aos="fade-up-right"
                data-aos-duration="2000"
              >
                info@1122sviluppoimmobiliare.com
              </span>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="d-flex justify-content-center align-items-center"
              data-aos="fade-up-left"
              data-aos-offset="200"
              data-aos-duration="2000"
            >
              <img
                src={numberGreen2}
                className="w-70 p.4"
                alt="img general contractor"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container
        fluid
        className=" d-flex justify-content-center align-items-center py-5 bgBluOpacity05 "
      >
        <Accordion
          defaultActiveKey="0"
          className="width100Smarphone width70Pc border-2px colortext-blunav "
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                Quanto dura in media un operazione immobiliare?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              Le nostre operazioni immobiliari, durano in media dai 6 ai 12 mesi
              e dai 12 ai 24mesi. Il settore immobiliare prevede tempi
              abbastanza lunghi di realizzazione.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                Possono esserci dei ritardi?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              Sì, è possibile; le previsioni che indichiamo su ciascun Business
              Plan sono sempre conservative, e nascono dalla nostra esperienza;
              può succedere però che un’operazione duri un po’ più del previsto,
              una meno del previsto, oppure esattamente il tempo previsto;
              questo dipende dai diversi fattori che si concatenano e possono
              portare con sé delle variabili (ad esempio: ritardi con pratiche
              edilizie o con le forniture di materiale, acquirente che compra
              con o senza mutuo, tribunale più veloce o un pò più lento, ecc…).
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                Quale è la quota minima per aderire alla partecipazione?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              La quota minima per l’investimento è di Euro 10.000,00, si possono
              acquistare più quote sino ad esaurimento. Potranno esserci
              operazioni dove la quota minima potrebbe essere più alta, ma ad
              ogni singola operazione comunicheremo quale sarà la quota minima
              di partecipazione, quante quote sono disponibili, ed i rendimenti
              previsti, le tempistiche, e tutto quello che si dovrà conoscere
              dell’operazione.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                Entro quanti giorni devo versare la quota di partecipazione?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              L’acquisizione della quota di partecipazione all’operazione
              immobiliare viene confermata con la sottoscrizione del contratto;
              il versamento della quota deve essere effettuato entro 3 giorni
              lavorativi dalla firma dello stesso.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                Dopo quanto tempo riceverò il capitale investito e l’utile
                derivante dall’operazione?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              Il rientro del capitale investito e dell’utile dipende dalla
              durata dell’operazione. L’operazione si intende conclusa dopo il
              rogito dell’ultimo immobile appartenente all’operazione. In
              seguito alla conferma dell’Iban, entro pochi giorni verranno
              emessi due bonifici:
              <ul>
                <li>Il primo per la parte del capitale</li>
                <li>Il secondo per la parte dell’utile netto</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                Il contratto verrà registrato? Ci sono dei costi?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              Per la partecipazione agli investimenti, sia come persone fisiche
              che come società, verrà sottoscritto un contratto di associazione
              in partecipazione. Tale contratto verrà regolarmente registrato
              dalla nostra società, e le spese sostenute saranno da dividere al
              50% tra le parti. I relativi costi della registrazione
              contrattuale saranno comunicati all’investitore, in fase
              contrattuale.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                L’utile potenziale indicato sul Business Plan è netto oppure
                lordo?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              L’utile potenziale che si trova indicato nelle nostre operazioni
              si intende lordo. E’ possibile partecipare sia come persona fisica
              sia come Società; la conseguente percentuale di tassazione da
              applicare sarà relativa alla propria specifica situazione. La
              partecipazione come persona fisica prevede l’applicazione del 26%
              di ritenuta a titolo d’imposta sul potenziale utile indicato. La
              partecipazione come Società prevede la ricezione dell’intera somma
              e concorrerà al formare la base imponibile della Società stessa
              pagando le imposte secondo quanto indicato dal proprio studio
              commercialista.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                Come funziona la tassazione?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              Gli utili di un’Operazione Finanziaria sono soggetti alla
              tassazione del 26%; attualmente la legge prevede che gli utili
              vengano tassati alla fonte, con ritenuta a titolo d’imposta. La
              nostra Società farà da sostituto d’imposta, pertanto tratterà il
              26% sugli utili dell’operazione e lo verserà all’erario a titolo
              d'imposta per conto dell’investitore (persona fisica). L’utile che
              si riceverà sarà già tassato alla fonte (se si partecipa come
              persona fisica), successivamente alla fine dell’anno verrà
              rilasciata la certificazione degli interessi percepiti e delle
              ritenute versate all’erario. Per quanto riguarda la tassazione
              come società, è necessario verificare la tassazione attraverso lo
              studio commercialista di riferimento.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                I redditi derivanti dall’investimento vanno a fare cumolo nella
                mia dichiarazione dei redditi?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              No. Come spiegato nella precedente Faq, gli utili vengono
              distribuiti al netto dell’imposta sostitutiva; essi non vengono
              ulteriormente tassati in sede di dichiarazione dei redditi in
              quanto la società fa da sostituto d’imposta.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                Posso rientrare del mio capitale prima della chiusura
                dell'operazione?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              Il contratto di associazione in partecipazione una volta
              sottoscritto, è vincolante per tutta la durata dell'operazione, e
              pertanto non è possibile rientrare del proprio capitale investito
              sino ad operazione conclusa.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                Come vengo aggiornato sull’andamento dell’operazione alla quale
                partecipo?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              Diventando un nostro investitore, entrerai a far parte del Club
              Investitori Premium, dove avrai l’opportunità di monitorare
              l’andamento delle operazioni tramite una Web App dedicata.
              All’interno dell’App troverai il Business Plan dettagliato, lo
              stato di avanzamento dei lavori, fotografie e report periodici,
              così da rimanere costantemente aggiornato.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="11">
            <Accordion.Header>
              <span className="fs-5 fw-bold colortext-blunav">
                Come faccio a partecipare ad una nuova operazione?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              è possibile registrarsi senza impegno alla nostra Invest App con
              l’inserimento dei propri dati al fine di partecipare ad una nostra
              operazione immobiliare; questo ti permetterà automaticamente di
              ricevere delle notifiche riguardanti le nostre opportunità di
              investimento, non appena un nuovo progetto sarà disponibile.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
      <ContactFormSmall />
      <Footer />
    </>
  );
};
export default Faq;
