import React, { useState } from "react";
import { ListGroup, Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  deleteParticipantFromInvest,
  investById,
} from "../../reducer/investSlice"; // Importa la funzione di rimozione e per aggiornare i dati

import { toast } from "react-toastify"; // Importa React Toastify

const SinglePartecipantInvest = ({ participant, index, investId }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false); // Stato per gestire la visibilità della modale

  const handleShow = () => setShowModal(true); // Mostra la modale
  const handleClose = () => setShowModal(false); // Chiudi la modale

  const handleRemove = async () => {
    try {
      await dispatch(
        deleteParticipantFromInvest({
          investId,
          participantId: participant._id,
        })
      ); // Rimuove il partecipante
      dispatch(investById(investId)); // Ricarica i dati dell'investimento dopo la rimozione
      toast.success("Partecipante rimosso con successo!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }); // Mostra il toast di successo
    } catch (error) {
      console.error("Errore durante la rimozione del partecipante:", error);
      toast.error("Errore durante la rimozione del partecipante!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }); // Mostra il toast di errore
    }
  };

  return (
    <>
      <ListGroup.Item className="d-flex justify-content-between align-items-center">
        <span>
          <span className="fw-bold colortext-blunav ">{index + 1}. </span>
          {participant.name} {participant.surname} (ID: {participant._id})
        </span>
        <Button variant="danger" onClick={handleShow}>
          Elimina
        </Button>
      </ListGroup.Item>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma eliminazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sei sicuro di voler rimuovere{" "}
          <span className=" fw-bold">
            {participant.name} {participant.surname}
          </span>{" "}
          dal progetto?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
          <Button variant="danger" onClick={handleRemove}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SinglePartecipantInvest;
