import React from "react";
import Card from "react-bootstrap/Card";
import article9Img from "../../asset/article9-img.png";

import { Button, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Article9() {
  const navigate = useNavigate();

  const redirectArticle9 = () => {
    navigate("/pre-delibera-del-mutuo");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article9Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Cos’è una pre-delibera di mutuo?
          </Card.Title>

          <Button onClick={redirectArticle9} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article9;
