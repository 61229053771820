import React from "react";
import Card from "react-bootstrap/Card";
import article7Img from "../../asset/article7-img.png";

import { Button, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Article7() {
  const navigate = useNavigate();

  const redirectArticle7 = () => {
    navigate("/investimenti-immobiliari-milano");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img
          className="img-card"
          variant="top"
          src={article7Img}
          alt="investimenti-Milano"
          title="investimenti-Milano"
          loading="lazy"
        />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Investire a Milano nel 2024 Ecco le migliori zone
          </Card.Title>

          <Button onClick={redirectArticle7} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article7;
