import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import article9Img from "../../asset/article9-img.png";

const Article9Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/articoli-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Cos’è una pre-delibera di mutuo</title>
        <meta
          name="description"
          content="Quali sono i vantaggi di una pre-delibera sul mutuo sia per l'acquirente che per il venditore"
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/pre-delibera-del-mutuo"
        />
      </Helmet>

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article9Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Cos’è una pre-delibera di mutuo?
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center">
          La pre-delibera di mutuo è un parere di fattibilità creditizia
          rilasciato dall’istituto bancario, ancora prima che il richiedente
          abbia individuato l’immobile da acquistare o per il quale fare una
          proposta d’acquisto. <hr /> Esempio pratico: sto cercando casa, non
          l’ho ancora trovata, voglio partecipare ad un asta immobiliare, vorrei
          essere sicuro che la banca mi erogherà il mutuo, e conseguentemente
          vorrei anche sapere l’importo massimo che potrei richiedere, sulla
          base della mia situazione reddituale e delle mie garanzie.
        </p>
      </Container>
      <Container fluid className="bg-white ">
        <Container className="py-5">
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            La banca analizzerà quindi la mia posizione, proprio come farebbe
            nel caso in cui fosse già presente un immobile. Dopo aver eseguito
            l’analisi creditizia mi comunicherà l’esito e, se positivo, mi
            comunicherà anche l’importo massimo che potrà erogarmi.
          </p>
        </Container>
      </Container>

      <Container fluid className="py-5 bgBluOpacity05">
        <Container>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            Ovviamente la pre-delibera ha un costo e non tutte le banche offrono
            questo servizio. Molto spesso esse richiedono che sia già stato
            individuato un immobile prima di partire con le pratiche. E si
            consideri anche che solitamente la pre-delibera ha una validità di
            circa 3-6 mesi, quindi in quel periodo dovrò impegnarmi seriamente a
            individuare l’immobile, altrimenti la banca dovrà procedere ad una
            nuova analisi creditizia nei miei confronti.
          </p>
        </Container>
      </Container>
      <Container className="py-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center mb-3">
          Quali sono i vantaggi della pre-delibera per l’acquirente?
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-3">
          <span className="bold-900 me-1">Certezza dell’erogazione:</span>O
          meglio, certezza legata alla propria situazione creditizia, componente
          fondamentale nella valutazione complessiva. Una volta individuato
          l’immobile la banca andrà ad esaminare solo la documentazione relativa
          all’immobile stesso, affidandosi ad un perito, e se anche questa sarà
          in regola allora procederà all’erogazione.
        </p>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-3">
          <span className="bold-900 me-1">
            Certezza dell’importo finanziabile:
          </span>
          Possibilità di effettuare una ricerca più mirata, perché so già che la
          banca mi finanzierà quella somma.
        </p>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-3">
          <span className="bold-900 me-1">Negoziazione:</span>
          Se mi presento dinanzi al venditore con una proposta d’acquisto
          vincolata al mutuo, ma ho già in mano una pre-delibera reddituale,
          sarò sicuramente avvantaggiato agli occhi del venditore, rispetto a
          chi invece non la possiede. E proprio per questo motivo potrei
          riuscire a negoziare un prezzo minore. Gli sto infatti dimostrando di
          essere finanziabile per quell’importo, pertanto l’erogazione del mutuo
          sarà vincolata solo alla regolarità dell’immobile.
        </p>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-3">
          <span className="bold-900 me-1">Tempistiche:</span>
          una volta individuato l’immobile, la tempistica per poter andare a
          rogito sarà minore, poiché come si diceva in precedenza una grossa
          parte del lavoro la banca l’ha già effettuato (ossia l’analisi
          reddituale) quindi sarà necessario analizzare solo l’immobile. Anche
          la possibilità di poter proporre tempistiche minori per andare a
          rogito fa aumentare il mio potere negoziale nei confronti del
          venditore, collegandoci al punto precedente.
        </p>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Quali sono i vantaggi per il venditore?
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          La pre-delibera può interessare chi sta cercando casa, ma anche chi la
          sta vendendo. Perché? I vantaggi per lui sono direttamente collegati
          ai vantaggi dell’acquirente, in particolare gli ultimi due, ossia la
          negoziazione e le tempistiche. <hr /> Io venditore, che mi vedo
          recapitare una proposta d’acquisto da un potenziale acquirente che
          abbia già una pre-delibera di mutuo, sono molto più sicuro che la
          vendita possa andare a buon fine. E con tempistiche molto più corte.
          Sarò quindi ben disposto nell’accettare la proposta che mi è arrivata.
        </p>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Conclusioni
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Pertanto, il consiglio, se state cercando casa realmente, è di
            andare in banca e richiedere questa pre-delibera. Potrete
            presentarvi davanti al venditore con molte più credenziali, e la
            probabilità di successo sarà più alta.
          </p>
        </div>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article9Text;
