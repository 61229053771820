import React, { useEffect } from "react";
import {
  Container,
  Col,
  Card,
  Row,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import "../Style/pageSecondary.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Slider from "react-infinite-logo-slider";
import { FaStar } from "react-icons/fa";

const RecensioniCarousel = () => {
  const RedirectRecensioni = () => {
    window.open(
      "https://www.google.com/search?sca_esv=91e3e3360bc36966&sca_upv=1&sxsrf=ADLYWILlvFeN3BE-0Ax3c9CvDUevsFk4ag:1726149800483&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7dcyqB7IQBIUS3nbNTG9oWssKkcE_XfhUTgN-tWtPiaEI1pXLXEo1xxQXmDMA-XUuuI4QeNQQI2jYeCQljO6e_3XjrwfmvbXzmU7ahHDeLqIclWbcnUCksN-P2A447umnYfIkThQ&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7sU1ceLLaAL56VW_4_wxfxFSdTQuzCXo5_xT7pWfnmRrK4zH88rSmvy-wm58k8LMfaAoqlU%3D&q=11.22+Sviluppo+Immobiliare+S.r.l.+Recensioni&sa=X&ved=2ahUKEwizjr-Nyb2IAxURwAIHHbzAK8oQ3PALegQIRhAF&biw=1920&bih=945&dpr=1",
      "_blank"
    );
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <div className="  d-flex align-items-center justify-content-center my-5 fs-1 fw-bold gap-4 colortext-blunav">
        <FaStar className="colorStarIcon" />
        Recensioni
        <FaStar className="colorStarIcon" />
      </div>
      <Container>
        <Row className="d-flex align-items-top justify-content-center">
          <Slider width="300px" duration={30} pauseOnHover={true}>
            <Slider.Slide>
              <div
                className="review-box text-center pointer color-blunav rounded shadow p-4 text-white mt-4 d-flex align-items-center justify-content-between flex-column"
                onClick={RedirectRecensioni}
              >
                <p className="text-center fw-bold">
                  Angelo Laforgia
                  <hr />
                </p>
                <p className="text-center text-truncate-multiline">
                  Ho avuto il piacere di collaborare in una loro iniziativa
                  immobiliare. Professionisti seri, puntuali e sicuramente
                  continuerò a collaborarci.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                </div>
              </div>
            </Slider.Slide>

            <Slider.Slide>
              <div
                className="review-box text-center pointer color-blunav rounded shadow p-4 text-white mt-4 d-flex align-items-center justify-content-between flex-column"
                onClick={RedirectRecensioni}
              >
                <p className="text-center fw-bold">
                  Dario Fabris <hr />
                </p>
                <p className="text-center text-truncate-multiline">
                  Ho partecipato al progetto della 11.22 Sviluppo Immobiliare e
                  voglio sottolineare la trasparenza e l’affidabilità dei
                  progetti proposti, e la ottima redditività. Continuate così!!
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                </div>
              </div>
            </Slider.Slide>

            <Slider.Slide>
              <div
                className="review-box text-center pointer color-blunav rounded shadow p-4 text-white mt-4 d-flex align-items-center justify-content-between flex-column"
                onClick={RedirectRecensioni}
              >
                <p className="text-center fw-bold">
                  Luca Manca <hr />
                </p>
                <p className="text-center text-truncate-multiline">
                  Il titolare è un professionista con lunga esperienza nel
                  settore, estremamente competente ed affidabile; la mission
                  della società è molto chiara e rigorosa: effettuare
                  investimenti immobiliari con la tecnica del flipping, avendo
                  cura della massimizzazione dell'efficienza energetica e
                  dell'impatto ambientale. La strategia di individuazione degli
                  investimenti target è molto chiara e definita a priori.
                  Partner ideale per chi vuole avvicinarsi a questa tipologia di
                  investimenti ad alto rendimento senza incorrere in rischi
                  inaspettati, e con un Business Plan ben definito fin
                  dall'inizio.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                </div>
              </div>
            </Slider.Slide>

            <Slider.Slide>
              <div
                className="review-box text-center pointer color-blunav rounded shadow p-4 text-white mt-4 d-flex align-items-center justify-content-between flex-column"
                onClick={RedirectRecensioni}
              >
                <p className="text-center fw-bold">
                  Giuseppe Grimaldi
                  <hr />
                </p>
                <p className="text-center text-truncate-multiline">
                  Recentemente ho concluso un investimento immobiliare con la
                  11.22 Sviluppo Immobiliare non potrei essere più soddisfatto
                  dell'esperienza. L'intero processo è stato gestito in modo
                  impeccabile, con grande professionalità. Ho ricevuto
                  un'assistenza chiara e puntuale, che mi ha permesso di
                  affrontare il tutto in serenità.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                </div>
              </div>
            </Slider.Slide>

            <Slider.Slide>
              <div
                className="review-box text-center pointer color-blunav rounded shadow p-4 text-white mt-4 d-flex align-items-center justify-content-between flex-column"
                onClick={RedirectRecensioni}
              >
                <p className="text-center fw-bold">
                  Enrico
                  <hr />
                </p>
                <p className="text-center text-truncate-multiline">
                  Ho investito in uno dei loro progetti immobiliari, e posso
                  dire che è stata una delle migliori decisioni che abbia mai
                  preso. Sin dai primi contatti l’azienda si è dimostrata
                  disponibile e trasparente, fornendomi tutte le informazioni
                  necessarie, e rispondendo in modo puntuale ed esaustivo ad
                  ogni mia domanda. La consiglio a tutti coloro che come me si
                  sono affacciati a questo mondo con poca conoscenza.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                </div>
              </div>
            </Slider.Slide>

            <Slider.Slide>
              <div
                className="review-box text-center pointer color-blunav rounded shadow p-4 text-white mt-4 d-flex align-items-center justify-content-between flex-column"
                onClick={RedirectRecensioni}
              >
                <p className="text-center fw-bold">
                  Giorgia Falanga
                  <hr />
                </p>
                <p className="text-center text-truncate-multiline">
                  Abbiamo scelto 11.22 Sviluppo Immobiliare S.r.l. per
                  ristrutturare una vecchia casa di corte. Da subito abbiamo
                  interagito col titolare che ha risposto con disponibilità e
                  competenza ad ogni nostra esigenza. La ristrutturazione ha
                  rispettato i tempi e le nostre richieste, restituendoci un
                  risultato ancora migliore di quello immaginato. Azienda
                  trasparente, affidabile e puntuale grazie alla professionalità
                  e alla cura dei dettagli del responsabile della commessa e del
                  suo team! Ottima esperienza, consigliata vivamente a chiunque
                  voglia ristrutturare senza stress!
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                </div>
              </div>
            </Slider.Slide>

            <Slider.Slide>
              <div
                className="review-box text-center pointer color-blunav rounded shadow p-4 text-white mt-4 d-flex align-items-center justify-content-between flex-column"
                onClick={RedirectRecensioni}
              >
                <p className="text-center fw-bold">
                  Mirko Rovedatti
                  <hr />
                </p>
                <p className="text-center text-truncate-multiline">
                  Fin dalla prima chiamata, il team si è dimostrato
                  professionale, competente e molto alle mie esigenze. Non sono
                  un investitore professionista ma loro mi hanno aiutato a
                  comprendere come poter investire senza problemi ed in modo
                  redditizio nel mondo dell'immobiliare. Grazie ancora.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                  <FaStar className="colorStarIcon" />
                </div>
              </div>
            </Slider.Slide>

            {/* Aggiungi altre recensioni qui */}
          </Slider>
        </Row>
      </Container>
    </>
  );
};

export default RecensioniCarousel;
