import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { CgProfile } from "react-icons/cg";
import RegistrationModal from "./UserBackendComponents/RegistrationModal";
import "../Style/pageSecondary.css";
import { useSession } from "../middlewares/ProtectedRoutes";
import LogoutModal from "./LogoutModal";
import { IoBarChartSharp } from "react-icons/io5";
import { FaChartPie } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import ButtonAdminControl from "./UserBackendComponents/ButtonAdminControl";
import { FaUser } from "react-icons/fa";
import DownloadPDFButton from "./DownloadPDFButton";
import LibroBanner from "../asset/LibroBannerSito.png";
import avatarVuotoImg from "../asset/img-avatarVuoto.jpg";

function OffCanvasProfile() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const redirectLoginOnClick = () => {
    navigate("/login");
    window.scrollTo(0, 0);
  };

  const redirectProfileOnClick = () => {
    navigate("/profile");
    window.scrollTo(0, 0);
  };

  const redirectBachecaInvestimentiOnClick = () => {
    navigate("/bacheca-Investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  const session = useSession();

  return (
    <>
      <Button className="button-redLogo r" onClick={handleShow}>
        {/* <CgProfile className="fs-3" /> */}
        {session ? (
          <div className="d-flex align-items-center justify-content-center ">
            <p className="m-0 p-0 ">
              {" "}
              <FaUser className="fs-5 me-2" />
              Profilo
            </p>
          </div>
        ) : (
          <p className="m-0 p-0">Registrati/Accedi</p>
        )}
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center justify-content-center text-center">
            {session ? (
              <LogoutModal />
            ) : (
              <div className="d-flex align-items-center justify-content-center text-center">
                <RegistrationModal className="" />
                <Button
                  onClick={redirectLoginOnClick}
                  className=" ms-3  button-redLogo"
                >
                  Login
                </Button>
              </div>
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="colortext-blunav">
          {session ? (
            <div>
              <h6 className="mb-5 text-center fs-4">
                DASHBOARD PERSONALE <hr />
              </h6>

              <div className="d-flex gap-3 align-items-center justify-content-center mb-2">
                <img
                  src={session.avatar ? session.avatar : avatarVuotoImg}
                  alt="avatar-img"
                  className="avatarImg shadow"
                />
                <div>
                  <p className="fw-bold fs-5">
                    {session.name} {session.surname}
                  </p>
                  <p className="fst-italic red-logo text-center">
                    {session.role}
                  </p>
                </div>
              </div>
              {session.role === "investitore" ? (
                ""
              ) : session.role === "admin" ? (
                <>
                  <ButtonAdminControl />
                  <p>
                    <hr />
                  </p>
                </>
              ) : null}
              <Button
                onClick={redirectBachecaInvestimentiOnClick}
                className="d-flex align-items-center justify-content-start mt-3 fs-5 button-redLogo"
              >
                <IoBarChartSharp className="fs-5 me-3" />
                Area investimenti
              </Button>
              <Link className="decorationNone" to={`/profile/${session.id}`}>
                <Button
                  onClick={redirectProfileOnClick}
                  className="d-flex align-items-center justify-content-start mt-3 fs-5 button-redLogo"
                >
                  <FaUser className="fs-5 me-3" />
                  Il mio profilo
                </Button>
              </Link>
              <div className=" my-5 d-flex align-items-center justify-content-start">
                <DownloadPDFButton />
              </div>
            </div>
          ) : (
            <div>
              <p className="fw-bold fs-5 text-center colortext-blunav">
                Vuoi accedere ai servizi da investitore premium e rimanere
                sempre aggiornato sulle tue possibilità di guadagno? <br />
                <br />
              </p>
              <p className="text-center mt-5 colortext-blunav">
                <span className="fw-bold">Registrati gratuitamente</span> e non
                perderti questa occasione unica di scaricare il nostro eBook,
                che ti aiuterà ad apprendere le basi fondamentali per iniziare a
                guadagnare in maniera completamente passiva con gli investimenti
                immobiliari.
              </p>
              <div className="d-flex align-items-center justify-content-center">
                <img src={LibroBanner} alt="" className="w-70 mt-2  " />
              </div>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvasProfile;
