import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import BarraLaterale from "../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";

import RiskIntro from "../asset/bg-InfoRischi.png";
import numberGreen2 from "../asset/numeroVerde1122SviluppoImmobiliare.png";

import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";

const InformationNoResp = () => {
  const navigate = useNavigate();

  const redirectJobOnClick = () => {
    navigate("/lavoraConNoi");
    window.scrollTo(0, 0);
  };

  const ContactIntroStyle = {
    backgroundImage: `url(${RiskIntro})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "3rem",
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />

      <Container
        fluid
        style={ContactIntroStyle}
        className="img-zoom overflowHidden "
      >
        <div
          className="mt-5 pt-5 d-flex flex-column justify-content-center
         align-items-center  text-center fade-in  "
        >
          <h1 className="mt-5 fw-bold red-logo  ">INFORMATIVA SUI RISCHI</h1>
        </div>
      </Container>
      <Container fluid className="py-5 color-blunav ">
        <p className="  fs-5 text-white  text-center">
          Questo messaggio non è un&#39;offerta di titoli o un invito a fare un
          investimento. Nulla di quanto contenuto nel presente documento deve
          essere considerato come una garanzia, un'assicurazione o una
          dichiarazione. Eventuali rendimenti target, rendimenti attesi o
          proiezioni probabilistiche non sono garantiti e potrebbero non
          riflettere la performance futura effettiva. Si prega di fare
          riferimento all'opportunità di investimento e al memorandum per
          ulteriori informazioni, rischi ed esclusioni di responsabilità. Non
          consulenza: la presente comunicazione non deve essere interpretata
          come consulenza in materia di investimenti, fiscale o legale in
          relazione all'argomento in questione; Gli investitori devono
          richiedere la propria consulenza legale o di altro tipo professionale.
          <hr />
          Il contenuto e i documenti disponibili sul sito (collettivamente, il
          "Contenuto") sono stati redatti da 11.22 Sviluppo Immobiliare S.r.l
          con l'unico scopo di fornire all'utente informazioni preliminari
          relative a 11.22 Sviluppo Immobiliare S.r.l e alla sua strategia di
          investimento nel settore immobiliare (la "Strategia di investimento"),
          alcune delle quali sono riservate esclusivamente a investitori
          qualificati/professionali come definiti ai sensi delle legge Italiana
          ed Europea applicabile. Il Contenuto non costituisce un'offerta, una
          pubblicità o una consulenza di investimento al pubblico, una
          sollecitazione all'acquisto o all'investimento in qualsiasi proprietà
          immobiliare o alla partecipazione alla Strategia di investimento. Il
          Contenuto viene divulgato solo a scopo informativo.
          <hr />
          La società 11.22 Sviluppo Immobiliare S.r.l ha compiuto ogni
          ragionevole sforzo per garantire che il Contenuto sia accurato e
          completo in tutti gli aspetti significativi, 11.22 Sviluppo
          Immobiliare S.r.l non fornisce alcuna garanzia e declina ogni
          responsabilità in merito all'accuratezza o alla completezza del
          Contenuto in qualsiasi momento. Un investimento ai sensi della
          Strategia d'investimento è illiquido e comporta rischi significativi e
          può comportare la perdita parziale o totale del capitale investito.
          Prima di intraprendere decisioni di investimento, bisognerebbe
          considerare attentamente la propria esperienza nel campo degli
          investimenti, la propria personale situazione finanziaria, ed i propri
          obbiettivi di investimento, ed il livello di tolleranza al rischio. Il
          potenziale investitore visiona il sito di 11.22 Sviluppo Immobiliare
          S.r.l e tutti i Contenuti, i dati e le informazioni, comprese in
          particolare le proiezioni, le previsioni e le dichiarazioni
          previsionali, esclusivamente a proprio rischio. Accedendo e
          utilizzando il sito di 11.22 Sviluppo Immobiliare S.r.l e le sue
          pagine, l'utente accetta e riconosce l’informativa sui rischi
          comunicata.
        </p>
      </Container>

      <CallToActionNumberGreen />

      <Footer />
    </>
  );
};
export default InformationNoResp;
