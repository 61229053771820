import React from "react";
import NavigationBar from "../Components/NavigationBar";
import Intro from "../Components/Intro";
import ChiSiamo from "../Components/ChiSiamo";
import BarraLaterale from "../Components/BarraLaterale";

import Footer from "../Components/Footer";
import Mission from "../Components/Mission";
import ContactFormSmall from "../Components/ContactFormSmall";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import { Helmet } from "react-helmet";
import InvestBoardHome from "../Components/InvestBoardHome";
import ArticoliGiornalistici from "../Components/ArticoliGiornalistici";
import RecensioniCarousel from "../Components/RecensioniCarousel";

const Homepage = () => {
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>1122 sviluppo immobiliare</title>
        <meta
          name="description"
          content="la 11.22 sviluppo immobiliare S.r.l. e una società di investimenti immobiliare che punta a far generare ai propri investitori una rendita passiva annua"
        />
        <link rel="canonical" href="https://www.1122sviluppoimmobiliare.com/" />
      </Helmet>
      <BarraLaterale />
      <Intro />
      <ChiSiamo />
      <Mission />
      <InvestBoardHome />
      <ArticoliGiornalistici />
      <RecensioniCarousel />
      <CallToActionNumberGreen />
      <ContactFormSmall />

      <Footer />
    </>
  );
};

export default Homepage;
