import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../reducer/userSlice"; // Assicurati di importare la tua azione
import { toast } from "react-toastify";
import SpinnerLoading from "../Components/SpinnerLoading"; // Importa il tuo componente spinner
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import { Button, Col, Container, Row } from "react-bootstrap";
import SfondoAnimato from "../Components/SfondoAnimatoOnde";
import "../Style/pageSecondary.css";
import { FaArrowDown } from "react-icons/fa";

const ResetPasswordRequest = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false); // Stato per gestire il caricamento
  const dispatch = useDispatch();
  const status = useSelector((state) => state.users.status); // Ottieni lo stato dal slice

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Attiva lo spinner
    try {
      const response = await dispatch(forgotPassword(email));
      // Controlla se la risposta è andata a buon fine
      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Email inviata per il reset della password!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      } else {
        // Messaggio di errore generico
        toast.error("Email inserita non valida.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      // Messaggio di errore generico
      toast.error("Si è verificato un errore. Riprova più tardi.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    } finally {
      setLoading(false); // Disattiva lo spinner
    }
  };

  return (
    <>
      <NavigationBar />
      <Container className="d-flex align-items-center justify-content-center flex-column VH80 mt-5">
        <div className="bg-white mb-3 shadow  p-2">
          <p className="fw-bold fs-5 text-center mt-5">
            Segui i seguenti passaggi per richiedere il link per reimpostare la
            password
          </p>
          <ol className="mb-4">
            <li>
              Inserisci la tua email con la quale ti sei registrato ed invia
            </li>
            <li>
              Controlla la tua email e clicca il link che ti portera al
              passaggio successivo dove potrai reimpostare la password
            </li>
          </ol>
        </div>

        <Row>
          <Col className=" p-5 bg-white rounded shadow ">
            <h2 className="mb-3">Reset Password</h2>
            <div className="d-flex align-items-center justify-content-center ">
              <form onSubmit={handleSubmit}>
                <input
                  className="me-3 p-1 rounded shadow"
                  type="email"
                  placeholder="Inserisci la tua email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <Button
                  className="button-redLogo shadow"
                  type="submit"
                  disabled={loading}
                >
                  Invia
                </Button>
                {loading && <SpinnerLoading />}{" "}
                {/* Mostra lo spinner se loading è true */}
              </form>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="d-flex align-items-center justify-content-center flex-column mt-5">
        <p className="text-center fw-bold fs-5">
          Se riscontri problemi non esitare a contattarci tramite il nostro
          numero verde gratuito per assistenza
        </p>
        <FaArrowDown className="fs-1" />

        <CallToActionNumberGreen />
      </Container>
      <Footer />
    </>
  );
};

export default ResetPasswordRequest;
