import React, { useState } from "react";
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // Icone dell'occhio
import SfondoAnimatoOnde from "../Components/SfondoAnimatoOnde";
import Footer from "../Components/Footer";
import NavigationBar from "../Components/NavigationBar";

const Login = () => {
  const [loginFormData, setLoginFormData] = useState({});
  const [showPassword, setShowPassword] = useState(false); // Stato per la visibilità della password
  const navigate = useNavigate();
  const endpoint = process.env.REACT_APP_SERVER_BASE_URL;

  const loginSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(`${endpoint}/login`, loginFormData);

      toast.success(" Login Effettuato Con Successo!!👍 ", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      if (res.data.token) {
        localStorage.setItem("userLoggedIn", JSON.stringify(res.data.token));
        navigate("/");
      }
    } catch (error) {
      console.log(error);

      let errorMessage = "❌ Email o password non valide!";

      if (error.response?.status === 403) {
        errorMessage =
          "EMAIL NON VERIFICATA, verifica la tua email ed attiva il tuo account";
      } else if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const redirectResetPasswordOnClick = () => {
    navigate("/reset-password");
  };

  return (
    <>
      <NavigationBar className="mb-5 " />
      <div className="d-flex align-items-center justify-content-center mt-5">
        <Container className="d-flex align-items-center justify-content-center my-5 ">
          <Row className="d-flex flex-column align-items-center justify-content-center ">
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="slide-in bg-white rounded shadow p-3"
            >
              <p className=" text-center fs-4 fw-bold colortext-blunav">
                Benvenuto nuovo investitore, ti presentiamo l'applicazione per
                investitori premium della 11.22 Sviluppo Immobiliare s.r.l.{" "}
                <hr /> Accedi per scoprire gli investimenti disponibili ed
                utilizzare i nostri servizi.
              </p>
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="my-4 d-flex flex-column justify-content-center align-items-center gap-4 "
            >
              <h3 className="d-flex flex-column align-items-center fw-bold fs-1 colortext-blunav fst-italic">
                Login
              </h3>
              <form onSubmit={loginSubmit} className="d-flex flex-column gap-3">
                <input
                  type="text"
                  name="email"
                  placeholder="email"
                  className="p-2 rounded shadow input_style"
                  onChange={(e) =>
                    setLoginFormData({
                      ...loginFormData,
                      email: e.target.value,
                    })
                  }
                />
                <div className="d-flex align-items-center">
                  <input
                    type={showPassword ? "text" : "password"} // Alterna tra 'password' e 'text'
                    name="password"
                    placeholder="password"
                    className="p-2 rounded shadow input_style flex-grow-1"
                    onChange={(e) =>
                      setLoginFormData({
                        ...loginFormData,
                        password: e.target.value,
                      })
                    }
                  />
                  <Button
                    variant=""
                    className="ms-2"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ textDecoration: "none" }}
                  >
                    {showPassword ? (
                      <AiOutlineEye size={24} />
                    ) : (
                      <AiOutlineEyeInvisible size={24} />
                    )}
                  </Button>
                </div>
                <Button
                  className="button-linkStyle fw-bold"
                  onClick={redirectResetPasswordOnClick}
                >
                  Password dimenticata?
                </Button>
                <Button
                  type="submit"
                  className="button-redLogo rounded p-1 fw-bold"
                >
                  Login
                </Button>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
      <SfondoAnimatoOnde />
    </>
  );
};

export default Login;
