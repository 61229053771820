import React from "react";
import { Container } from "react-bootstrap";
import imgCallNumberGreen from "../asset/numeroVerde1122SviluppoImmobiliare.png";

const CallToActionNumberGreen = () => {
  const phoneNumber = "800160722";

  const styleNumber = {
    width: "20rem",
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <a href={`tel:${phoneNumber}`} style={{ textDecoration: "none" }}>
        <img
          style={styleNumber}
          src={imgCallNumberGreen}
          alt="immagine numero verde"
        />
      </a>
    </Container>
  );
};

export default CallToActionNumberGreen;
