import React from "react";
import Card from "react-bootstrap/Card";
import article16Img from "../../asset/article16-img.png";

import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Article16() {
  const navigate = useNavigate();

  const redirectArticle16 = () => {
    navigate("/accollo-del-mutuo-vantaggi-e-come-funziona");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article16Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Accollo del mutuo: cosa sapere e quali vantaggi per parti?
          </Card.Title>

          <Button onClick={redirectArticle16} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article16;
