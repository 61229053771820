import React from "react";
import { ProgressBar } from "react-bootstrap";
import "../../Style/pageSecondary.css";
const ProgressBarComponent = ({ progress }) => {
  return (
    <div className="mb-3 d-flex align-items-center justify-content-center flex-column py-5 bg-white rounded shadow">
      <h5 className="text-center fs-5 mb-3">Avanzamento dei lavori</h5>
      <ProgressBar className="w-70 " now={progress} label={`${progress}%`} />
    </div>
  );
};

export default ProgressBarComponent;
