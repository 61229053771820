import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import SpinnerLoading from "../Components/SpinnerLoading";
import SingleInvestHome from "./Investiment/SingleInvestHome";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import { getInvest, filterinvestName } from "../reducer/investSlice";
import { MdSearch } from "react-icons/md";
import "../Style/pageSecondary.css";
import Aos from "aos";
import "aos/dist/aos.css";
import OffCanvasProfile from "./OffCanvasProfile";
import { useSession } from "../middlewares/ProtectedRoutes";
import { useNavigate } from "react-router-dom";

const InvestBoardHome = () => {
  const session = useSession();
  const navigate = useNavigate();

  const redirectBachecaInvestimentiOnClick = () => {
    navigate("/bacheca-Investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  const { investsArray, loading } = useSelector((state) => state.invest);

  useEffect(() => {
    Aos.init();
    dispatch(getInvest());
  }, [dispatch]);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term === "") {
      dispatch(getInvest());
    } else {
      dispatch(filterinvestName(term));
    }
  };

  return (
    <>
      <Container className="my-5 pt-5">
        <h1 className="text-center fw-bold colortext-blunav">
          I NOSTRI INVESTIMENTI IMMOBILIARI
        </h1>
        <div className="d-flex justify-content-center align-items-center my-5">
          <MdSearch className="fs-1 me-2 colortext-blunav" />
          <Form.Group controlId="search" className="w-70 searchBarStyle">
            <Form.Control
              className="shadow"
              type="text"
              placeholder="Cerca per nome del progetto"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Form.Group>
        </div>
      </Container>
      <Container fluid className="shadow bgBluOpacity05">
        <Container>
          <Row className="d-flex justify-content-center align-items-center py-5">
            {loading ? (
              <div className="my-5 d-flex justify-content-center align-items-center">
                <SpinnerLoading />
              </div>
            ) : investsArray.length > 0 ? (
              investsArray
                .slice()
                .reverse()
                .map((invest) => (
                  <SingleInvestHome
                    key={nanoid()}
                    invest={invest}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                  />
                ))
            ) : (
              <div className="my-5 d-flex justify-content-center align-items-center">
                <p className="text-center fs-4">
                  Nessun investimento disponibile al momento.
                </p>
              </div>
            )}
          </Row>
        </Container>
      </Container>
      <Container>
        <p
          className="color-blunav mt-5 colortext-blunav text-center fs-5 fw-bold text-white p-3 rounded"
          data-aos="flip-down"
          data-aos-duration="1000"
          data-aos-easing="linear"
        >
          Tutti i nostri investitori potranno monitorare l'andamento
          dell’operazione alla quale partecipano tramite la nostra Invest App
          dedicata, all’interno della quale troveranno il Business Plan
          dettagliato, lo stato avanzamento lavori, fotografie, ed un report
          periodico così da restare costantemente aggiornati. <hr /> Inoltre, su
          richiesta, sarà possibile visionare personalmente l’immobile e lo
          stato avanzamento dei lavori, cosi da toccare con mano il proprio
          investimento.
        </p>
        <h2
          className="text-center bold-900 mt-5 colortext-blunav"
          data-aos="fade-down"
          data-aos-duration="800"
          data-aos-easing="ease-in-sine"
        >
          Sei interessato alle nostre opportunità d'investimento immobiliare?
        </h2>
        {session ? (
          <div className="d-flex justify-content-center align-items-center my-4">
            <Button
              onClick={redirectBachecaInvestimentiOnClick}
              className="button-redLogo fs-5"
            >
              Visualizza la nostra area investimenti
            </Button>
          </div>
        ) : (
          <div>
            <p
              className="text-center fs-3 my-3 fw-bold colortext-blunav"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-easing="ease-in-sine"
            >
              Registrati gratuitamente alla nostra piattaforma per esplorare le
              possibilità di guadagno e rimanere aggiornato su tutte le novità
              disponibili
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <OffCanvasProfile />
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default InvestBoardHome;
