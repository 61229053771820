import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import "../Style/pageSecondary.css";

function LogoutModal() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = () => {
    localStorage.clear(); // Svuota il Local Storage
    navigate("/login"); // Reindirizza alla pagina di login o ad altra destinazione
  };

  return (
    <>
      <Button className="button-redLogo" onClick={handleShow}>
        Logout
        <AiOutlineLogout className="ms-2 fs-5" />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sei sicuro di voler uscire dal tuo account?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Chiudi
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Si
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LogoutModal;
