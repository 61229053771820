import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const auth = () => {
  return JSON.parse(localStorage.getItem("userLoggedIn"));
};

export const useSession = () => {
  const session = auth();
  const decodedSession = session ? jwtDecode(session) : null;
  return decodedSession;
};

const ProtectedRoutes = () => {
  const session = useSession();
  const navigate = useNavigate();

  React.useEffect(() => {
    // Se non è autenticato, reindirizza a login
    if (!session) {
      navigate("/login", { replace: true });
    }
  }, [navigate, session]);

  // Controlla se l'utente è un admin
  const isAdmin = session && session.role === "admin";

  // Mostra i componenti per gli utenti registrati o admin
  return (
    <>
      {isAdmin || session ? (
        <Outlet />
      ) : (
        navigate("/error", { replace: true }) // Se non è admin e non è registrato, reindirizza a errore
      )}
    </>
  );
};

export default ProtectedRoutes;
