import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import ScrollButtonInvestForm from "../Components/ScrollButtonInvestForm";
import BarraLaterale from "../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";
import { useSession } from "../middlewares/ProtectedRoutes";
import backInvest from "../asset/Invest-bg-80opacity.png";
import aumentoPrice from "../asset/aumentoPrezzi.png";
import Investitor from "../asset/investitor.png";
import InvestimentSicure from "../asset/investiment-sicure.png";
import ImgComplesso from "../asset/Complesso.png";
import ImgSingolAbitation from "../asset/SingolaAbitazione.png";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import bgTecnologic from "../asset/bg-tecnologico.png";
import ContactFormSmall from "../Components/ContactFormSmall";
import ContactForm from "../Components/ContactForm";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import OffCanvasProfile from "../Components/OffCanvasProfile";

const InvestPage = () => {
  const session = useSession();
  const navigate = useNavigate();

  const redirectBachecaInvestimentiOnClick = () => {
    navigate("/bacheca-Investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  const InvestStyle = {
    backgroundImage: `url(${backInvest})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "3rem",
  };

  const personalAppStyle = {
    backgroundImage: `url(${bgTecnologic})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",

    backgroundPosition: "center",
    minHeight: "70vh", // Imposta l'altezza minima al 100% della viewport
    zIndex: -1,
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Investimenti Immobiliari</title>
        <meta
          name="description"
          content="la 11.22 sviluppo immobiliare S.r.l. e una società di investimenti immobiliare che punta a far generare ai propri investitori una rendita passiva annua"
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/investimenti-immobiliari"
        />
      </Helmet>

      <BarraLaterale />
      <Container fluid style={InvestStyle} className="img-zoom overflowHidden">
        <div
          className="mt-5 pt-5 d-flex flex-column justify-content-center
         align-items-center color-title text-center fade-in h-100 "
        >
          <h1 className="mt-5 fw-bold red-logo  ">INVESTIMENTI IMMOBILIARI </h1>
        </div>
      </Container>
      <Container fluid className="py-5 color-blunav overflowHidden p-lg-5">
        <h2 className=" text-center text-white fw-bold ">
          Investi in sicurezza nei nostri progetti nel Real Estate. <hr />
          Investimenti mirati a creare valore.
        </h2>
        <p className=" p-4 fs-5 text-white  text-center">
          <span className=" fw-bold fs-3 d-block">
            Scegli il mercato immobiliare per il suo rapporto esclusivo rischio
            rendimento.
          </span>{" "}
        </p>
      </Container>
      <Container fluid className="overflowHidden bgBluOpacity05  shadow py-4">
        <div className="d-flex align-items-center flex-column justify-content-center my-5  colortext-blunav">
          <p className="text-center fs-2 fw-bold ">
            Gli investimenti immobiliari sono riconosciuti come i più sicuri.
            <br /> Ti illustriamo il perché.{" "}
          </p>
        </div>
        <Container>
          <Row className=" d-flex align-items-center justify-content-start ">
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h3 className="text-center bold-900 color-title-card fs-1 mb-4 red-logo">
                  Stabilità e aumento continuo dei prezzi degli immobili
                </h3>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Quando investiamo in qualcosa, ci aspettiamo di recuperare il
                  nostro investimento con un profitto. Ogni investimento
                  comporta una percentuale di rischio, di cui ogni investitore
                  deve essere consapevole. A differenza dell’investimento in
                  strumenti finanziari come azioni, obbligazioni o fondi di
                  investimento, gli investimenti immobiliari sono considerati
                  una forma d’investimento sicura, dimostrata e certificata da
                  decenni di risultati avuti da innumerevoli imprenditori e
                  investitori immobiliari.
                </p>
              </div>

              <div className=" d-flex justify-content-center align-items-center ">
                <ScrollButtonInvestForm />
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <img
                src={aumentoPrice}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>

            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              <img
                src={InvestimentSicure}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h3 className="text-center bold-900 color-title-card fs-1 mb-4 red-logo">
                  Stabilità e redditività degli investimenti
                </h3>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Un investimento si definisce stabile quando il sottostante non
                  soffre di volatilità o cambio del prezzo. Un investimento si
                  definisce redditivo quando lo storico del sottostante, che non
                  significa certezza del futuro, garantisce e dimostra un
                  aumento del prezzo costante negli anni. Il valore delle
                  attività finanziarie può diminuire in una sola notte a causa
                  di qualsiasi evento economico e politico. Nel caso
                  dell’immobiliare, la continua crescita e la bassa volatilità
                  dei prezzi definiscono l’investimento sull’immobile come uno
                  dei mercati più stabili e redditizi, dimostrato in questi
                  decenni.
                </p>
              </div>

              <div className=" d-flex justify-content-center align-items-center ">
                <ScrollButtonInvestForm />
              </div>
            </Col>

            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h3 className="text-center bold-900 color-title-card fs-1 mb-4 red-logo">
                  I nostri investitori
                </h3>
                <p className="colortext-blunav fw-bold fs-5 w-100">
                  Il nostro focus è duplice: ci rivolgiamo sia a nuovi
                  investitori desiderosi di iniziare a massimizzare il
                  rendimento del loro capitale sia ad investitori esperti che
                  desiderano diversificare i propri portafogli.
                </p>
              </div>

              <div className=" d-flex justify-content-center align-items-center ">
                <ScrollButtonInvestForm />
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <img
                src={Investitor}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid style={personalAppStyle}>
        <Row className="d-flex ">
          <Col
            data-aos="zoom-in-left"
            data-aos-offset="200"
            data-aos-duration="2000"
            className=" hover-img  rounded flex-column d-flex justify-content-center  align-items-center  py-2"
          >
            <h2 className="text-center fw-bold pt-5 mb-5 fs-1 text-white">
              Puoi monitorare il tuo investimento
            </h2>
            <p className=" text-center fw-bold  rounded mb-3  fs-4 width-100Smarphone width-70Pc   text-white line-height3 ">
              I membri del nostro esclusivo
              <span className="bold-900 ms-1 ">Club Investitori Premium</span>
               avranno l’opportunità di monitorare in tempo reale l’andamento
              dell’operazione tramite una Web App dedicata. All’interno dell’App
              troveranno il Business Plan dettagliato, lo stato avanzamento
              lavori, fotografie e report periodici per restare sempre
              aggiornati sull’evoluzione del loro investimento. <br />
              Inoltre i membri del Club Investitori Premium potranno usufruire
              di una serie di vantaggi a loro dedicati. <br />
              Su richiesta gli investitori potranno visitare personalmente
              l’immobile e verificare lo stato dei lavori, per avere un contatto
              diretto con il proprio investimento.
            </p>
            <Button
              className="d-flex align-items-center justify-content-center   mt-5 shadow p-2 button-redLogo mb-5"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              data-aos-offset="200"
            >
              <span>
                <OffCanvasProfile />
              </span>{" "}
            </Button>
          </Col>
        </Row>
      </Container>

      <Container fluid className="overflowHidden py-5 bg-white">
        <Container>
          <h3
            className="text-center fw-bold pt-5 mb-5 fs-1 red-logo "
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-sine"
          >
            Nuovi progetti immobiliari in partenza
          </h3>

          <p
            className="text-center fw-bold  mb-5 fs-3 colortext-blunav "
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-sine"
          >
            Siamo lieti di invitarti a registrarti gratuitamente alla nostra
            Invest App . Iscrivendoti, riceverai le notifiche riguardanti le
            nostre nuove opportunità d’investimento. <br />
            Verrai contattato automaticamente non appena un nuovo progetto sarà
            disponibile.
          </p>
        </Container>
      </Container>
      <Container className="my-5">
        <h2
          className="text-center bold-900 mt-5 colortext-blunav "
          data-aos="fade-down"
          data-aos-duration="1500"
          data-aos-easing="ease-in-sine"
        >
          Sei interessato alle nostre opportunità d'investimento immobiliare?
        </h2>
        {session ? (
          <div className="d-flex justify-content-center align-items-center my-4">
            <Button
              onClick={redirectBachecaInvestimentiOnClick}
              className="button-redLogo fs-5"
            >
              Visualizza la nostra area investimenti
            </Button>
          </div>
        ) : (
          <div>
            <p
              className="text-center fs-3 my-3 fw-bold colortext-blunav"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-easing="ease-in-sine"
            >
              Registrati gratuitamente alla nostra piattaforma per esplorare le
              possibilità di guadagno e rimanere aggiornato su tutte le novità
              disponibili
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <OffCanvasProfile />
            </div>
          </div>
        )}
      </Container>

      <ContactFormSmall />

      <CallToActionNumberGreen />

      <Footer />
    </>
  );
};
export default InvestPage;

{
}
