import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Homepage from "./Pages/Homepage";
import ErrorPage from "./Pages/ErrorPage";
import ChiSiamoPage from "./Pages/ChiSiamoPage";
import InvestPage from "./Pages/InvestPage";
import Faq from "./Pages/Faq";
import LaNostraMission from "./Pages/LaNostraMission";
import Valori from "./Pages/Valori";
import Contact from "./Pages/Contact";
import LavoraConNoi from "./Pages/LavoraConNoi";
import InformationNoResp from "./Pages/InformationNoResp";
import Login from "./Pages/Login";
import Blog from "./Pages/Blog";
import Article1Text from "./Pages/Articoli/Article1Text";
import Article2Text from "./Pages/Articoli/Article2Text";
import Article3Text from "./Pages/Articoli/Article3Text";
import Article4Text from "./Pages/Articoli/Article4Text";
import Article5Text from "./Pages/Articoli/Article5Text";
import Article6Text from "./Pages/Articoli/Article6Text";
import Article7Text from "./Pages/Articoli/Article7Text";
import Article8Text from "./Pages/Articoli/Article8Text";
import Article9Text from "./Pages/Articoli/Article9Text";
import Article10Text from "./Pages/Articoli/Article10Text";
import Article11Text from "./Pages/Articoli/Article11Text";
import Article12Text from "./Pages/Articoli/Article12Text";
import Article13Text from "./Pages/Articoli/Article13Text";
import Article14Text from "./Pages/Articoli/Article14Text";
import Article15Text from "./Pages/Articoli/Article15Text";
import Article16Text from "./Pages/Articoli/Article16Text";
import Article17Text from "./Pages/Articoli/Article17Text";
import Article18Text from "./Pages/Articoli/Article18Text";
import Article19Text from "./Pages/Articoli/Article19Text";
import Article20Text from "./Pages/Articoli/Article20Text";
import Article21Text from "./Pages/Articoli/Article21Text";
import Article22Text from "./Pages/Articoli/Article22Text";
import ProtectedRoutes from "./middlewares/ProtectedRoutes";
import UtentGestion from "./Pages/UtentGestion";
import ProfileUser from "./Pages/ProfileUser";
import GestionInvestiment from "./Pages/GestionInvestiment";

import DetailsInvestimentADMIN from "./Pages/DetailsInvestimentADMIN";
import BachecaInvestiment from "./Pages/BachecaInvestiment";
import USERdetailInvest from "./Pages/USERdetailsInvest";
import ResetPassword from "./Pages/ResetPassword";
import ResetPasswordRequest from "./Pages/ResetPasswordRequest";
import SectionPrivate from "./Pages/SectionPrivate";
import EmailVerification from "./Pages/EmailVerification";
import AdminRoutes from "./middlewares/AdminRoutes";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/chiSiamo" element={<ChiSiamoPage />} />
          <Route path="/investimenti-immobiliari" element={<InvestPage />} />
          <Route
            path="/laNostraMission-rendita-passiva"
            element={<LaNostraMission />}
          />
          <Route path="/valori" element={<Valori />} />
          <Route path="/faq-investimenti-immobiliari" element={<Faq />} />
          <Route path="/contatti" element={<Contact />} />
          <Route
            path="/lavora-con-noi-nel-settore-degli-investimenti-immobiliari"
            element={<LavoraConNoi />}
          />
          <Route path="/information" element={<InformationNoResp />} />
          <Route path="/verify-email/:token" element={<EmailVerification />} />
          <Route path="/login" element={<Login />} />
          <Route path="/articoli-investimenti-immobiliari" element={<Blog />} />
          <Route path="/reset-password" element={<ResetPasswordRequest />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />

          <Route
            path="/trend-investimenti-immobiliari-2024"
            element={<Article1Text />}
          />
          <Route path="/bonus-edilizi-2024" element={<Article2Text />} />
          <Route path="/case-green" element={<Article3Text />} />
          <Route
            path="/investimenti-immobiliari-in-italia-2023"
            element={<Article4Text />}
          />
          <Route
            path="/virtual-tour-rivoluzione-mondo-immobiliare"
            element={<Article5Text />}
          />
          <Route
            path="/plusvalenze-immobiliari-come-si-calcolano"
            element={<Article6Text />}
          />
          <Route
            path="/investimenti-immobiliari-milano"
            element={<Article7Text />}
          />
          <Route
            path="/crescita-rendimenti-immobiliari"
            element={<Article8Text />}
          />
          <Route path="/pre-delibera-del-mutuo" element={<Article9Text />} />
          <Route
            path="/come-si-calcolano-il-ROI-ed-il-ROE"
            element={<Article10Text />}
          />
          <Route
            path="/open-house-vendite-immobiliari"
            element={<Article11Text />}
          />
          <Route path="/a-cosa-serve-il-DURC" element={<Article12Text />} />
          <Route path="/rogito-notarile" element={<Article13Text />} />
          <Route
            path="/cambiamento-mutui-ultimi-due-anni"
            element={<Article14Text />}
          />
          <Route
            path="/detrazione-interessi-passivi-mutuo-prima-casa"
            element={<Article15Text />}
          />
          <Route
            path="/accollo-del-mutuo-vantaggi-e-come-funziona"
            element={<Article16Text />}
          />
          <Route
            path="/passaggi-per-acquistare-casa-dal-preliminare-al-rogito"
            element={<Article17Text />}
          />
          <Route
            path="/quanto-costa-donare-un-immobile-ad-un-parente"
            element={<Article18Text />}
          />
          <Route
            path="/variabili-da-considerare-quando-si-deve-vendere-casa"
            element={<Article19Text />}
          />
          <Route
            path="/cambio-di-destinazione-di-uso-di-un-immobile"
            element={<Article20Text />}
          />
          <Route
            path="/requisiti-per-ottenere-un-mutuo-per-acquistare-casa"
            element={<Article21Text />}
          />
          <Route
            path="/condizioni-e-permessi-per-trasformare-il-sottotetto-in-locale-abitabile"
            element={<Article22Text />}
          />

          {/* Route protette (visibili a tutti gli utenti registrati) */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/profile/:id" element={<ProfileUser />} />
            <Route
              path="/investments/:id/updates"
              element={<SectionPrivate />}
            />
            <Route
              path="/detailsInvestUser/:id"
              element={<USERdetailInvest />}
            />
            <Route
              path="/bacheca-Investimenti-immobiliari"
              element={<BachecaInvestiment />}
            />

            {/* Route specifiche per l'admin */}
            <Route element={<AdminRoutes />}>
              <Route
                path="/detailsInvestiment/:id"
                element={<DetailsInvestimentADMIN />}
              />
              <Route
                path="/gestionInvestiment"
                element={<GestionInvestiment />}
              />
              <Route path="/utentGestion" element={<UtentGestion />} />
            </Route>
          </Route>

          {/* Route per error handling */}
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
