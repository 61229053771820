import React from "react";

import "../Style/sfondoAnimato.css";
import { Button, Container } from "react-bootstrap";

const CarouselHero = () => {
  return (
    <>
      <Container className="container-carousel">
        <div className="div-intro">
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
        </div>
      </Container>
    </>
  );
};

export default CarouselHero;
