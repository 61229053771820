import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";
import { Helmet } from "react-helmet";

import article3Img from "../../asset/article3-img.png";

const Article3Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/articoli-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Case green</title>
        <meta
          name="description"
          content="Le nuove normative sulle case green, normative europee e nuovi termini per le emissioni"
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/case-green"
        />
      </Helmet>

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article3Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Case green, l'UE allenta le richieste e sposta i termini.
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center">
          Siamo giunti all’accordo sulla direttiva europea case green, ed è un
          accordo più mite del previsto. La commissione del Parlamento Europeo
          ha licenziato il testo, che passerà all’esame della plenaria.
          Rilevanti cambiamenti per quanto riguarda i requisiti di adeguamento
          energetico, meno stringenti e più flessibili a discrezione dei Paesi
          membri, che dovranno redigere un piano di riduzione dei consumi con
          l’obbiettivo di arrivare a zero nel 2050. Spostata in avanti anche la
          scadenza per l’abbandono dei combustibili fossili e delle caldaie a
          metano nelle abitazioni: dal 2035 al 2040
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Dopo un lungo iter nelle istituzioni Ue, è giunto oggi in
            Commissione il testo rivisto e corretto della direttiva case green.
            Un provvedimento che, come era stato pensato da Bruxelles, sarebbe
            andato a colpire duramente aziende, lavoratori e famiglie italiane.
            Una minaccia scongiurata grazie alle battaglie condotte dall’Italia
            in Europa e all'impegno dello stesso governo Italiano, che hanno
            fatto prevalere il buonsenso su tutti i tavoli negoziali, rispedendo
            al mittente ogni Eurofollia.
          </p>
        </Container>
      </Container>

      <Container fluid className="py-5 bgBluOpacity05">
        <Container>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            Positivo che sia stato eliminato l'obbligo, per i cittadini, di
            adeguarsi alle classi energetiche imposte dall'Europa, lasciando
            questa responsabilità ai singoli Stati membri con tempi più
            ragionevoli per i piani di rinnovamento. Permangono tuttavia alcune
            criticità che sostengono in un'iniziativa figlia dell'ideologia del
            Green Deal, a cominciare dagli oneri di ristrutturazione che, in un
            Paese dal particolare contesto immobiliare come il nostro dove le
            case sono per lo più di proprietà, saranno comunque pesanti e
            ricadranno su una situazione di debito pubblico già problematica.
          </p>
          <div className="mb-4">
            <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
              E' una vittoria quella ottenuta a Bruxelles, con la conferma dell'
              Trilogo tra le istituzioni Europee, sulla direttiva
              dell'efficientamento energetico degli edifici. Vengono
              quindi ammorbidite le richieste iniziali della Commissione
              Europea: il raggiungimento di un parco edifici ad emissioni zero è
              stato posticipato al 2050, così come l'obbligo di dire addio alle
              caldaie alimentate da combustibili fossili prorogato al 2040.
            </p>
          </div>
        </Container>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article3Text;
