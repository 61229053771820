import React from "react";
import Card from "react-bootstrap/Card";
import article15Img from "../../asset/article15-img.png";

import { Button, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Article15() {
  const navigate = useNavigate();

  const redirectArticle15 = () => {
    navigate("/detrazione-interessi-passivi-mutuo-prima-casa");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article15Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Detrazione interessi passivi sul mutuo prima casa quando non
            residenti
          </Card.Title>

          <Button onClick={redirectArticle15} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article15;
