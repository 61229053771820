import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail, resetVerificationStatus } from "../reducer/userSlice"; // Assicurati di usare il path corretto per il tuo slice
import { useParams, useNavigate } from "react-router-dom"; // Importa useNavigate
import { toast } from "react-toastify"; // Se stai utilizzando react-toastify per le notifiche
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import "../Style/pageSecondary.css";

const EmailVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Inizializza useNavigate
  const { token } = useParams();
  const { verificationStatus, verificationError } = useSelector(
    (state) => state.users
  );

  useEffect(() => {
    if (token) {
      dispatch(verifyEmail(token));
    }

    // Pulire lo stato della verifica quando il componente si smonta
    return () => {
      dispatch(resetVerificationStatus());
    };
  }, [dispatch, token]);

  useEffect(() => {
    if (verificationStatus === "fulfilled") {
      toast.success("Email verificata con successo!");
      navigate("/login"); // Aggiungi il redirect al login
    } else if (verificationStatus === "rejected" && verificationError) {
      toast.error(
        verificationError.message || "Errore durante la verifica dell'email."
      );
    }
  }, [verificationStatus, verificationError, navigate]); // Assicurati di includere navigate nelle dipendenze

  return (
    <>
      <NavigationBar />
      <div className="verification-container mt-5 pt-5 text-center VH80">
        <h2 className="p-2">
          Verifica dell'email completata! <hr /> Ora il tuo account è attivo e
          puoi effettuare il login alla nostra piattaforma.
        </h2>

        {verificationStatus === "pending" && <p>Verifica in corso...</p>}
        {verificationStatus === "idle" && !verificationError && (
          <p>Controlla il tuo stato di verifica.</p>
        )}
      </div>
      <Footer />
    </>
  );
};

export default EmailVerification;
