import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createUpdate } from "../../reducer/updateInvestSlice";
import { toast } from "react-toastify";

const NewUpdateModal = ({ show, handleClose, investId }) => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [imageUpdate, setImageUpdate] = useState(null);
  const [loading, setLoading] = useState(false); // Stato di caricamento
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Attiva lo stato di caricamento

    const formData = new FormData();
    formData.append("title", title);
    formData.append("text", text);
    formData.append("imageUpdate", imageUpdate);
    formData.append("investId", investId);

    try {
      await dispatch(createUpdate(formData)); // Usa l'azione per creare l'aggiornamento
      toast.success("Aggiornamento creato con successo!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      }); // Notifica di successo
      handleClose(); // Chiudi la modale
    } catch (error) {
      toast.error(
        "Si è verificato un errore durante la creazione dell'aggiornamento.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        }
      ); // Notifica di errore
    } finally {
      setLoading(false); // Disattiva lo stato di caricamento
      // Resetta i campi del form
      setTitle("");
      setText("");
      setImageUpdate(null);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Crea un aggiornamento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formTitle">
            <Form.Label>Titolo</Form.Label>
            <Form.Control
              type="text"
              placeholder="Inserisci il titolo"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formText" className="my-3">
            <Form.Label>Testo</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Inserisci il testo"
              value={text}
              onChange={(e) => setText(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formImageUpdate">
            <Form.Label>Immagine o file pdf</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setImageUpdate(e.target.files[0])}
              accept=".jpg,.jpeg,.png,.pdf"
            />
          </Form.Group>
          <Button
            className="my-3"
            variant="primary"
            type="submit"
            disabled={loading}
          >
            {loading ? "Attendi qualche secondo..." : "Crea Aggiornamento"}{" "}
            {/* Cambia il testo del pulsante */}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NewUpdateModal;
