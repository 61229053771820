import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { investPost, getInvest } from "../../reducer/investSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../Style/pageSecondary.css";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import SpinnerLoading from "../SpinnerLoading";

const NewInvestModal = () => {
  //costanti della modale di bootstrap
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false); // Stato per gestire la visualizzazione del componente SpinnerLoading

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const name = useRef(null);
  const area = useRef(null);
  const typeOfIntervent = useRef(null);
  const duration = useRef(null);
  const interventionAmount = useRef(null);
  const roiEstimated = useRef(null);
  const durationEffective = useRef(null);
  const roiAnnuo = useRef(null);
  const image = useRef(null);
  const description = useRef(null);
  const roiFinal = useRef(null);
  const state = useRef(null);

  const handleSubmit = () => {
    setIsLoading(true); // Imposta isLoading a true al momento del click sull'invio

    // Esegui la validazione obbligatoria per il campo immagine utilizzando validator.js
    if (!image.current.files[0]) {
      toast.error("L'immagine è obbligatoria", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      setIsLoading(false); // Imposta isLoading a false dopo la visualizzazione del messaggio di errore
      return; // Interrompi l'invio del form se l'immagine non è stata fornita
    }

    const data = {
      name: name.current.value,
      area: area.current.value,
      typeOfIntervent: typeOfIntervent.current.value,
      duration: duration.current.value,
      interventionAmount: interventionAmount.current.value,
      roiEstimated: roiEstimated.current.value,
      durationEffective: durationEffective.current.value,
      roiAnnuo: roiAnnuo.current.value,
      image: image.current.files[0],
      description: description.current.value,
      roiFinal: roiFinal.current.value,
      state: state.current.value,
    };

    // Invia la richiesta POST al server
    dispatch(investPost(data))
      .unwrap()
      .then((originalPromiseResult) => {
        // Controlla se l'invio ha avuto successo
        if (originalPromiseResult) {
          toast.success("Investimento creato con Successo! 👌 ", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
          handleClose(); // Chiude la modale dopo l'invio del form
          dispatch(getInvest()); // Rilancia la funzione per ottenere gli investimenti aggiornati
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // L'invio ha generato un errore
        console.error(rejectedValueOrSerializedError);
        toast.error(
          "Si è verificato un errore durante la creazione dell'investimento.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          }
        );
      })
      .finally(() => {
        setIsLoading(false); // Imposta isLoading a false dopo l'invio del form
      });
  };

  return (
    <>
      <Button
        className=" style-button-association shadow d-flex  align-items-center justify-content-center"
        onClick={handleShow}
      >
        <FaPlus className="me-2" />
        Crea Nuovo Investimento
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>CREA NUOVO INVESTIMENTO </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center">
          <Form>
            <Form.Group className="mb-3" controlId="formGroupImg">
              <Form.Label>IMMAGINE</Form.Label>
              <Form.Control type="file" ref={image} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>NOME</Form.Label>
              <Form.Control type="input" ref={name} placeholder="nome" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupArea">
              <Form.Label>AREA</Form.Label>
              <Form.Control type="input" ref={area} placeholder="area" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupTypeOfIntervent">
              <Form.Label>TIPO DI INTERVENTO</Form.Label>
              <Form.Control
                type="input"
                ref={typeOfIntervent}
                placeholder=" Tipo di intervento"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupDuration">
              <Form.Label>DURATA STIMATA</Form.Label>
              <Form.Control
                type="input"
                ref={duration}
                placeholder="inserisci la durata intervento"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupAmount">
              <Form.Label>IMPORTO TOTALE DELL'INTERVENTO</Form.Label>
              <Form.Control
                type="input"
                ref={interventionAmount}
                placeholder="inserisci i costi dell'intervento"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupRoiEstimated">
              <Form.Label>ROI STIMATO</Form.Label>
              <Form.Control
                type="input"
                ref={roiEstimated}
                placeholder="ROI stimato "
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>DESCRIZIONE</Form.Label>
              <Form.Control
                as="textarea" // Modificato per utilizzare un'area di testo
                type="text"
                ref={description}
                placeholder="descrizione"
                rows={7} // Imposta il numero di righe visibili
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupRoiRoiFinal">
              <Form.Label> ROI FINALE</Form.Label>
              <Form.Control type="text" ref={roiFinal} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupRoiAnnuo">
              <Form.Label>ROI ANNUO</Form.Label>
              <Form.Control
                type="input"
                ref={roiAnnuo}
                placeholder="ROI Annuo"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupDurationEffective">
              <Form.Label>DURATA EFFETTIVA</Form.Label>
              <Form.Control
                type="input"
                ref={durationEffective}
                placeholder="Durata effettiva"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupState">
              <Form.Label> STATO</Form.Label>
              <Form.Control
                as="select" // Specifica che si tratta di un input a tendina
                name="state"
                ref={state}
              >
                <option value="ATTIVO">ATTIVO</option>
                <option value="IN CORSO">IN CORSO</option>
                <option value="CONCLUSO">CONCLUSO</option>
              </Form.Control>
            </Form.Group>

            <div className="text-center">
              <Button
                variant="success"
                className="text-light fw-bold"
                onClick={handleSubmit}
              >
                CREA
              </Button>
            </div>
          </Form>
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center">
          {isLoading === true ? (
            <p className="d-flex align-items-center justify-content-center">
              Attendi..
              <SpinnerLoading />
            </p>
          ) : (
            <span></span>
          )}
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewInvestModal;
