import React from "react";
import Card from "react-bootstrap/Card";
import article22Img from "../../asset/article22-img.png";

import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Article22() {
  const navigate = useNavigate();

  const redirectArticle22 = () => {
    navigate(
      "/condizioni-e-permessi-per-trasformare-il-sottotetto-in-locale-abitabile"
    );
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article22Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Trasformare il sottotetto in locale abitabile: <br />
            condizioni e permessi
          </Card.Title>

          <Button onClick={redirectArticle22} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article22;
