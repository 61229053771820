import React, { useEffect, useState } from "react";
import { Container, Row, Button, Form } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import SpinnerLoading from "../Components/SpinnerLoading";
import NewInvestModal from "../Components/Investiment/NewInvestModal";
import SingleInvestiment from "../Components/Investiment/SingleInvestiment";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvest,
  filterinvestName,
  filterByState,
} from "../reducer/investSlice";
import { MdSearch } from "react-icons/md";
import "../Style/pageSecondary.css";
import { FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const GestionInvestiment = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredState, setFilteredState] = useState(""); // Stato per tenere traccia del filtro attivo
  const [noInvestmentsMessage, setNoInvestmentsMessage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Ottenere lo stato di caricamento e gli investimenti dall'API
  const { investsArray, loading } = useSelector((state) => state.invest);

  const redirectUtentGestionOnClick = () => {
    navigate("/utentGestion");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(getInvest()); // Effettua la chiamata GET al caricamento del componente
  }, [dispatch]);

  useEffect(() => {
    if (filteredState !== "") {
      // Verifica se ci sono investimenti disponibili per lo stato filtrato
      if (investsArray.length === 0) {
        setNoInvestmentsMessage(true);
        const timer = setTimeout(() => {
          setNoInvestmentsMessage(false);
        }, 3000); // Mostra il messaggio per 3 secondi
        return () => clearTimeout(timer);
      } else {
        setNoInvestmentsMessage(false);
      }
    }
  }, [filteredState, investsArray]);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term === "") {
      // Se la barra di ricerca è vuota, mostra tutti gli investimenti
      dispatch(getInvest());
    } else {
      // Altrimenti, filtra gli investimenti per nome
      dispatch(filterinvestName(term));
    }
  };

  const filterInvestmentsByState = (state) => {
    setFilteredState(state); // Imposta lo stato del filtro attivo
    dispatch(filterByState(state));
  };

  return (
    <>
      <NavigationBar />
      <Container className="my-5 pt-5 ">
        <Button
          className="button-redLogo mb-5 mt-2 d-flex justify-content-center align-items-center"
          onClick={redirectUtentGestionOnClick}
        >
          <FaUsers className="fs-5 me-2" />
          Gestione Utenti
        </Button>
        <h1 className="text-center fw-bold colortext-blunav ">
          GESTIONE INVESTIMENTI
        </h1>
        <div className="d-flex justify-content-center align-items-center ">
          <NewInvestModal />
        </div>
        <div className="d-flex justify-content-center align-items-center my-5">
          <MdSearch className="fs-1 me-2 colortext-blunav" />
          <Form.Group controlId="search" className="w-70 searchBarStyle">
            <Form.Control
              className="shadow"
              type="text"
              placeholder="Cerca per nome del progetto"
              value={searchTerm}
              onChange={handleSearch} // Funzione che gestisce il cambio del termine di ricerca
            />
          </Form.Group>
        </div>

        {/* Pulsanti di filtro per stato */}
        <div className="d-flex justify-content-center gap-3 mb-4">
          {/* Mostra pulsanti di stato solo se non è selezionato uno stato */}
          {filteredState === "" && (
            <>
              <Button
                variant="success"
                onClick={() => filterInvestmentsByState("ATTIVO")}
              >
                Attivo
              </Button>
              <Button
                variant="warning"
                onClick={() => filterInvestmentsByState("IN CORSO")}
              >
                In corso..
              </Button>
              <Button
                variant="secondary"
                onClick={() => filterInvestmentsByState("CONCLUSO")}
              >
                Concluso
              </Button>
            </>
          )}
          {/* Mostra il pulsante "Mostra tutti" sempre */}
          <Button
            variant="primary"
            onClick={() => {
              setFilteredState(""); // Resetta lo stato di filtro
              dispatch(getInvest());
            }}
          >
            Mostra tutti
          </Button>
        </div>

        {/* Messaggio se non ci sono investimenti */}
        {noInvestmentsMessage && filteredState && (
          <div className="my-5 d-flex justify-content-center align-items-center">
            <h3>
              Nessun investimento disponibile per lo stato{" "}
              {filteredState.toLowerCase()}
            </h3>
          </div>
        )}
      </Container>
      <Container>
        <Row className="d-flex justify-content-center align-items-center ">
          {/* Spinner durante il caricamento */}
          {loading && (
            <div className="my-5 d-flex justify-content-center align-items-center">
              <SpinnerLoading />
            </div>
          )}

          {/* Messaggio se non ci sono investimenti */}
          {!loading && investsArray.length === 0 && (
            <div className="my-5 d-flex justify-content-center align-items-center">
              <p className="fw-bold fs-4">Nessun investimento disponibile</p>
            </div>
          )}

          {/* Mappa degli investimenti se presenti */}
          {!loading &&
            investsArray.length > 0 &&
            investsArray
              .slice()
              .reverse()
              .map((invest) => (
                <SingleInvestiment
                  key={nanoid()}
                  invest={invest}
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                />
              ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default GestionInvestiment;
