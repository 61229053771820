import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import article22Img from "../../asset/article22-img.png";

const Article22Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/articoli-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>
          Condizioni e permessi per trasformare il sottotetto in locale
          abitabile
        </title>
        <meta
          name="description"
          content="Condizioni e permessi per trasformare il sottotetto in locale abitabile"
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/condizioni-e-permessi-per-trasformare-il-sottotetto-in-locale-abitabile"
        />
      </Helmet>

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article22Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Trasformare il sottotetto in locale abitabile: <br />
          condizioni e permessi
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center ">
          Quali sono i principali aspetti da prendere in considerazione per
          trasformare la soffitta in una comoda mansarda? Di norma, il
          sottotetto è un vano non destinato all’uso abitativo, con funzioni di
          protezione dei piani sottostanti. A determinate condizioni può essere
          trasformato in un vano abitabile, aumentando altezze e volumi. Le
          regole da osservare cambiano però da Regione a Regione. Inoltre, le
          normative di alcune Regioni riservano ai singoli Comuni la possibilità
          di decidere quali lavori si possono fare su un sottotetto. Vediamo
          quali sono i principali aspetti da prendere in considerazione per
          trasformare la soffitta in una comoda mansarda senza commettere abusi
          edilizi.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Cos’è il sottotetto?
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-3">
            Il sottotetto è in genere quell’ambiente ricavato sotto il tetto con
            la funzione principale di difendere le stanze dell’ultimo piano dal
            caldo, dal freddo e dall’umidità. Un locale limitato in alto dalla
            struttura del tetto ed in basso dal solaio o dalle volte che coprono
            gli ambienti dell’ultimo piano. <hr /> Per le sue caratteristiche,
            il sottotetto, di norma, non è destinato ad usi abitativi;
            costituisce invece un volume tecnico che può essere utilizzato come
            deposito, oppure come locale per collocare impianti di servizio
            (centrali termiche, macchine ascensore, ecc.). <hr /> Negli edifici
            condominiali, l’articolo 1117 del Codice civile ricomprende
            espressamente i sottotetti tra le parti comuni, se non risulta il
            contrario dal titolo.
          </p>
        </Container>
      </Container>
      <Container fluid className="mb-5 bgBluOpacity05 py-5">
        <Container>
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Quando è abitabile e quando non lo è?
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            In passato, i locali sottotetto non destavano particolare interesse.
            Erano spesso considerati dei “locali di servizio” o dei “volumi
            tecnici”. Negli ultimi anni la situazione è cambiata. Sempre più di
            frequente le vecchie soffitte vengono sfruttate con soppalchi e i
            sottotetti diventando confortevoli mansarde.
          </p>

          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            In effetti, il proprietario del sottotetto può trasformare tale
            locale in unità abitabile, ove la legislazione regionale autorizzi
            tale modificazione di destinazione d'uso, acquisendo le necessarie
            autorizzazioni.
          </p>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            Molte regioni hanno legiferato in tema di trasformazioni dei
            sottotetti. Pertanto, è sempre necessario consultare queste
            normative per verificare se ci sono limiti o divieti mutamento da
            uso non abitativo del sottotetto a uso abitativo.
          </p>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
            Su questo punto, i giudici hanno stabilito che la modifica della
            destinazione d'uso del locale sottotetto in vano abitabile non può
            considerarsi un intervento di manutenzione ordinaria. Si tratta,
            invece, di un intervento di ristrutturazione edilizia, per il quale
            occorre ottenere il necessario titolo edilizio. Inoltre, tali
            interventi necessitano di una preventiva valutazione di sicurezza.
            Perciò, senza le necessarie autorizzazioni, rilasciate dagli
            organicompetenti, i lavori di trasformazioni non possono essere
            avviati (Cassazione civile, n. 1542/2015 e n. 36563/2016).
          </p>
        </Container>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Attenzione alle norme regionali!
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Le possibilità, le condizioni, i limiti rispetto alla trasformazione
          delle soffitte in mansarde abitabili possono cambiare da Regione a
          Regione. Facciamo una piccola panoramica delle principali
          caratteristiche comuni.
        </p>

        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Tutte le Regioni richiedono per i sottotetti altezze medie inferiori
          ai 270 cm regolari, ma con differenze sostanziali. L’altezza media più
          comune per i locali abitabili è di 240 cm. In alcuni casi, per i
          locali di servizio, bagni, corridoi, viene ulteriormente abbassata di
          10 cm.
        </p>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Molte Regioni permettono un’ulteriore riduzione dell’altezza (in
          genere di 20 cm), ad esempio nei Comuni classificati come montani o
          semi-montani, o al di sopra di una certa altitudine. Alcune leggi
          regionali prevedono che gli spazi al di sotto di un’altezza minima, se
          non sono posti in corrispondenza di fonti di luce, devono essere
          chiusi con muri o mobili. <hr />
          Tutte le Regioni prevedono la possibilità di aprire finestre e
          lucernai per illuminare i locali con luce solare (ma in alcuni casi
          sotto particolari prescrizioni o con l’esclusione di alcune zone del
          territorio). Il rapporto aero-illuminante (cioè il rapporto tra la
          superficie delle finestre e quella del pavimento) stabilito per Legge
          a livello nazionale è 1/8; ma in molte Regioni si arriva fino a 1/16.{" "}
          <hr /> In quasi la metà delle Regioni non è permesso alzare la quota
          del colmo o modificare la pendenza del tetto per ottenere le altezze
          medie e minime richieste. <hr /> Altre Regioni consentono di elevare
          il tetto, ma a precise condizioni. In alcuni casi, è esclusa la
          possibilità di innalzare il tetto solo in alcune zone: ad esempio, nei
          centri storici.
        </p>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Per recuperare in altezza non c’è solo la possibilità di innalzare il
          tetto: lo stesso risultato si può ottenere anche abbassando il solaio
          del sottotetto, ma solo se quest’operazione non riduce l’altezza
          dell’ultimo piano inferiore a quella standard. Anche qui vanno
          verificate le diverse regole locali.
        </p>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Prima di iniziare qualsiasi intervento di recupero di un sottotetto è
          opportuno rivolgersi al Comune di competenza per verificare tutte le
          norme vigenti. Alcune Regioni riservano infatti ai singoli Comuni la
          possibilità di decidere quali lavori si possono fare su un sottotetto.
        </p>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Abusi edilizi
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Come si diceva all’inizio, di regola il sottotetto è un locale non è
          abitabile. Questo significa che qualunque intervento che modifica
          destinazione (ad esempio, da deposito o lavanderia ad abitazione con
          camera da letto, cucina o bagno), senza accatastamento e senza
          cambiamento di destinazione d’uso, è da considerarsi abusivo. <hr /> È
          importante che stipula dell’atto di compravendita sia rispettata la
          destinazione d’uso attuale del sottotetto. Se il sottotetto non è
          accatastato, è opportuno affidarsi a un tecnico, che potrà verificare,
          tra l’altro, se vi sono degli abusi in funzione della destinazione
          d’uso e secondo il progetto già autorizzato. Tra le verifiche
          principali da fare, il professionista:
        </p>
        <div className="align-items-start mb-2">
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>
              Analizza il regolamento urbanistico comunale su altezze e
              destinazioni d’uso dei sottotetti;
            </li>

            <li>
              Compie il rilievo del sottotetto, verificando se le misure attuali
              corrispondono a quanto dichiarato nei documenti depositati in
              Comune (quindi se vi è la presenza di un abuso da ripristinare, e
              se le caratteristiche sono tali da renderlo abitabile);
            </li>
            <li>
              Accerta se è possibile aumentare la volumetria totale della casa
              per rendere abitabile il sottotetto;
            </li>
            <li>Provvede all’accatastamento.</li>
          </ul>
        </div>
      </Container>

      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article22Text;
