import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useSession } from "./ProtectedRoutes"; // Assicurati che questa importazione sia corretta

const AdminRoutes = () => {
  const session = useSession();
  const navigate = useNavigate();

  React.useEffect(() => {
    // Controlla se l'utente è autenticato e se è un admin
    if (!session || session.role !== "admin") {
      navigate("/error", { replace: true }); // Reindirizza a /error se non è admin
    }
  }, [navigate, session]);

  return <Outlet />; // Permette l'accesso alle route per gli admin
};

export default AdminRoutes;
