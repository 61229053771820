import React from "react";
import { Button } from "react-bootstrap";
import "../Style/buttonScrollForm.css";

const ScrollButtonInvestForm = () => {
  const scrollToContact = () => {
    const contactForm = document.getElementById("SectionForm");
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Button
      className="button-ScrollForm fw-bold fs-5 mt-2"
      onClick={scrollToContact}
    >
      Fissa una call
    </Button>
  );
};

export default ScrollButtonInvestForm;
