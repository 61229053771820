import React from "react";
import Card from "react-bootstrap/Card";
import article1Img from "../../asset/article1-img.png";

import { Button, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Article1() {
  const navigate = useNavigate();

  const redirectArticle1 = () => {
    navigate("/trend-investimenti-immobiliari-2024");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img
          className="img-card"
          variant="top"
          alt="trend-immobiliare"
          title="trend-investimenti-immobiliari"
          loading="lazy"
          src={article1Img}
        />
        <Card.Body className="d-flex flex-column justify-content-around ">
          <Card.Title className="colortext-blunav">
            Mondo immobiliare, 4 trend da monitorare per l’anno 2024
          </Card.Title>

          <Button onClick={redirectArticle1} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article1;
