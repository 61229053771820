import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserInvestments } from "../../reducer/investSlice";
import { Link } from "react-router-dom";

const UserInvestments = ({ userId }) => {
  const dispatch = useDispatch();
  const userInvestsArray = useSelector(
    (state) => state.invest.userInvestsArray || []
  ); // Cambiato per usare userInvestsArray
  const status = useSelector((state) => state.invest.status || "idle");

  // Aggiungi i console.log qui
  console.log(
    "State:",
    useSelector((state) => state.invest)
  );
  console.log("User Invests Array:", userInvestsArray);

  useEffect(() => {
    if (userId) {
      dispatch(getUserInvestments(userId));
    }
  }, [dispatch, userId]);

  return (
    <div>
      {status === "pending" ? (
        <p>Caricamento in corso...</p>
      ) : userInvestsArray.length > 0 ? (
        <ul>
          {userInvestsArray.map((invest) => (
            <li key={invest._id}>
              <Link to={`/detailsInvestUser/${invest._id}`}>{invest.name}</Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>Nessun investimento trovato</p>
      )}
    </div>
  );
};

export default UserInvestments;
