import React from "react";
import { Button, ButtonToolbar, Container } from "react-bootstrap";
import "../../Style/pageSecondary.css";
import { useNavigate } from "react-router-dom";

const ButtonAdminControl = () => {
  const navigate = useNavigate();

  const redirectUtentGestionOnClick = () => {
    navigate("/utentGestion");
  };
  const redirectInvestimentGestionOnClick = () => {
    navigate("/gestionInvestiment");
  };
  return (
    <>
      <div className="d-flex justify-content-center flex-column align-items-start">
        <Button
          onClick={redirectUtentGestionOnClick}
          className="button-redLogoTrasparent mt-3 fs-5"
        >
          Gestione Utenti
        </Button>
        <Button
          onClick={redirectInvestimentGestionOnClick}
          className="button-redLogoTrasparent fs-5 mt-3"
        >
          Gestione Investimenti
        </Button>
      </div>
    </>
  );
};

export default ButtonAdminControl;
