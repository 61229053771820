import React from "react";
import Card from "react-bootstrap/Card";
import article11Img from "../../asset/article11-img.png";

import { Button, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Article11() {
  const navigate = useNavigate();

  const redirectArticle11 = () => {
    navigate("/open-house-vendite-immobiliari");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article11Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            L’Open House e l’efficacia dell’interazione umana
          </Card.Title>

          <Button onClick={redirectArticle11} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article11;
