import React, { useState, useEffect } from "react";
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFinalImageInvest,
  getFinalImages,
  investById,
} from "../../reducer/investSlice";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa6";
import SingleImageFinal from "./SingleImageFInal"; // Importa il componente per la singola immagine
import { useSession } from "../../middlewares/ProtectedRoutes";

const PatchImageFinal = ({ investId, finalImages }) => {
  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const session = useSession();
  useEffect(() => {
    // Carica le immagini finali al caricamento del componente
    dispatch(getFinalImages(investId));
  }, [dispatch, investId]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) {
      toast.error("Seleziona un'immagine da caricare", {
        position: "top-right",
        autoClose: 1500,
        theme: "dark",
      });
      return;
    }

    const formData = new FormData();
    formData.append("finalImages", image);
    setLoading(true);
    try {
      await dispatch(updateFinalImageInvest({ investId, formData })).unwrap();
      toast.success("Immagine caricata con successo!", {
        position: "top-right",
        autoClose: 1500,
        theme: "dark",
      });
      // Richiama le immagini aggiornate
      await dispatch(getFinalImages(investId));
      await dispatch(investById(investId));
      handleClose();
      setImage(null); // Resetta l'immagine
    } catch (error) {
      toast.error("Errore durante il caricamento dell'immagine", {
        position: "top-right",
        autoClose: 1500,
        theme: "dark",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {session.role === "admin" ? (
        <Container className="d-flex align-items-center justify-content-center">
          <Button
            className="d-flex align-items-center justify-content-center gap-1"
            variant="primary"
            onClick={handleShow}
          >
            <FaPlus className="fs-5" />
            Aggiungi Immagine Finale
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Carica Immagine Finale</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Seleziona Immagine</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </Form.Group>
                {image && (
                  <div className="preview">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Preview"
                      style={{ width: "100%", marginTop: "10px" }}
                    />
                  </div>
                )}

                <Button
                  className="my-3"
                  variant="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Caricamento..." : "Carica Immagine"}
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </Container>
      ) : (
        ""
      )}

      {/* Mappa e visualizza le immagini finali direttamente */}
      <Container>
        <Row className="mt-4">
          {finalImages?.length > 0 ? (
            finalImages.map((imageUrl, index) => (
              <SingleImageFinal
                key={index}
                imageUrl={imageUrl}
                index={index}
                investId={investId}
              />
            ))
          ) : (
            <p className="text-center">Nessuna immagine disponibile.</p>
          )}
        </Row>
      </Container>
    </>
  );
};

export default PatchImageFinal;
