import React, { useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GiPositionMarker } from "react-icons/gi";

import Aos from "aos";
import "aos/dist/aos.css";

import "../../Style/pageSecondary.css";

const SingleInvestHome = ({ invest }) => {
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="fade-in-element ">
      <Card className="w-100 mb-4 shadow colortext-blunav hover-card fade-in heightCardInvestHome">
        <div className="d-flex justify-content-center align-items-center ">
          <Card.Img
            variant="top"
            className="custom-card-img"
            src={invest.image}
          />
        </div>

        <Card.Body className="d-flex flex-column justify-content-around align-items-center gap-2 colortext-blunav">
          <Card.Title className="text-center fw-bold mb-1">
            {invest.name}
          </Card.Title>
          <Card.Text className="text-center colortext-blunav">
            {invest.typeOfIntervent}
          </Card.Text>
          <Card.Text className="d-flex flex-column justify-content-around align-items-center gap-2">
            <div className="d-flex justify-content-center align-items-center gap-2 fw-bold fs-5">
              <GiPositionMarker className="fs-5" /> {invest.area}
            </div>
          </Card.Text>
        </Card.Body>
        <Card.Footer
          className={`d-flex justify-content-center align-items-center fw-bold ${
            invest.state === "ATTIVO"
              ? "bg-success"
              : invest.state === "IN CORSO"
              ? "bg-warning"
              : ""
          }`}
        >
          {invest.state}
        </Card.Footer>
        <Card.Footer className="d-flex justify-content-center align-items-center fw-bold">
          <span className="me-2 fw-bold">
            ROI {invest.state === "CONCLUSO" ? "finale" : "stimato"}:
          </span>
          {invest.state === "CONCLUSO" ? invest.roiFinal : invest.roiEstimated}
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default SingleInvestHome;
