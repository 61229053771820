import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Card,
  Row,
  Modal,
  Button,
  CardBody,
} from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import SpinnerLoading from "../Components/SpinnerLoading";

import { useDispatch, useSelector } from "react-redux";
import { deleteInvest, investById } from "../reducer/investSlice";
import { useSession } from "../middlewares/ProtectedRoutes";
import { useParams } from "react-router-dom";
import { GiPositionMarker } from "react-icons/gi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import { GiReceiveMoney } from "react-icons/gi";
import { FaChartLine } from "react-icons/fa6";

import { IoCashOutline } from "react-icons/io5";
import { SiSoundcharts } from "react-icons/si";

import { FaMagnifyingGlassChart } from "react-icons/fa6";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";
import { useNavigate } from "react-router-dom";
import { FaArrowDown } from "react-icons/fa";
import { BsCalendarCheck } from "react-icons/bs";
import FinalImageUser from "../Components/Investiment/FinalImageUser";
import { toast } from "react-toastify";

import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import BarraLaterale from "../Components/BarraLaterale";
import { BiSolidBuildingHouse } from "react-icons/bi";

import { format } from "date-fns";
import { it } from "date-fns/locale";
import ProgressBarComponent from "../Components/Investiment/ProgressBarComponent";
import PatchImageFinal from "../Components/Investiment/PatchImageFinal";
import PdfFilesList from "../Components/Investiment/PdfFilesList";
import SingleImageFinal from "../Components/Investiment/SingleImageFInal";
import { FaArrowLeft } from "react-icons/fa";

const USERdetailInvest = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // Funzione per formattare la data

  const { id } = useParams();
  const session = useSession();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirectFaqOnClick = () => {
    navigate("/faq-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  const redirectBAchecaOnClick = () => {
    navigate("/bacheca-Investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  const { singleInvest } = useSelector((state) => state.invest);
  console.log(singleInvest);

  useEffect(() => {
    Aos.init();
    // Chiamata per recuperare i dati dell'utente quando il componente è montato
    dispatch(investById(id)); // Supponendo che session.userId contenga l'ID dell'utente corrente
  }, [dispatch, id]);

  return (
    <>
      <NavigationBar />
      <BarraLaterale />

      <Container fluid className="bgBluOpacity05 pb-5">
        {/* Pulsante fisso per tornare alla bacheca */}
        <div className="pt-5">
          <Button
            onClick={redirectBAchecaOnClick}
            className="button-redLogo fixed-top-left d-flex  justify-content-center align-items-center  mt-5"
          >
            <FaArrowLeft className="me-1" />
            Indietro
          </Button>
        </div>

        {singleInvest.investsById ? (
          <>
            <Container>
              <h1 className="text-center fw-bold  pt-5 colortext-blunav ">
                {singleInvest.investsById ? (
                  <p> {singleInvest.investsById.name}</p>
                ) : (
                  <SpinnerLoading />
                )}
              </h1>
              <div data-aos="fade-up">
                {singleInvest.investsById ? (
                  <img
                    className="w-100 rounded shadow"
                    src={singleInvest.investsById.image}
                  />
                ) : (
                  <SpinnerLoading />
                )}
              </div>
              {singleInvest.investsById ? (
                <Container className="bg-white m-0 text-center line-height2 py-5  overAuto">
                  <div className=" d-flex flex-column justify-content-center align-items-center">
                    <p
                      className=" me-2 text-start p-5 width-70-LG preserve-line-breaks colortext-blunav "
                      data-aos="zoom-in-up"
                      data-aos-duration="800"
                      data-aos-easing="linear"
                    >
                      {singleInvest.investsById.description}
                    </p>{" "}
                  </div>
                </Container>
              ) : (
                <SpinnerLoading />
              )}

              {singleInvest.investsById ? (
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="fade-in-element  "
                  >
                    <Card className="w-100 mb-4 shadow colortext-blunav fade-in">
                      <Card.Body>
                        <Card.Title className="text-center fw-bold  fs-1 mb-5">
                          {singleInvest.investsById.name}
                        </Card.Title>

                        <Card.Text
                          className="d-flex flex-column justify-content-around align-items-start gap-2"
                          data-aos="fade-up"
                          data-aos-duration="800"
                          data-aos-easing="linear"
                        >
                          <div className="d-flex justify-content-center align-items-center gap-2 fw-bold mb-2">
                            <GiPositionMarker className="fs-5" />{" "}
                            <span className="bold-600">Area:</span>{" "}
                            {singleInvest.investsById.area}
                          </div>
                          <div className="d-flex justify-content-start align-items-center gap-2 fw-bold flex-wrap mb-2">
                            <BiSolidBuildingHouse className="fs-5" />{" "}
                            <span className="bold-600">
                              Tipo di Intervento:
                            </span>{" "}
                            {singleInvest.investsById.typeOfIntervent}
                          </div>
                          <div className="d-flex justify-content-start mb-2 align-items-center gap-2 fw-bold flex-wrap">
                            <FaRegCalendarAlt className="fs-5" />{" "}
                            <span className="bold-600">Durata Stimata:</span>{" "}
                            {singleInvest.investsById.duration}
                          </div>
                        </Card.Text>
                        <Card.Text
                          className="d-flex flex-column justify-content-around align-items-start gap-2"
                          data-aos="fade-down"
                          data-aos-duration="800"
                          data-aos-easing="linear"
                        >
                          <div className="d-flex justify-content-center align-items-center gap-2 fw-bold flex-wrap mb-2">
                            <IoCashOutline className="fs-5" />
                            <span className="bold-600">
                              Costo dell'intervento:
                            </span>{" "}
                            <span>
                              € {singleInvest.investsById.interventionAmount}{" "}
                            </span>
                          </div>
                          <div className="d-flex justify-content-center align-items-center gap-2 fw-bold">
                            <GiReceiveMoney className="fs-5" />{" "}
                            <span className="bold-600">ROI stimato:</span>{" "}
                            {singleInvest.investsById.roiEstimated}
                          </div>

                          <div className="d-flex   justify-content-start align-items-center w-100">
                            {singleInvest.investsById &&
                            singleInvest.investsById.state === "CONCLUSO" ? (
                              <section>
                                <div
                                  className="d-flex justify-content-start align-items-center gap-2 fw-bold "
                                  id="example-fade-text"
                                >
                                  <div className="d-flex justify-content-center align-items-center gap-2">
                                    <p>
                                      <hr />
                                      <FaChartLine className="fs-5 me-2" />
                                      <span className="bold-600 me-2  ">
                                        ROI MEDIO FINALE:
                                      </span>{" "}
                                      {singleInvest.investsById.roiFinal}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="d-flex justify-content-start align-items-center gap-2 fw-bold "
                                  id="example-fade-text"
                                >
                                  <p className="d-flex justify-content-center align-items-center gap-2">
                                    <BsCalendarCheck className="fs-5 " />
                                    <span className="bold-600 ">
                                      DURATA EFFETTIVA:
                                    </span>{" "}
                                    {singleInvest.investsById.durationEffective}
                                  </p>
                                </div>
                                <div
                                  className="d-flex justify-content-startalign-items-center gap-2 fw-bold "
                                  id="example-fade-text"
                                >
                                  <p className="d-flex justify-content-startalign-items-center gap-2">
                                    <SiSoundcharts className="fs-5" />
                                    <span className="bold-600">
                                      ROI ANNUO:
                                    </span>{" "}
                                    {singleInvest.investsById.roiAnnuo}
                                  </p>
                                </div>
                              </section>
                            ) : (
                              ""
                            )}
                          </div>
                        </Card.Text>
                        <div
                          className={`d-flex justify-content-center align-items-center fw-bold w-100 py-3 ${
                            singleInvest.investsById.state === "ATTIVO"
                              ? "bg-success"
                              : singleInvest.investsById.state === "IN CORSO"
                              ? "bg-warning"
                              : ""
                          }`}
                        >
                          <span className="bold-600 me-2">
                            Stato dell'investimento:
                          </span>{" "}
                          {singleInvest.investsById.state}
                        </div>
                        <Card.Text>
                          <div className="d-flex justify-content-start align-items-start gap-1">
                            <p>
                              <span className="bold-600 ">
                                Data di Creazione:
                              </span>{" "}
                              {format(
                                new Date(singleInvest.investsById.createdAt),
                                "dd MMMM yyyy",
                                { locale: it }
                              )}
                            </p>
                          </div>
                        </Card.Text>
                      </Card.Body>
                      {singleInvest.investsById &&
                        singleInvest.investsById.participants && (
                          <Card.Footer className="color-blunav">
                            {console.log(singleInvest.investsById.participants)}
                            {singleInvest.investsById.participants.some(
                              (participant) => participant._id === session.id
                            ) && (
                              <div>
                                <p
                                  className="text-white p-3 p-lg-5  fs-5 text-center "
                                  data-aos="fade-down"
                                  data-aos-duration="800"
                                  data-aos-easing="linear"
                                >
                                  {" "}
                                  Benvenuto {session.name} {session.surname}!
                                  Siamo lieti di accoglierti e darti il
                                  benvenuto nel nostro Club Investitori Premium,
                                  un mondo dedicato agli investimenti
                                  immobiliari sicuri e profittevoli.
                                </p>
                                <ProgressBarComponent
                                  progress={singleInvest.investsById.progress}
                                />
                                <div className="d-flex justify-content-center align-items-center flex-column text-white ">
                                  <p
                                    className=" p-lg-5 fs-5"
                                    data-aos="fade-up"
                                    data-aos-duration="800"
                                    data-aos-easing="linear"
                                  >
                                    {" "}
                                    <hr />
                                    Qui potrai monitorare in tempo reale il tuo
                                    investimento, che sarà aggiornato
                                    regolarmente per offrirti informazioni
                                    chiare e trasparenti sull’andamento
                                    dell’operazione immobiliare, così da poter
                                    seguire ogni fase del processo con
                                    consapevolezza e fiducia. Il nostro team ti
                                    terrà aggiornato su ogni step attraverso un
                                    Business Plan dettagliato, lo stato
                                    avanzamento lavori costantemente aggiornato,
                                    fotografie, e report periodici che ti
                                    permetteranno di monitorare il tuo
                                    investimento immobiliare.
                                  </p>
                                  <p className="  fs-5">
                                    <span className=" text-center title-card">
                                      {" "}
                                      Essendo un membro del Club, potrai
                                      usufruire di una serie di vantaggi a te
                                      dedicati:
                                    </span>
                                    <ol className="my-3 d-flex justify-content-start align-items-start flex-column gap-3">
                                      <li
                                        data-aos="fade-right"
                                        data-aos-duration="800"
                                        data-aos-easing="ease-in-sine"
                                      >
                                        Accesso prioritario a nuove operazioni
                                        immobiliari
                                      </li>
                                      <li
                                        data-aos="fade-right"
                                        data-aos-duration="800"
                                        data-aos-easing="ease-in-sine"
                                      >
                                        Consulenza dedicata
                                      </li>
                                      <li
                                        data-aos="fade-right"
                                        data-aos-duration="800"
                                        data-aos-easing="ease-in-sine"
                                      >
                                        Opzioni di co- investimento riservate
                                      </li>
                                      <li
                                        data-aos="fade-right"
                                        data-aos-duration="800"
                                        data-aos-easing="ease-in-sine"
                                      >
                                        Condizioni finanziarie migliorative
                                      </li>
                                      <li
                                        data-aos="fade-right"
                                        data-aos-duration="800"
                                        data-aos-easing="ease-in-sine"
                                      >
                                        Inviti ad eventi esclusivi
                                      </li>
                                      <li
                                        data-aos="fade-right"
                                        data-aos-duration="800"
                                        data-aos-easing="ease-in-sine"
                                      >
                                        Omaggio di gadget aziendali
                                      </li>
                                      <li
                                        data-aos="fade-right"
                                        data-aos-duration="800"
                                        data-aos-easing="ease-in-sine"
                                      >
                                        Newsletter sul mondo immobiliare
                                      </li>
                                    </ol>
                                  </p>
                                  <p className=" text-center fs-3 title-card">
                                    Inizia a visionare il tuo investimento
                                    Immobiliare!
                                  </p>
                                  <PdfFilesList
                                    pdfFiles={singleInvest.investsById.pdfFile}
                                  />
                                  <Button
                                    className="button-linkStyle fs-5 fw-bold d-flex justify-content-center align-items-center pb-5 mt-5"
                                    onClick={() => {
                                      navigate(`/investments/${id}/updates`); // Naviga a una nuova pagina per gli aggiornamenti
                                      window.scrollTo(0, 0); // Effettua lo scroll all'inizio della pagina
                                    }}
                                  >
                                    Visualizza lo stato dell'avanzamento dei
                                    lavori
                                    <FaArrowRight className="fs-4 ms-1 zigzag-animation" />
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Card.Footer>
                        )}
                    </Card>
                  </Col>
                </Row>
              ) : (
                <SpinnerLoading />
              )}
            </Container>
            {singleInvest.investsById.state === "CONCLUSO" ? (
              <Container>
                <h3 className="text-center fs-3 fw-bold my-3">
                  Foto del progetto concluso:
                </h3>
                {singleInvest.investsById &&
                singleInvest.investsById.finalImages ? (
                  <Container>
                    <Row className="mt-4">
                      {singleInvest.investsById.finalImages.length > 0 ? (
                        singleInvest.investsById.finalImages.map(
                          (imageUrl, index) => (
                            <FinalImageUser
                              key={index}
                              imageUrl={imageUrl}
                              index={index}
                              investId={singleInvest.investsById._id}
                            />
                          )
                        )
                      ) : (
                        <p className="text-center">
                          Nessuna immagine disponibile.
                        </p>
                      )}
                    </Row>
                  </Container>
                ) : (
                  <SpinnerLoading />
                )}
              </Container>
            ) : null}
          </>
        ) : (
          <SpinnerLoading />
        )}
        <Container className="text-center flex-column d-flex justify-content-center align-items-center my-5">
          <p className="fs-4 fw-bold colortext-blunav">
            Consulta le nostre FAQ per vedere le domande più frequenti{" "}
          </p>
          <Button
            className="my-2 d-flex justify-content-center align-items-center button-redLogo"
            onClick={redirectFaqOnClick}
          >
            Leggi le FAQ <FaArrowRight className="fs-5 ms-1" />
          </Button>
        </Container>

        <Container>
          <h2
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-sine"
            className="text-center bold-900 colortext-blunav fs-1"
          >
            Per qualsiasi necessità o informazioni non esitare a contattarci
          </h2>

          <p className="fw-bold text-center fs-4 mt-5 colortext-blunav">
            Chiama ora il numero verde gratuito o contattaci via email
          </p>
        </Container>
        <p className="text-center">
          <FaArrowDown className="fs-1 mt-1 colortext-blunav " />
        </p>
      </Container>
      <CallToActionNumberGreen />

      <Footer />
    </>
  );
};

export default USERdetailInvest;
