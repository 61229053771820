import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const endpoint = process.env.REACT_APP_SERVER_BASE_URL;

const initialState = {
  investsArray: [],
  userInvestsArray: [], // Gli investimenti specifici dell'utente
  invest: null,
  status: "idle",
  singleInvest: {},
};

const investSlice = createSlice({
  name: "invests",
  initialState,
  reducers: {
    filterinvestName: (state, action) => {
      state.investsArray = state.investsArray.filter((invest) => {
        return invest.name.toLowerCase().includes(action.payload.toLowerCase());
      });
    },
    filterByState: (state, action) => {
      state.investsArray = state.investsArray.filter((invest) => {
        return invest.state === action.payload;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      //Chiamata POST PER CREARE investimento
      .addCase(investPost.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(investPost.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(investPost.pending, (state, action) => {
        state.status = "pending";
      })

      //Chiamata GET PER AVERE TUTTI GLI investimenti
      .addCase(getInvest.fulfilled, (state, action) => {
        state.investsArray = action.payload;
      })
      .addCase(getInvest.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(getInvest.pending, (state, action) => {
        state.status = "pending";
      })

      //Chiamata per cercare investimento tramite ID
      .addCase(investById.fulfilled, (state, action) => {
        state.singleInvest = action.payload;
      })
      .addCase(investById.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(investById.rejected, (state, action) => {
        state.status = "error";
      })

      //CHIAMATA PATCH PER MODIFICARE INVESTIMENTO
      .addCase(updateInvest.fulfilled, (state, action) => {
        // Trova l'utente nell'array degli utenti e aggiorna i suoi dati
        const index = state.investsArray.findIndex(
          (invest) => invest._id === action.payload._id
        );
        if (index !== -1) {
          state.investsArray[index] = action.payload;
        }
        // Imposta lo status a "idle" per indicare che la chiamata è stata completata
        state.status = "idle";
      })
      .addCase(updateInvest.rejected, (state, action) => {
        // In caso di errore, impostiamo lo status a "error"
        state.status = "error";
      })
      .addCase(updateInvest.pending, (state, action) => {
        // Inizia a caricare, impostando lo status a "pending"
        state.status = "pending";
      })
      //CHIAMATA PATCH PER MODIFICARE SOLO L'IMMAGINE DELL'INVESTIMENTO
      .addCase(updateInvestImage.fulfilled, (state, action) => {
        const updatedInvest = action.payload; // Dati dell'investimento aggiornato
        const index = state.investsArray.findIndex(
          (invest) => invest._id === updatedInvest._id
        );
        if (index !== -1) {
          state.investsArray[index] = updatedInvest; // Aggiorna l'investimento nell'array
        }
        state.status = "idle";
      })
      .addCase(updateInvestImage.rejected, (state, action) => {
        state.status = "error"; // Gestisci lo stato di errore
      })
      .addCase(updateInvestImage.pending, (state, action) => {
        state.status = "pending"; // Gestisci lo stato di caricamento
      })

      //CHIAMATA DELETE PER CANCELLARE INVESTIMENTO
      .addCase(deleteInvest.fulfilled, (state, action) => {
        // Rimuovi l'utente dall'array degli utenti
        state.investsArray = state.investsArray.filter(
          (invest) => invest._id !== action.payload
        );
        // Imposta lo status a "idle" per indicare che la chiamata è stata completata
        state.status = "idle";
      })
      .addCase(deleteInvest.rejected, (state, action) => {
        // In caso di errore, impostiamo lo status a "error"
        state.status = "error";
      })
      .addCase(deleteInvest.pending, (state, action) => {
        // Inizia a caricare, impostando lo status a "pending"
        state.status = "pending";
      })
      //CHIAMATA POST PER AGGIUNGERE UTENTE ALL'INVESTIMENTO
      .addCase(addUserToInvest.fulfilled, (state, action) => {
        const updatedInvest = action.payload; // Dati dell'investimento aggiornato
        const index = state.investsArray.findIndex(
          (invest) => invest._id === updatedInvest._id
        );
        if (index !== -1) {
          state.investsArray[index] = updatedInvest; // Aggiorna l'investimento nell'array
        }
      })

      .addCase(addUserToInvest.rejected, (state, action) => {
        // Gestisci gli errori
      })
      .addCase(addUserToInvest.pending, (state, action) => {
        // Gestisci lo stato di caricamento
      })

      // Chiamata GET per ottenere tutti i partecipanti di un investimento
      .addCase(getInvestParticipants.fulfilled, (state, action) => {
        state.singleInvest.participants = action.payload;
      })
      .addCase(getInvestParticipants.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(getInvestParticipants.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(deleteParticipantFromInvest.fulfilled, (state, action) => {
        const updatedInvest = action.payload; // Dati dell'investimento aggiornato
        const index = state.investsArray.findIndex(
          (invest) => invest._id === updatedInvest._id
        );
        if (index !== -1) {
          state.investsArray[index] = updatedInvest; // Aggiorna l'investimento nell'array
        }
      })
      .addCase(deleteParticipantFromInvest.rejected, (state, action) => {
        state.status = "error"; // Gestisci lo stato di errore
      })
      .addCase(deleteParticipantFromInvest.pending, (state, action) => {
        state.status = "pending"; // Gestisci lo stato di caricamento
      })
      //CHIAMATA PER AVERE TUTTI GLI INVESTIMENTI DI UN UTENTE
      .addCase(getUserInvestments.fulfilled, (state, action) => {
        console.log("Dati ricevuti:", action.payload);
        state.userInvestsArray = action.payload; // Aggiorna userInvestsArray
        state.status = "idle";
      })
      .addCase(getUserInvestments.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getUserInvestments.rejected, (state, action) => {
        state.status = "error";
      })

      // CHIAMATA PATCH PER AGGIORNARE L'IMMAGINE FINALE DELL'INVESTIMENTO
      .addCase(updateFinalImageInvest.pending, (state) => {
        state.status = "loading"; // Imposta lo stato su loading mentre la richiesta è in corso
      })
      .addCase(updateFinalImageInvest.fulfilled, (state, action) => {
        const updatedInvest = action.payload; // Dati dell'investimento aggiornato
        const index = state.investsArray.findIndex(
          (invest) => invest._id === updatedInvest._id
        );
        if (index !== -1) {
          state.investsArray[index] = updatedInvest; // Aggiorna l'investimento nell'array
        }
        state.status = "idle"; // Imposta lo stato su idle
      })
      .addCase(updateFinalImageInvest.rejected, (state, action) => {
        state.status = "error"; // Gestisci lo stato di errore
      })
      // Chiamata GET per ottenere tutte le immagini finali di un investimento
      .addCase(getFinalImages.fulfilled, (state, action) => {
        state.singleInvest.finalImages = action.payload; // Salva le immagini finali nell'oggetto singleInvest
        state.status = "idle"; // Imposta lo stato su idle
      })
      .addCase(getFinalImages.pending, (state) => {
        state.status = "loading"; // Imposta lo stato su loading mentre la richiesta è in corso
      })
      .addCase(getFinalImages.rejected, (state) => {
        state.status = "error"; // Gestisci lo stato di errore
      })
      // CHIAMATA DELETE PER RIMUOVERE UN'IMMAGINE FINALE
      .addCase(deleteFinalImage.pending, (state) => {
        state.status = "loading"; // Imposta lo stato su loading mentre la richiesta è in corso
      })
      .addCase(deleteFinalImage.fulfilled, (state, action) => {
        const updatedInvest = action.payload; // Dati dell'investimento aggiornato
        const index = state.investsArray.findIndex(
          (invest) => invest._id === updatedInvest._id
        );
        if (index !== -1) {
          state.investsArray[index] = updatedInvest; // Aggiorna l'investimento nell'array
        }
        state.status = "idle"; // Imposta lo stato su idle
      })
      .addCase(deleteFinalImage.rejected, (state, action) => {
        state.status = "error"; // Gestisci lo stato di errore
      })
      // Gestione della chiamata per caricare il file PDF
      .addCase(uploadPdfFile.pending, (state) => {
        state.status = "loading"; // Imposta lo stato su loading mentre la richiesta è in corso
      })
      .addCase(uploadPdfFile.fulfilled, (state, action) => {
        const updatedInvest = action.payload;
        const index = state.investsArray.findIndex(
          (invest) => invest._id === updatedInvest._id
        );
        if (index !== -1) {
          state.investsArray[index] = updatedInvest; // Aggiorna l'investimento nell'array
        }
        state.status = "idle"; // Imposta lo stato su idle
      })
      .addCase(uploadPdfFile.rejected, (state) => {
        state.status = "error"; // Gestisci lo stato di errore
      })
      // CHIAMATA DELETE PER RIMUOVERE UN FILE PDF
      .addCase(deletePdfFile.fulfilled, (state, action) => {
        const updatedInvest = action.payload; // Dati dell'investimento aggiornato
        const index = state.investsArray.findIndex(
          (invest) => invest._id === updatedInvest._id
        );
        if (index !== -1) {
          state.investsArray[index] = updatedInvest; // Aggiorna l'investimento nell'array
        }
        state.status = "idle"; // Imposta lo stato su idle
      })
      .addCase(deletePdfFile.rejected, (state, action) => {
        state.status = "error"; // Gestisci lo stato di errore
      })
      .addCase(deletePdfFile.pending, (state) => {
        state.status = "loading"; // Imposta lo stato su loading mentre la richiesta è in corso
      });
  },
});

//Chiamata POST PER CREARE INVESTIMENTO
export const investPost = createAsyncThunk("create/invests", async (invest) => {
  const form = new FormData();

  form.append("name", invest.name);
  form.append("area", invest.area);
  form.append("typeOfIntervent", invest.typeOfIntervent);
  form.append("duration", invest.duration);
  form.append("interventionAmount", invest.interventionAmount);
  form.append("roiEstimated", invest.roiEstimated);
  form.append("durationEffective", invest.durationEffective);
  form.append("roiAnnuo", invest.roiAnnuo);
  form.append("image", invest.image);
  form.append("description", invest.description);
  form.append("roiFinal", invest.roiFinal);
  form.append("state", invest.state);

  try {
    const res = await axios.post(`${endpoint}/invests/create`, form, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

//CHIAMATA GET PER AVERE TUTTI GLI INVESTIMENTI
export const getInvest = createAsyncThunk("invest/all", async () => {
  try {
    const res = await axios.get(`${endpoint}/invests/all`);
    if (!res) {
      console.log(`HTTP error! status: ${res.status}`);
    }

    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

//Chiamata GET by ID dell'Investimento
export const investById = createAsyncThunk("invests/getById", async (id) => {
  try {
    const res = await axios.get(`${endpoint}/invests/` + id);
    if (!res) {
      console.log(`HTTP error! status: ${res.status}`);
    }
    console.log("LOG GETbyID investiment SLICE", res.data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

// CHIAMATA PATCH PER MODIFICARE INFO DELL'INVESTIMENTO
export const updateInvest = createAsyncThunk(
  "invests/update",
  async ({ id, updates }) => {
    try {
      const res = await axios.patch(`${endpoint}/invests/${id}`, updates);
      return res.data; // Restituisci i dati dell'utente aggiornati per la gestione nell'extraReducers
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

// CHIAMATA PATCH PER AGGIORNARE SOLO L'IMMAGINE DELL'INVESTIMENTO
export const updateInvestImage = createAsyncThunk(
  "invests/updateImage",
  async ({ id, image }) => {
    const form = new FormData();
    form.append("image", image);

    try {
      const res = await axios.patch(
        `${endpoint}/invests/${id}/updateImage`,
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return res.data; // Restituisci i dati dell'investimento aggiornato per la gestione nell'extraReducers
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

//CHIAMATA POST PER AGGIUNGERE UTENTE ALL'INVESTIMENTO
export const addUserToInvest = createAsyncThunk(
  "invests/addUser",
  async ({ investId, userId }) => {
    try {
      const res = await axios.post(`${endpoint}/invests/${investId}/addUser`, {
        userId,
      });
      console.log("ID UTENTE PARTECIPANTE", res.data);

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

// CHIAMATA DELETE PER RIMUOVERE PARTECIPANTE DA UN INVESTIMENTO
export const deleteParticipantFromInvest = createAsyncThunk(
  "invests/removeParticipant",
  async ({ investId, participantId }) => {
    try {
      const res = await axios.delete(
        `${endpoint}/invests/${investId}/removeParticipant/${participantId}`
      );
      return res.data; // Restituisci l'investimento aggiornato
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

// Chiamata GET per ottenere tutti i partecipanti di un investimento
export const getInvestParticipants = createAsyncThunk(
  "invests/getParticipants",
  async (id) => {
    try {
      const res = await axios.get(`${endpoint}/invests/${id}/participants`);
      if (!res) {
        console.log(`HTTP error! status: ${res.status}`);
      }
      console.log("LOG GET ALL participants SLICE", res.data);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//CHIAMATA DELETE PER CANCELLARE INVESTIMENTO
export const deleteInvest = createAsyncThunk("invests/delete", async (id) => {
  try {
    const res = await axios.delete(`${endpoint}/invests/${id}`);
    return id; // Restituisci l'ID dell'investimento eliminato per la gestione nell'extraReducers
  } catch (error) {
    console.error(error);
    throw error;
  }
});

// Chiamata GET per ottenere gli investimenti di un utente specifico
export const getUserInvestments = createAsyncThunk(
  "invests/getUserInvestments",
  async (userId) => {
    try {
      const res = await axios.get(`${endpoint}/invests/participants/${userId}`);
      if (!res) {
        console.log(`HTTP error! status: ${res.status}`);
      }
      console.log("LOG GET USER INVESTMENTS SLICE", res.data);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// CHIAMATA PATCH PER AGGIORNARE L'IMMAGINE FINALE DELL'INVESTIMENTO
export const updateFinalImageInvest = createAsyncThunk(
  "invest/updateFinalImage",
  async ({ investId, formData }) => {
    const response = await axios.patch(
      `${endpoint}/invests/${investId}/addFinalImage`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data; // Assicurati che la risposta contenga i dati necessari
  }
);

// Chiamata GET per ottenere tutte le immagini finali di un investimento specifico
export const getFinalImages = createAsyncThunk(
  "invests/getFinalImages",
  async (id) => {
    try {
      const res = await axios.get(`${endpoint}/invests/${id}/final-images`);
      return res.data; // Restituisci le immagini finali
    } catch (error) {
      console.error(error);
      throw error; // Propaga l'errore per gestirlo negli extraReducers
    }
  }
);

// CHIAMATA DELETE PER RIMUOVERE UN'IMMAGINE FINALE
export const deleteFinalImage = createAsyncThunk(
  "invests/deleteFinalImage",
  async ({ id, imageUrl }) => {
    try {
      const res = await axios.delete(
        `${endpoint}/invests/${id}/removeFinalImage`,
        {
          data: { imageUrl }, // Passa l'URL dell'immagine nel body della richiesta
        }
      );
      return res.data.invest; // Restituisci l'investimento aggiornato
    } catch (error) {
      console.error("Errore nella rimozione dell'immagine finale:", error);
      throw error;
    }
  }
);

// CHIAMATA POST PER CARICARE UN FILE PDF IN UN INVESTIMENTO
export const uploadPdfFile = createAsyncThunk(
  "invest/uploadPDF",
  async ({ investId, pdfFile }) => {
    const formData = new FormData();
    formData.append("pdfFile", pdfFile);

    try {
      const response = await axios.post(
        `${endpoint}/invests/${investId}/uploadPDF`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return response.data.invest;
    } catch (error) {
      console.error("Errore durante il caricamento del PDF:", error);
      throw error;
    }
  }
);

// Chiamata DELETE per rimuovere un file PDF di un investimento
export const deletePdfFile = createAsyncThunk(
  "delete/pdf",
  async ({ investId, pdfId }) => {
    const response = await axios.delete(
      `${endpoint}/invests/${investId}/removePdf/${pdfId}`
    );
    return response.data.invest; // Ritorna l'investimento aggiornato
  }
);

export const { filterinvestName, filterByState } = investSlice.actions;
export default investSlice.reducer;
