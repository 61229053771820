import React, { useState, useEffect } from "react";
import "../Style/pageSecondary.css";
import { Button, Container } from "react-bootstrap";
import LibroBanner from "../asset/LibroBannerSito.png";
import { useSession } from "../middlewares/ProtectedRoutes"; // Assumi che l'hook useSession sia in un file separato

const BannerBook = () => {
  const [showBanner, setShowBanner] = useState(false);
  const session = useSession(); // Usa l'hook per verificare la sessione

  useEffect(() => {
    // Mostra il banner solo se l'utente non è loggato
    if (!session) {
      const showBannerFunction = () => {
        setShowBanner(true);
      };

      const showBannerTimeout = setTimeout(showBannerFunction, 20000); // Mostra il banner dopo 20 secondi

      const interval = setInterval(showBannerFunction, 5 * 60 * 1000); // Ogni 5 minuti

      return () => {
        clearTimeout(showBannerTimeout);
        clearInterval(interval);
      };
    }
  }, [session]); // Dipende dalla sessione

  const handleCloseBanner = () => {
    setShowBanner(false);
  };

  return (
    <>
      <Container className="h100 w-100  colortext-blunav ">
        {showBanner && (
          <div className="banner z-index-max-banner p-lg-5 ">
            <img src={LibroBanner} alt="" className="w-100" />
            <p className="text-center fw-bold mt-3 fs-5">
              Sei interessato agli investimenti immobiliari? <hr />
              Registrati gratuitamente per ottenere il nostro Ebook!
            </p>
            <div className="text-center">
              <Button className="button-redLogo" onClick={handleCloseBanner}>
                Chiudi
              </Button>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default BannerBook;
