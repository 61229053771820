import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";
import { Helmet } from "react-helmet";

import article12Img from "../../asset/article12-img.png";

const Article12Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/articoli-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Cos’è il DURC ed a cosa serve</title>
        <meta
          name="description"
          content="Cos’è il DURC e a cosa serve,come ottenerlo ed a cosa serve nei progetti di edilizia"
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/a-cosa-serve-il-DURC"
        />
      </Helmet>

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article12Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Cos’è il DURC ed a cosa serve!
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center ">
          Nei lavori di edilizia privata è necessario assicurare la regolarità
          delle posizioni contributive dei lavoratori in cantiere da parte della
          ditta che segue le opere? Quali sono le responsabilità del committente
          in materia? Ecco le principali informazioni sull'obbligo del DURC nei
          lavori di edilizia privata.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Cos'è il DURC?
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Si tratta del Documento Unico di Regolarità Contributiva,
            un'attestazione richiesta dalla legge a carico delle imprese
            appaltatrici riferita alla regolarità della posizione contributiva
            di queste ultime nei confronti degli enti previdenziali: in altri
            termini, con il rilascio di questo documento si afferma
            positivamente che l'impresa ha versato i contributi INPS, INAIL e
            Casse Edili dei propri dipendenti. <hr /> Il rilascio di questa
            attestazione avviene dietro richiesta ai diversi enti previdenziali.
            Dal punto di vista formale, il DURC deve contenere tutti i dati
            relativi all'anagrafe aziendale (denominazione, sede, oggetto
            sociale, ecc.), oltre che i numeri di iscrizione all'Ente di
            riferimento. <hr />
            Il DURC è stato introdotto nel 2002 come requisito di partecipazione
            agli appalti pubblici; successivamente il Codice degli Appalti 2006
            (con norma sostanzialmente riproposta nel nuovo Codice di cui al
            D.Lgs. 50/2016) ha generalizzato la presenza del DURC per gli
            affidamenti di lavori pubblici e per la gestione dei lavori edili
            privati.
          </p>
        </Container>
      </Container>
      <Container fluid className="mb-5 bgBluOpacity05 py-5">
        <Container>
          <div className="mb-4">
            <h2 className="colortext-blunav fs-1  fw-bold text-center">
              Il DURC è necessario nei lavori di edilizia privata?
            </h2>
            <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
              La legge dispone che è necessario che le imprese edilizie siano in
              possesso del DURC anche per l'esecuzione di alcuni lavori di
              edilizia privata, cioè tutte le opere realizzate in favore di un
              committente che non è una pubblica amministrazione. In
              particolare, l'impresa appaltatrice ha il dovere di dotarsi
              dell'attestazione in tutti i casi in cui i lavori da svolgere sono
              sottoposti alla condizione del rilascio di autorizzazioni
              amministrative, come DIA, SCIA e Permesso di Costruire, come anche
              per le opere di Ordinaria Manutenzione. Ne consegue che il DURC
              risulta obbligatorio quando è necessario effettuare lavori di
              manutenzione ordinaria, e straordinaria, restauro o risanamento,
              per i quali le leggi e gli strumenti urbanistici richiedono, o non
              la presentazione di un'istanza di autorizzazione al Comune.
              Inoltre, l'impresa edile è obbligata a richiedere il DURC anche
              alle eventuali ditte subappaltatrici cui abbia affidato una o più
              porzioni dei lavori nel proprio cantiere, onde verificare la
              regolarità contributiva di queste ultime. In assenza, l'impresa
              subappaltatrice non ha diritto ai pagamenti per l'opera
              effettivamente prestata. Aggiornamento 2021/2022: A partire dal 1°
              Novembre 2021, il DURC diviene obbligatorio per ogni cantiere
              privato, e attraverso lo stesso dovrà essere denunciato un numero
              minimo di lavoratori per tipo di lavorazione. Nel Articolo 8,
              comma 10 del D.l. 76 del 16 luglio 2020, si specifica che: "al
              Documento Unico di Regolarità Contributiva è aggiunto quello
              relativo alla congruità dell’incidenza della manodopera relativa
              allo specifico intervento, secondo le modalità indicate con
              decreto del Ministro del lavoro e delle politiche sociali."
            </p>
          </div>
        </Container>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          In che modo si ottiene il DURC?
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Per le tipologie evidenziate di lavori di edilizia privata per i quali
          è necessario ottenere il DURC, la procedura connessa al rilascio del
          documento è stata modificata dalla legge 34/2014, secondo la quale  è
          possibile richiedere l'attestazione anche online , ricevendo il
          documento in formato elettronico presso l'indirizzo PEC
          obbligatoriamente tenuto dall'impresa edile. In alternativa, è ancora
          possibile chiedere il DURC in modo tradizionale, presentandone
          richiesta all'Ente Previdenziale interessato a mezzo del legale
          rappresentante dell'impresa, di un consulente del lavoro o del
          commercialista. Condizione per il rilascio del Documento è che
          l'impresa richiedente sia in regola con i pagamenti previdenziali
          mensili fino al momento dell'istanza. In questo caso, l'Ente emette
          l'attestazione entro 15 giorni dalla richiesta. Il documento ha una
          validità di 120 giorni, con la scadenza che deve essere specificamente
          indicata sul titolo stesso. Ne consegue che per lavori di durata
          superiore ai quattro mesi sarà necessario rinnovare la richiesta e
          ottenere un nuovo DURC.
        </p>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Quali sono le sanzioni per l'assenza del DURC?
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Sebbene l'obbligo di dotarsi del DURC gravi sull'impresa a cui sono
          affidati i lavori,
          <span className=" bold-900 me-1">
            {" "}
            la legge dispone che il committente acquisisca il documento da parte
            della ditta appaltatrice. Se il DURC non viene fornito al
            committente, questi, in solido con il Direttore dei Lavori, viene
            ritenuto responsabile e può essere condannato alla sanzione
            dell'arresto da 2 a 4 mesi e al pagamento di un'ammenda che va da
            1.106,19 a 5.309,73 €
          </span>
          . Tuttavia, il Direttore dei Lavori, anche quando il DURC viene
          regolarmente trasmesso da parte della ditta che esegue le opere, può
          verificare periodicamente che il datore di lavoro sia in regola con i
          versamenti riferiti ai propri dipendenti, eventualmente con facoltà di
          chiedere chiarimenti e integrazioni in caso di irregolarità: è quanto
          affermano gli artt. 123- 124 del D.P.R. 554/1999. <hr /> Peraltro, il
          DURC deve essere acquisito anche da parte dell'Amministrazione che
          rilascia i titoli autorizzativi per l'esecuzione dei lavori: se
          vengono riscontrate irregolarità nel documento, il Comune dovrà
          sospendere o revocare l'efficacia del Permesso di Costruire o della
          DIA/SCIA avanzate. <hr /> Per quanto concerne l'impresa non in regola
          con il versamento degli obblighi previdenziali, invece, l'eventuale
          ispezione sul cantiere che dovesse accertare la presenza di operai non
          regolari può determinare conseguenze molto gravi, che vanno dalla
          semplice interruzione dei lavori, passando per la sospensione
          dell'abilitazione all'esercizio dell'attività e, in caso di processo
          penale, alla condanna per la commissione del reato di omesso
          versamento delle ritenute previdenziali.
        </p>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article12Text;
