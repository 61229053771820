import React, { useState, useRef } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  uploadPdfFile,
  investById,
  deletePdfFile,
} from "../../reducer/investSlice";
import { FaFilePdf } from "react-icons/fa6";
import { FaTrash } from "react-icons/fa";

const UploadPDF = ({ investId, pdfFiles }) => {
  const [pdfFile, setPdfFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedPdfId, setSelectedPdfId] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Stato per il caricamento
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setPdfFile(file);
      console.log("File selezionato:", file);
    } else {
      toast.error("Per favore, carica un file PDF valido.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleUpload = async () => {
    if (!pdfFile) {
      toast.error("Seleziona un file PDF da caricare!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    setIsLoading(true); // Imposta lo stato di caricamento su true
    try {
      await dispatch(uploadPdfFile({ investId, pdfFile })).unwrap();
      setPdfFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      toast.success("PDF caricato con successo!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      await dispatch(investById(investId));
    } catch (error) {
      toast.error("Errore nel caricamento del PDF, riprova.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } finally {
      setIsLoading(false); // Imposta lo stato di caricamento su false
    }
  };

  const handleDeleteClick = (pdfId) => {
    setSelectedPdfId(pdfId);
    setShowModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await dispatch(
        deletePdfFile({ investId, pdfId: selectedPdfId })
      ).unwrap();
      toast.success("PDF eliminato con successo!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      await dispatch(investById(investId));
      setShowModal(false);
    } catch (error) {
      toast.error("Errore nella cancellazione del PDF, riprova.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <Container className="bg-white my-5 py-5 shadow">
      <p className="fw-bold fs-4 colortext-blunav">LISTA DOCUMENTI</p>
      <Form
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "5px",
        }}
      >
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>
            Carica BUSINESS PLANE e documenti PDF dell'investimento
          </Form.Label>
          <Form.Control
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        </Form.Group>
        <Button variant="primary" onClick={handleUpload} disabled={isLoading}>
          {isLoading ? "Caricamento..." : "Carica PDF"}
        </Button>
      </Form>
      <ul>
        {pdfFiles &&
          pdfFiles.map((pdf) => (
            <li
              key={pdf._id}
              className="mb-3 d-flex justify-content-start align-items-center my-3"
            >
              <a
                href={pdf.url}
                target="_blank"
                className="colortext-blunav"
                rel="noopener noreferrer"
              >
                <FaFilePdf className="fs-4 me-2 red-logo" />
                {pdf.fileName}
              </a>
              <Button
                variant="danger"
                className="ms-5 "
                onClick={() => handleDeleteClick(pdf._id)}
              >
                <FaTrash />
              </Button>
            </li>
          ))}
      </ul>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma Eliminazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sei sicuro di voler eliminare questo file PDF?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annulla
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UploadPDF;
