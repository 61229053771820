import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import article6Img from "../../asset/article6-img.png";

const Article6Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/articoli-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Plusvalenze immobiliari</title>
        <meta
          name="description"
          content="come si calcolano e cosa sono le plusvalenze immobiliari,come vengono tassate,come pagare le plusvalenze"
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/plusvalenze-immobiliari-come-si-calcolano"
        />
      </Helmet>

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article6Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Plusvalenze immobiliari: cosa è, è come si calcolano nel 2024
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center">
          Quando si acquista un immobile e poi lo si rivende (presumibilmente a
          un prezzo più alto) si realizza una plusvalenza immobiliare. Su quel
          guadagno il Fisco vuole la sua parte e dal 2024 si pagherà il 26%
          anche sulle plusvalenze delle seconde case ristrutturate col
          superbonus. Ecco come si fanno i calcoli, chi deve pagare l’imposta e
          come farlo senza rischiare sanzioni.
          <hr /> Cosa s'intende per plusvalenza da vendita di un immobile? è
          importante conoscere esattamente cos'è e come si calcola
          una plusvalenza immobiliare, in vista anche dell'inasprimento della
          pressione fiscale sulle seconde case ristrutturate col superbonus.
          Cerchiamo allora di capire quali sono i casi in cui si viene tassati e
          se ci sono modi per risparmiare.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Che cos'è una plusvalenza immobiliare
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Se si compra una casa a scopo di investimento, per poi rivenderla in
            un secondo momento si ottiene un guadagno. Questo guadagno, che in
            termini fiscali si chiama plusvalenza, può derivare dal semplice
            aumento del valore commerciale dell’immobile negli anni, ad esempio,
            per una rivalutazione della zona in cui si trova, oppure per
            interventi di miglioramento che sono stati eseguiti, e che hanno
            comportato l’aumento del valore dell’immobile stesso. La plusvalenza
            viene considerata dallo Stato come un reddito diverso, cioè che non
            deriva da capitale investito o dal lavoro, e come tale viene
            tassato.
          </p>
        </Container>
      </Container>
      <Container fluid className="mb-5 bgBluOpacity05 py-5">
        <Container>
          <div className="mb-4">
            <h2 className="colortext-blunav fs-1  fw-bold text-center">
              Come si calcola
            </h2>
            <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
              La plusvalenza è data dalla differenza tra quanto una persona
              ottiene dalla vendita di un immobile e quanto ha speso per
              l’acquisto. Tuttavia, la tassazione è applicata sulla plusvalenza
              calcolata al netto delle spese, in pratica, dal risultato della
              differenza che abbiamo visto, si devono sottrarre:
            </p>
          </div>
          <div className="align-items-start">
            <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
              <li>Le tasse pagare per l’acquisto della casa;</li>
              <li>
                L’onorario del notaio e ogni altra spesa sostenuta in sede di
                acquisto come quelle per l’intermediazione immobiliare;
              </li>
              <li>Eventuali spese di manutenzione straordinaria.</li>
            </ul>
          </div>
        </Container>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Facciamo un esempio
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Se si acquista una casa per 150.000,00 euro, pagando 4.000,00 euro di
          notaio e 6.000,00 euro di imposte, rivendendola a 180.000,00 euro, la
          plusvalenza tassabile sarà di 20.000,00 euro. Infatti, ai 150 .0000,00
          euro si sommano le spese sostenute all’atto d’acquisto che ammontano a
          10.000,00 euro e si sottraggono quindi 160.000,00 da 180.000,00. Se
          però durante il periodo di possesso sono stati fatti dei lavori di
          ristrutturazione che ammontano a 15.000,00 euro complessivi, la
          plusvalenza da tassare sarà solo di 5.000,00 euro.
        </p>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Quali plusvalenze non vengono tassate?
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Fortunatamente non sempre la plusvalenza viene tassata infatti, lo
            Stato riconosce diversi casi di esclusione se l’immobile:
          </p>
        </div>
        <div className="align-items-start">
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>E’ stato comprato o costruito da più di 5 anni;</li>
            <li>E’ stato ereditato;</li>
            <li>
              Per la maggior parte del tempo intercorso tra
              l’acquisto/costruzione e la vendita è stato utilizzato
              come abitazione principale dal proprietario o dai suoi
              familiari come con vari gradi di parentela.
            </li>
            <li>
              In quest’ultimo caso bisogna quindi calcolare il periodo di
              possesso e determinare quindi se l’utilizzo come abitazione
              principale si è protratto per almeno la metà del tempo più un
              giorno.
            </li>
          </ul>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            In caso di immobile che deriva da una donazione invece, il calcolo
            dei 5 anni viene fatto a partire dalla data di acquisto o
            costruzione dell’immobile donato da parte del donante. In pratica,
            se un genitore compra una casa per il figlio e gliela regala, questi
            per non pagare la plusvalenza non deve venderla prima che siano
            trascorsi 5 anni da quando il genitore l’ha acquistata.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Quanto si paga
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Per la tassazione della plusvalenza è possibile utilizzare due
            metodi alternativi tra loro, che possono essere più o meno
            convenienti a seconda della situazione reddituale personale.
            Infatti, è possibile utilizzare:
          </p>
        </div>
        <div className="align-items-start">
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>La tassazione ordinaria, cioè le aliquote Irpef;</li>
            <li>La tassazione sostituiva pari al 26%.</li>
          </ul>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Nel caso in cui l’immobile venduto sia di proprietà di più persone,
            ognuno potrà scegliere di pagare l’imposta che risulta meno onerosa
            in base al proprio scaglione Irpef.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Come ridurre l’imposta
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            La tassazione sostituiva è certa nel suo importo, basta applicare il
            26% alla plusvalenza da tassare, l’utilizzo della tassazione
            ordinaria, invece, merita una valutazione più attenta. Infatti,
            poiché le aliquote Irpef variano da un minimo del 23% a un massimo
            del 43%, la percentuale di tassazione finale dipende dalla presenza
            di eventuali altri redditi. <hr /> Ad esempio, se un soggetto che
            possiede redditi da lavoro per 50.000,00 euro realizza una
            plusvalenza di 10.000,00 euro, l’aliquota che gli verrebbe applicata
            con la tassazione ordinaria sarebbe del 43%, mentre con l’imposta
            sostitutiva pagherebbe solo il 26%. Di fatto il risparmio con
            l’imposta sostitutiva sarebbe di 1.700,00 euro. <hr /> Allo stesso
            modo, se il soggetto guadagna 20.000,00 euro, l’aliquota Irpef
            ordinaria che verrebbe applicata è del 23% (dal 2024), quindi
            risulterebbe più conveniente rispetto alla sostitutiva del 26%, con
            un risparmio di 300 euro di imposte.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Come pagare la plusvalenza immobiliare
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            A seconda del regime di tassazione scelto è possibile utilizzare due
            metodi differenti di pagamento. Infatti, nel caso in cui si opti per
            la tassazione sostitutiva del 26% il pagamento avviene in sede di
            rogito notarile, sarà il notaio a fare da sostituto d’imposta e
            versare le imposte per conto del contribuente.
            <hr /> Se invece si opta per la tassazione ordinaria, bisogna
            inserirla in dichiarazione dei redditi. In questo caso, infatti,
            l’importo della plusvalenza viene indicato nella dichiarazione dei
            redditi annuale, tra i redditi diversi, e viene quindi tassata con
            le stesse modalità previste per i redditi da lavoro o da pensione e
            può esser rateizzata.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Plusvalenze e superbonus
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            La manovra di bilancio del 2024 ha previsto un inasprimento della
            tassazione della plusvalenza qualora l’immobile venduto sia stato
            ristrutturato utilizzando il superbonus. La manovra prevede due
            interventi che determinano l’aumento delle imposte sulla plusvalenza
            in caso di vendita di immobile:
          </p>
        </div>
        <div className="align-items-start">
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>
              Allargamento a 10 anni del periodo di riferimento per il calcolo
              della plusvalenza;
            </li>
            <li>
              Metodi diversi di conteggio delle spese di ristrutturazione ai
              fini del calcolo della plusvalenza.
            </li>
          </ul>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            In ogni caso però è possibile scegliere alternativamente tra la
            tassazione ordinaria e quella sostitutiva al 26%. Tutti gli altri
            tipi di ristrutturazione, dal bonus casa, all’ecobonus o al bonus
            facciate vengono conteggiati come oneri accessori e ricadono nella
            tassazione della plusvalenza come descritto precedentemente.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            La plusvalenza si valuta su 10 anni
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            La nuova norma prevede che per chi vende un immobile che è
            stato ristrutturato utilizzando il superbonus, la tassazione della
            plusvalenza è calcolata sui 10 anni precedenti. Di fatto si amplia
            l’orizzonte temporale durante il quale è possibile tassare la
            plusvalenza, mantenendo però le stesse cause di esenzione previste
            dalla normativa (immobili che derivano da una successione,
            destinazione ad abitazione principale per la maggior parte del
            tempo).
          </p>
        </div>

        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Il valore della ristrutturazione per la plusvalenza
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            In tutti i casi in cui l’immobile è stato ristrutturato con il
            superbonus al 110% e si è optato per la cessione del credito o lo
            sconto in fattura, le spese di ristrutturazione, ai fini del calcolo
            della plusvalenza vengono considerate nel seguente modo:
          </p>
        </div>
        <div className="align-items-start">
          <ul className="  colortext-blunav fs-5 line-height2 fw-bold ">
            <li>
              Per gli interventi realizzati tra i 5 e i 10 anni precedenti la
              vendita dell’immobile si considera solo il 50% del valore della
              ristrutturazione. Il prezzo di costruzione o di acquisto però deve
              essere rivalutato in base alla variazione dell’indice dei prezzi
              al consumo per le famiglie di operai e impiegati;
            </li>
            <li>
              Per gli interventi realizzati entro i 5 anni precedenti la
              vendita non si considera il valore della ristrutturazione.
            </li>
          </ul>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            In pratica, se un immobile, che non rientra nelle cause di
            esclusione, è stato ristrutturato con il 110% utilizzando la
            cessione del credito o lo sconto in fattura, viene venduto entro i
            10 anni, aumenta la base imponibile su cui calcolare le
            imposte. Infatti, si presume che la ristrutturazione abbia fatto
            aumentare notevolmente il valore dell’immobile che quindi si
            differenzi notevolmente dal costo sostenuto per l’acquisto dello
            stesso e, non potendo considerare come onere accessorio le spese di
            ristrutturazione, la plusvalenza che ne deriva è più alta.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Un esempio di plusvalenza e superbonus
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Pensiamo a un immobile acquistato per 100.000,00 euro nel 2018 che
            nel 2021 è stato ristrutturato con il superbonus al 110% utilizzando
            cessione del credito con un valore dell’intervento di 80.000,00
            euro. Se viene rivenduto 6 anni dopo, nel 2024 a 200,000,00 euro, la
            plusvalenza sarà calcolata come differenza tra il costo di vendita e
            il costo di acquisto maggiorato del 50% delle spese sostenute,
            quindi 40.000,00 euro. Di conseguenza le imposte saranno calcolate
            su 60.000,00 euro.
          </p>
        </div>
        <div className="mb-4">
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Viceversa, se il superbonus fosse rimasto in capo al proprietario,
            senza cessione del credito, la plusvalenza verrebbe conteggiata su
            20.000,00 euro, cioè come differenza tra il costo di acquisto,
            maggiorato del valore della ristrutturazione e il prezzo di vendita.
          </p>
        </div>
        <div className="mb-4">
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Da ultimo, se la ristrutturazione fosse stata fatta utilizzando
            altri tipi di bonus, ma non il superbonus, non ci sarebbe alcuna
            plusvalenza da tassare perché sono trascorsi più di 5 anni
            dall’acquisto.
          </p>
        </div>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article6Text;
