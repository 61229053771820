import React from "react";

const ErrorPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontWeight: "bold",
        fontSize: "3rem",
      }}
    >
      Opss this page not exist!!
    </div>
  );
};

export default ErrorPage;
