import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { deleteSingleUpdate } from "../../reducer/updateInvestSlice";
import { FaTrashAlt } from "react-icons/fa"; // Icona cestino
import { toast } from "react-toastify"; // Importare Toast

const DeleteUpdateModal = ({ updateId }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = async () => {
    try {
      await dispatch(deleteSingleUpdate(updateId)).unwrap();
      toast.success("Aggiornamento eliminato con successo!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      handleClose();
    } catch (error) {
      toast.error("Errore durante l'eliminazione dell'aggiornamento.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };
  return (
    <>
      <Button variant="danger" onClick={handleShow} className="ms-2">
        <FaTrashAlt /> {/* Icona del cestino */}
      </Button>

      {/* Modale di conferma */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma eliminazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sei sicuro di voler eliminare questo aggiornamento?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteUpdateModal;
