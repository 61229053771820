import React, { useEffect, useState } from "react";
import { Container, Row, Button, Form } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import SpinnerLoading from "../Components/SpinnerLoading";
import SingleInvestBachecaUser from "../Components/Investiment/SingleInvestBachecaUser";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvest,
  filterinvestName,
  filterByState,
} from "../reducer/investSlice";
import { MdSearch } from "react-icons/md";
import "../Style/pageSecondary.css";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import Aos from "aos";
import "aos/dist/aos.css";
import { FaArrowDown } from "react-icons/fa";

const BachecaInvestiment = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredState, setFilteredState] = useState(""); // Stato per tenere traccia del filtro attivo
  const [loading, setLoading] = useState(true); // Stato di caricamento
  const dispatch = useDispatch();

  const { investsArray } = useSelector((state) => state.invest);
  console.log(investsArray);

  useEffect(() => {
    Aos.init();
    setLoading(true); // Imposta il caricamento su true
    dispatch(getInvest()).finally(() => setLoading(false)); // Imposta il caricamento su false dopo la chiamata
  }, [dispatch]);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term === "") {
      dispatch(getInvest());
    } else {
      dispatch(filterinvestName(term));
    }
  };

  const filterInvestmentsByState = (state) => {
    setFilteredState(state);
    dispatch(filterByState(state));
  };

  const handleShowAll = () => {
    setFilteredState("");
    dispatch(getInvest());
  };

  return (
    <>
      <NavigationBar />
      <Container className="my-5 pt-5">
        <h1 className="text-center fw-bold colortext-blunav">
          AREA INVESTIMENTI
        </h1>
        <div className="colortext-blunav my-5 bg-white rounded shadow p-3">
          <p className="fw-bold fs-5 text-center">
            Benvenuto nella nostra area investimenti, dove potrai esplorare
            alcune delle nostre opportunità disponibili, suddivise per stato:
          </p>
          <ul className="d-flex flex-column gap-4">
            <li>
              <span className="bg-success fw-bold fs-5 rounded p-1 me-1">
                ATTIVO:
              </span>{" "}
              Informa che l'opportunità di investimento è 'aperta'. Questo
              significa che puoi unirti a noi e investire in questo progetto.
            </li>
            <li>
              <span className="bg-warning fw-bold fs-5 rounded p-1 me-1">
                IN CORSO:
              </span>{" "}
              Indica che l’operazione è attualmente in fase di sviluppo e non è
              più possibile effettuare nuovi investimenti.
            </li>
            <li>
              <span className="bg-grey fw-bold fs-5 rounded p-1 me-1">
                CONCLUSO:
              </span>{" "}
              Significa che l’operazione si è conclusa con successo e gli
              investitori hanno ricevuto il rendimento previsto.
            </li>
          </ul>
        </div>

        {/* Barra di ricerca */}
        <div className="d-flex justify-content-center align-items-center my-5">
          <MdSearch className="fs-1 me-2 colortext-blunav" />
          <Form.Group controlId="search" className="w-70 searchBarStyle">
            <Form.Control
              className="shadow"
              type="text"
              placeholder="Cerca per nome del progetto"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Form.Group>
        </div>

        {/* Pulsanti di filtro per stato */}
        <p className="text-center fw-bold colortext-blunav ">
          Filtra gli investimenti in base al loro stato
        </p>
        <div className="d-flex justify-content-center gap-3 mb-4">
          {!filteredState && (
            <>
              <Button
                variant="success"
                onClick={() => filterInvestmentsByState("ATTIVO")}
              >
                Attivi
              </Button>
              <Button
                variant="warning"
                onClick={() => filterInvestmentsByState("IN CORSO")}
              >
                In corso
              </Button>
              <Button
                variant="secondary"
                onClick={() => filterInvestmentsByState("CONCLUSO")}
              >
                Conclusi
              </Button>
            </>
          )}
          {(filteredState || !filteredState) && (
            <Button variant="primary" onClick={handleShowAll}>
              Mostra tutti
            </Button>
          )}
        </div>
      </Container>
      <Container>
        <Row className="d-flex justify-content-center align-items-center my-5">
          {loading ? ( // Mostra lo spinner se sta caricando
            <SpinnerLoading />
          ) : investsArray && investsArray.length > 0 ? ( // Mostra gli investimenti se esistono
            investsArray
              .slice()
              .reverse()
              .map((invest) => (
                <SingleInvestBachecaUser
                  key={nanoid()}
                  invest={invest}
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                />
              ))
          ) : (
            // Mostra il messaggio se non ci sono investimenti
            <div className="my-5 d-flex justify-content-center align-items-center">
              <p className="fw-bold fs-4">Nessun investimento disponibile</p>
            </div>
          )}
        </Row>
      </Container>
      <Container>
        <h2
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
          className="text-center bold-900 colortext-blunav fs-1"
        >
          Vuoi avere maggiori informazioni o vuoi partecipare ad un
          investimento?
        </h2>
        <p
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
          className="fw-bold text-center fs-4 colortext-blunav"
        >
          Non perderti questa occasione unica di avere una chiamata
          completamente gratuita per toglierti ogni dubbio e saperne di più sui
          nostri investimenti immobiliari
        </p>
      </Container>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <p className="fw-bold text-center p-2 fs-4 mt-5  colortext-blunav">
          Chiama ora il numero verde gratuito o contattaci via email
        </p>
      </div>
      <p className="text-center">
        <FaArrowDown className="fs-1 mt-1 colortext-blunav " />
      </p>
      <CallToActionNumberGreen />
      <Footer />
    </>
  );
};

export default BachecaInvestiment;
