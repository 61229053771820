import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import investSlice from "./investSlice";
import updateInvestSlice from "./updateInvestSlice";
import emailSlice from "./emailSlice";
const store = configureStore({
  reducer: {
    users: userSlice,
    invest: investSlice,
    updateInvest: updateInvestSlice,
    email: emailSlice,
  },
});

export default store;
