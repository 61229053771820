import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUpdateByInvest } from "../../reducer/updateInvestSlice";
import { useParams } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import { investById } from "../../reducer/investSlice";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import PatchUpdateModal from "./PatchUpdateModal";
import { useSession } from "../../middlewares/ProtectedRoutes";
import SpinnerLoading from "../SpinnerLoading";
import "../../Style/pageSecondary.css";
import DeleteUpdateModal from "./DeleteUpdateModal";
import { FaFilePdf } from "react-icons/fa6";

const UpdatesList = ({ investmentName }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { updateArray, status, error } = useSelector(
    (state) => state.updateInvest
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedUpdate, setSelectedUpdate] = useState(null);

  const session = useSession();

  useEffect(() => {
    dispatch(getUpdateByInvest(id));
    dispatch(investById(id));
  }, [dispatch, id]);

  const handleClose = () => setShowModal(false);
  const handleShow = (update) => {
    setSelectedUpdate(update);
    setShowModal(true);
  };

  if (status === "loading") {
    return (
      <div className="colorStarIcon fs-2 d-flex align-items-center justify-content-center gap-2">
        <SpinnerLoading /> Caricamento della tua modifica.. attendi qualche
        secondo...
      </div>
    );
  }

  if (error) {
    return (
      <div className="fs-3 text-white fst-italic text-center">{error}</div>
    );
  }

  return (
    <div>
      {updateArray
        .slice()
        .reverse()
        .map((update) => (
          <Card key={update._id} className="mb-3">
            <Card.Body>
              <Card.Title className="text-center my-3 fs-3 fw-bold">
                {update.title}
              </Card.Title>
              <Card.Text className="d-flex align-items-center justify-content-center preserve-line-breaks">
                <div className="width-70-LG">{update.text}</div>
              </Card.Text>

              {/* Display image or PDF if available */}
              {update.imageUpdate && (
                <div className="d-flex justify-content-center align-items-center mt-5 mb-3">
                  {update.imageUpdate.endsWith(".pdf") ? (
                    <div className="d-flex justify-content-center align-items-center mt-5 mb-3">
                      <a
                        href={update.imageUpdate}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none d-flex align-items-center justify-content-center"
                      >
                        <FaFilePdf className="fs-4 me-2 red-logo" />

                        <span className="fs-5"> Visualizza documento</span>
                      </a>
                    </div>
                  ) : (
                    <img
                      src={update.imageUpdate}
                      alt={update.title}
                      className="dimensionImageUpdate"
                    />
                  )}
                </div>
              )}

              <Card.Text className="mt-2">
                <small>
                  {format(new Date(update.createdAt), "dd MMMM yyyy", {
                    locale: it,
                  })}
                </small>
              </Card.Text>

              {session.role === "admin" && (
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    variant="secondary"
                    onClick={() => handleShow(update)}
                  >
                    Modifica
                  </Button>
                  <DeleteUpdateModal updateId={update._id} />
                </div>
              )}
            </Card.Body>
          </Card>
        ))}
      {selectedUpdate && session.role === "admin" && (
        <PatchUpdateModal
          show={showModal}
          handleClose={handleClose}
          update={selectedUpdate}
        />
      )}
    </div>
  );
};

export default UpdatesList;
