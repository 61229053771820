import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import article14Img from "../../asset/article14-img.png";

const Article14Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/articoli-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Come sono cambiati i mutui negli ultimi due anni</title>
        <meta
          name="description"
          content="Come sono cambiati i mutui negli ultimi due anni,Aumenta la preferenza per il tasso variabile, finalità del mutuo nel 2023"
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/cambiamento-mutui-ultimi-due-anni"
        />
      </Helmet>

      <BarraLaterale />
      <Container fluid className="mt-5 w-100 p-0 ">
        <img src={article14Img} alt="" className=" img-article-intro mt-4  " />
      </Container>
      <Container fluid className="py-5 color-blunav min-w100 ">
        <h1 className="  fs-1 text-white  text-center p-lg-5  ">
          Come sono cambiati i mutui negli ultimi due anni
        </h1>
      </Container>
      <Container className="my-4">
        <p className="colortext-blunav fs-5 line-height2 fw-bold text-center ">
          I mutui durano oltre 2 anni in più rispetto al 2022, con una crescita
          della preferenza per il tasso variabile e prevalenza di acquisti per
          la prima casa. Questi i trend più importanti del 2023 che emergono
          dall’analisi del mercato dei mutui di quest’anno rispetto a quello
          degli anni passati nel rapporto di Euroansa, società di mediazione del
          credito.
        </p>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-5">
          <h2 className="colortext-blunav fs-1  fw-bold text-center">
            Mutui più lunghi di due anni nel 2023
          </h2>
          <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
            Stando al rapporto di Euroansa, il dato più rilevante che emerge ha
            a che fare con la durata dei mutui: rispetto agli anni passati, il
            2023 vede la durata media dei mutui allungarsi e di molto. Se
            compariamo il 2022 con il 2023 vediamo che l’aumento della durata
            delle rate è di 25 mesi, oltre due anni. “Questo accade perché i
            tassi d’interesse si sono alzati e quindi, per riuscire a finanziare
            lo stesso importo di mutuo, c’è bisogno di più tempo.
          </p>
        </Container>
      </Container>
      <Container fluid className="mb-5 bgBluOpacity05 py-5">
        <Container>
          <div className="mb-4">
            <h2 className="colortext-blunav fs-1  fw-bold text-center">
              Importi erogati invariati rispetto al 2022
            </h2>
            <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center">
              L’allungamento della durata dei mutui si accompagna ad un importo
              medio che, invece, non è cambiato molto, a conferma del fatto che
              ora, per rimborsare lo stesso importo, ci sia bisogno di più
              tempo. Le somme erogate nel 2023 si attestano sui 131,2 mila
              euro e a differenza della durata, non sono molto diverse da quelle
              dell’anno scorso o della media degli ultimi anni. Nel 2022,
              infatti, si parlava di 130,5 mila euro, così come dal 2018 al
              2022. Le persone hanno potuto prendere circa la stessa quantità di
              denaro, ma per ripagarla hanno bisogno di dividerla in un numero
              maggiore di rate.
            </p>
          </div>
        </Container>
      </Container>
      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Aumenta la preferenza per il tasso variabile
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Raddoppiano rispetto al 2022 gli italiani che scelgono il tasso
          variabile al posto di quello fisso: se l’anno scorso, infatti, erano
          il 6,63%, nel 2023 arrivano a quasi il 14%, con un tasso fisso che,
          pur rimanendo il preferito degli italiani, cala nelle scelte dal 83%
          al 75%.Con l’aumento dei tassi, aumentano le persone che scelgono il
          tasso variabile perché sperano che con il passare del tempo la rata
          vada riducendosi. “Ma non è solo questo: il tasso variabile, non
          proteggendo l’acquirente dalla fluttuazione dei tassi, è più basso del
          fisso e nell’immediato questo permette a chi prende il mutuo di avere
          una migliore capacità di affrontare il pagamento”. Ma non
          solo “Attualmente - aggiunge Olivia Bernacchi, direttore commerciale
          di Euroansa - si sta verificando una diminuzione dell’IRS ( parametro
          di riferimento del tasso fisso). Le banche stanno già proponendo
          condizioni di tasso finito concorrenziali, questo comporterà, molto
          probabilmente, una inversione di scelta. È evidente che, in un
          contesto in continua evoluzione, la figura professionale del
          consulente del credito cresce di importanza nella scelta migliore da
          fare nei diversi momenti di mercato”.
        </p>
      </Container>

      <Container className="my-5">
        <h2 className="colortext-blunav fs-1  fw-bold text-center">
          Finalità del mutuo nel 2023
        </h2>
        <p className="  colortext-blunav fs-5 line-height2 fw-bold text-center mb-5">
          Nel 2023, infine, parlando di finalità del mutuo si è registrata
          una maggiore preferenza sui prestiti per l’acquisto della prima casa.
          Sono crollati surroghe, consolidamenti e sostituzioni del
          mutuo perché, non c’è motivo di sostituire un mutuo preso negli anni
          passati ad un tasso più basso quando oggi quello stesso mutuo avrebbe
          un tasso molto più alto.
        </p>
      </Container>
      <Container className="my-5">
        <Button
          onClick={redirectBlogOnClick}
          className="d-flex align-items-center justify-content-center button-redLogo"
        >
          <FaArrowLeft className="fs-4" />
          Indietro
        </Button>
      </Container>

      <Footer />
    </>
  );
};
export default Article14Text;
