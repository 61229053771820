// src/Pages/UpdatesPage.jsx
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UpdatesList from "../Components/UpdateInvest/UpdatesList";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import { investById } from "../reducer/investSlice";
import { useParams } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import { FaArrowDown } from "react-icons/fa";
import "../Style/pageSecondary.css";

const SectionPrivate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleInvest } = useSelector((state) => state.invest);

  useEffect(() => {
    dispatch(investById(id));
  }, [dispatch, id]);

  return (
    <>
      <NavigationBar />
      <Container fluid className="mt-5 py-5 color-blunav">
        <h1 className=" text-center title-card mt-5">
          Benvenuto nel Club per Investitori Premium
        </h1>
        <p className=" text-white text-center fs-5 mt-3 px-5">
          Qui potrai tenere traccia di come stanno procedendo i lavori del tuo
          investimento. Manderemo aggiornamenti ogni qual volta c'è ne saranno
          per tenerti sempre informato e partecipe. <hr />
        </p>
        <div className="container my-5 ">
          <h2 className="text-center text-white mb-5">
            Aggiornamenti dell'Investimento: {singleInvest.investsById?.name}
          </h2>
          <UpdatesList investmentName={singleInvest.investsById?.name} />
        </div>
        <Container>
          <p className=" text-white fs-4 text-center">
            Se hai dubbi o incomprensioni siamo sempre a tua disposizione <hr />{" "}
            Non esitare a contattarci chiamando il NUMERO VERDE GRATUITO o via
            EMAIL{" "}
          </p>
          <p className="text-center">
            <FaArrowDown className="fs-1 mt-1 text-white " />
          </p>
          <CallToActionNumberGreen />
        </Container>
      </Container>

      <Footer />
    </>
  );
};

export default SectionPrivate;
