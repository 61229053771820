import React from "react";
import Card from "react-bootstrap/Card";
import article3Img from "../../asset/article3-img.png";

import { Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Article3() {
  const navigate = useNavigate();

  const redirectArticle3 = () => {
    navigate("/case-green");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img
          className="img-card"
          variant="top"
          src={article3Img}
          alt="case-green"
          title="case-green"
          loading="lazy"
        />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Case green, l'Ue allenta le richieste e sposta i termini.
          </Card.Title>

          <Button onClick={redirectArticle3} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article3;
