import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import SinglePartecipantInvest from "./SinglePartecipantInvest";
import SpinnerLoading from "../SpinnerLoading";

const ParticipantsList = ({ participants, investId }) => {
  if (!participants) {
    return <SpinnerLoading />;
  }

  return (
    <Card>
      <ListGroup variant="flush">
        {participants.length > 0 ? (
          participants.map((participant, index) => (
            <SinglePartecipantInvest
              key={participant._id}
              participant={participant}
              index={index}
              investId={investId} // Passa l'id dell'investimento
            />
          ))
        ) : (
          <ListGroup.Item>Non ci sono partecipanti.</ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
};

export default ParticipantsList;
