import React from "react";
import { Col, Image, Button, Modal } from "react-bootstrap";

import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css"; // Assicurati di importare il file CSS

const SingleImageFinal = ({ imageUrl, index, investId }) => {
  React.useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Col
      xs={12}
      sm={12}
      md={12}
      lg={6}
      className="mb-5 position-relative" // Aggiungi la classe 'position-relative'
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-easing="ease-in-sine"
    >
      <Image
        src={imageUrl}
        alt={`Final Image ${index + 1}`}
        className="img-fluid rounded shadow-sm dimension-image-gallery"
      />
    </Col>
  );
};

export default SingleImageFinal;
