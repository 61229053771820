import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Card,
  Row,
  Modal,
  Button,
  CardBody,
} from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import SpinnerLoading from "../Components/SpinnerLoading";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteInvest,
  investById,
  addUserToInvest,
  getFinalImages,
} from "../reducer/investSlice";
import { useSession } from "../middlewares/ProtectedRoutes";
import { useParams } from "react-router-dom";
import { GiPositionMarker } from "react-icons/gi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoCashOutline } from "react-icons/io5";
import { FaMagnifyingGlassChart } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import PatchInvestimentModal from "../Components/Investiment/PatchInvestimentModal";
import ParticipantsList from "../Components/Investiment/PartecipantsList";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import ChangeImageInvest from "../Components/Investiment/ChangeImageInvest";
import { MdOutlineChangeCircle } from "react-icons/md";
import { MdOutlineContentPasteSearch } from "react-icons/md";
import ProgressBarComponent from "../Components/Investiment/ProgressBarComponent";
import NewUpdateModal from "../Components/UpdateInvest/NewUpdateModal";
import { FaArrowRight } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import PatchImageFinal from "../Components/Investiment/PatchImageFinal";
import { FaArrowLeft } from "react-icons/fa";
import UploadPDF from "../Components/Investiment/UploadPDF";
import PdfFilesList from "../Components/Investiment/PdfFilesList";

const DetailsInvestimentADMIN = () => {
  const [userId, setUserId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false); // Stato per la visibilità della modale dell'immagine
  const [searchTerm, setSearchTerm] = useState(""); // Stato per il filtro di ricerca
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [finalImages, setFinalImages] = useState([]);

  const { id } = useParams();
  const session = useSession();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirectInvestimentGestionOnClick = () => {
    navigate("/gestionInvestiment");
  };

  const { singleInvest } = useSelector((state) => state.invest);

  useEffect(() => {
    dispatch(investById(id)); // Carica i dati dell'investimento al montaggio del componente
    // Recupera le immagini finali all'inizializzazione del componente
    dispatch(getFinalImages(id));
  }, [dispatch, id]);

  const handleDeleteInvest = async () => {
    dispatch(deleteInvest(id)); // Elimina l'investimento con l'ID
    setShowDeleteModal(false);
    navigate("/gestionInvestiment");
    toast.success("Investimento eliminato!", {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleAddUser = async () => {
    if (!userId) {
      toast.error("Inserisci un ID utente valido!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!singleInvest.investsById) {
      toast.error("Errore nel recuperare i dati dell'investimento!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    // Controllo per verificare se l'utente è già partecipante
    const isUserAlreadyParticipant = singleInvest.investsById.participants.some(
      (participant) => {
        if (typeof participant === "object" && participant !== null) {
          return participant._id === userId;
        }
        return participant === userId;
      }
    );

    if (isUserAlreadyParticipant) {
      toast.error("Partecipante già presente nell'investimento!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    try {
      // Aggiungi utente all'investimento
      const result = await dispatch(
        addUserToInvest({ investId: id, userId })
      ).unwrap(); // unwrap estrae il risultato o genera un errore
      await dispatch(investById(id));
      setUserId("");

      toast.success("Utente aggiunto con successo!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      console.error(error);

      // Controllo generico per errori
      toast.error(
        "Errore nell'aggiungere l'utente, controlla l'ID e riprova!",
        {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  // Funzione per filtrare i partecipanti per cognome
  const filteredParticipants = singleInvest.investsById?.participants.filter(
    (participant) =>
      participant.surname?.toLowerCase().includes(searchTerm.toLowerCase()) // Filtra per cognome
  );

  return (
    <>
      <NavigationBar />
      <div className="mt-5 py-5 ps-2">
        <Button
          className="d-flex align-items-center justify-content-center button-redLogo"
          onClick={redirectInvestimentGestionOnClick}
        >
          <FaArrowLeft />
          Indietro
        </Button>
      </div>
      {session.role === "admin" ? (
        <div>
          {singleInvest.investsById ? (
            <>
              <Container>
                <h1 className="text-center fw-bold colortext-blunav">
                  {singleInvest.investsById ? (
                    <p>
                      <span>GESTIONE DELL'INVESTIMENTO:</span>{" "}
                      {singleInvest.investsById.name}
                    </p>
                  ) : (
                    <SpinnerLoading />
                  )}
                </h1>
                <div>
                  {singleInvest.investsById ? (
                    <img
                      className="w-100 rounded"
                      src={singleInvest.investsById.image}
                    />
                  ) : (
                    <SpinnerLoading />
                  )}
                  <div className=" d-flex align-items-center justify-content-end py-1">
                    <Button
                      className="buttonEditImage d-flex align-items-center justify-content-center ms-2"
                      variant="primary"
                      onClick={() => setShowImageModal(true)}
                    >
                      <MdOutlineChangeCircle className="fs-5 me-1" />
                      Cambia Immagine
                    </Button>
                  </div>
                </div>
                {singleInvest.investsById ? (
                  <Container className="bg-white m-0 text-center  py-5 overAuto">
                    <ChangeImageInvest
                      show={showImageModal}
                      handleClose={() => setShowImageModal(false)}
                      investId={id} // Passiamo l'ID dell'investimento
                    />
                    <div className=" d-flex flex-column justify-content-center align-items-center">
                      <p className="line-height2 me-2 text-start preserve-line-breaks width-70-LG p-3 colortext-blunav ">
                        {singleInvest.investsById.description}
                      </p>
                    </div>
                  </Container>
                ) : (
                  <SpinnerLoading />
                )}

                {singleInvest.investsById ? (
                  <Row>
                    <Col lg={12} className="fade-in-element">
                      <Card className="w-100 mb-4 shadow colortext-blunav fade-in">
                        <Card.Body>
                          <Card.Title className="text-center fw-bold mb-3 fs-1">
                            {singleInvest.investsById.name}
                          </Card.Title>
                          <Card.Text className=" d-flex flex-column gap-3">
                            <div className="d-flex justify-content-start align-items-center gap-2 fw-bold">
                              <GiPositionMarker className="fs-5" />{" "}
                              <span className="bold-600">Area:</span>{" "}
                              {singleInvest.investsById.area}
                            </div>
                            <div className="d-flex justify-content-start align-items-center gap-2 fw-bold">
                              <FaRegCalendarAlt className="fs-5" />{" "}
                              <span className="bold-600">
                                Tempo stimato dei lavori:
                              </span>{" "}
                              {singleInvest.investsById.duration}
                            </div>
                            <div className="d-flex justify-content-start align-items-center gap-2 fw-bold">
                              <IoCashOutline className="fs-5" />
                              <span className="bold-600">
                                Costo dell'intervento:
                              </span>{" "}
                              <span>
                                € {singleInvest.investsById.interventionAmount}
                              </span>
                            </div>
                            <div className="d-flex justify-content-start align-items-center gap-2 fw-bold">
                              <GiReceiveMoney className="fs-5" />{" "}
                              <span className="bold-600">ROI stimato:</span>{" "}
                              {singleInvest.investsById.roiEstimated}
                            </div>

                            {singleInvest.investsById.state === "CONCLUSO" && (
                              <section>
                                <div className="d-flex justify-content-start align-items-center gap-2 fw-bold">
                                  <p>
                                    <hr />
                                    <span className="bold-600 me-2">
                                      ROI MEDIO FINALE:
                                    </span>{" "}
                                    {singleInvest.investsById.roiFinal}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-start align-items-center gap-2 fw-bold">
                                  <p>
                                    <span className="bold-600 me-2">
                                      DURATA EFFETTIVA:
                                    </span>{" "}
                                    {singleInvest.investsById.durationEffective}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-start align-items-center gap-2 fw-bold">
                                  <p>
                                    <span className="bold-600 me-2">
                                      ROI ANNUO:
                                    </span>{" "}
                                    {singleInvest.investsById.roiAnnuo}
                                  </p>
                                </div>
                              </section>
                            )}
                            <div
                              className={`d-flex justify-content-center align-items-center fw-bold w-100  ${
                                singleInvest.investsById.state === "ATTIVO"
                                  ? "bg-success"
                                  : singleInvest.investsById.state ===
                                    "IN CORSO"
                                  ? "bg-warning"
                                  : ""
                              }`}
                            >
                              <span className="bold-600 me-1 p-3">
                                Stato dell'investimento:
                              </span>
                              {singleInvest.investsById.state}
                            </div>
                            {singleInvest.investsById &&
                            singleInvest.investsById.createdAt ? (
                              <div>
                                <span className="bold-600">
                                  Data di Creazione:
                                </span>{" "}
                                {format(
                                  new Date(singleInvest.investsById.createdAt),
                                  "dd MMMM yyyy",
                                  {
                                    locale: it,
                                  }
                                )}
                              </div>
                            ) : (
                              <p>Data non disponibile</p>
                            )}
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          <div className="my-2 px-3 d-flex align-items-end justify-content-end">
                            <PatchInvestimentModal
                              invest={singleInvest.investsById}
                            />
                            <Button
                              className="buttonDeleteAccount d-flex align-items-center justify-content-center"
                              variant="danger"
                              onClick={() => setShowDeleteModal(true)}
                            >
                              <FaTrashAlt />
                            </Button>
                          </div>
                        </Card.Footer>
                        <Modal
                          show={showDeleteModal}
                          onHide={() => setShowDeleteModal(false)}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Conferma Eliminazione investimento
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            Sei sicuro di voler eliminare l'investimento? Questa
                            azione non può essere annullata e perderai tutti i
                            dati dell'investimento in corso.
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={() => setShowDeleteModal(false)}
                            >
                              Annulla
                            </Button>
                            <Button
                              variant="danger"
                              onClick={handleDeleteInvest}
                            >
                              Elimina
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  <SpinnerLoading />
                )}
              </Container>
              <Container className="">
                {singleInvest.investsById ? (
                  <div>
                    <ProgressBarComponent
                      progress={singleInvest.investsById.progress}
                    />
                    <div className="d-flex justify-content-center align-items-center flex-column bg-white rounded py-3 shadow">
                      <Button onClick={() => setShowUpdateModal(true)}>
                        Crea Aggiornamento
                      </Button>

                      <NewUpdateModal
                        show={showUpdateModal}
                        handleClose={() => setShowUpdateModal(false)}
                        investId={singleInvest.investsById._id}
                      />
                      <Button
                        className="button-linkStyle fs-5 fw-bold d-flex justify-content-center align-items-center"
                        onClick={() => {
                          navigate(`/investments/${id}/updates`); // Naviga a una nuova pagina per gli aggiornamenti
                          window.scrollTo(0, 0); // Effettua lo scroll all'inizio della pagina
                        }}
                      >
                        Vedi Aggiornamenti
                        <FaArrowRight className="fs-4 ms-1" />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <SpinnerLoading /> // Mostra uno spinner finché i dati non sono disponibili
                )}
              </Container>
              <Container>
                <UploadPDF
                  investId={singleInvest.investsById._id}
                  pdfFiles={singleInvest.investsById.pdfFile}
                />
              </Container>

              <Container className="mt-5 pt-5">
                <h4 className="text-center fw-bold mb-4">
                  Gestione Partecipanti
                </h4>
                <Card>
                  <Card.Title className="text-center fw-bold mt-4 d-flex align-items-center justify-content-center">
                    <FaPlus className="fs-5 me-1" />
                    AGGIUNGI PARTECIPANTI
                  </Card.Title>
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <div className="w-100 d-flex align-items-center justify-content-center gap-4">
                      <input
                        className="w-50 text-center"
                        type="text"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        placeholder="Inserisci l'ID dell'utente da aggiungere"
                      />
                      <Button onClick={handleAddUser}>
                        {" "}
                        <FaPlus className="fs-5 me-1" /> Aggiungi
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Container>
              <Container className="mb-5">
                <p className="fw-bold text-center fs-5 bg-white m-0 pt-3">
                  LISTA INVESTITORI
                </p>
                <div className="w-100 d-flex align-items-center justify-content-center gap-1 pt-3 bg-white pb-2">
                  <MdOutlineContentPasteSearch className="fs-2" />

                  <input
                    className="w-50 text-center rounded"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} // Gestisce l'input per la ricerca
                    placeholder="Cerca per cognome"
                  />
                </div>
                {singleInvest.investsById &&
                  singleInvest.investsById.participants && (
                    <ParticipantsList
                      participants={filteredParticipants}
                      investId={id} // Passiamo l'id dell'investimento
                    />
                  )}
              </Container>

              <Container>
                <h3 className="text-center fs-3 fw-bold ">
                  Galleria di foto finali del progetto:
                </h3>
                {singleInvest.investsById &&
                singleInvest.investsById.finalImages ? (
                  <PatchImageFinal
                    investId={singleInvest.investsById._id}
                    finalImages={singleInvest.investsById.finalImages}
                  />
                ) : (
                  <SpinnerLoading />
                )}
              </Container>
            </>
          ) : (
            <SpinnerLoading />
          )}
        </div>
      ) : null}
      <Footer />
    </>
  );
};

export default DetailsInvestimentADMIN;
