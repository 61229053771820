import React, { useState } from "react";
import { Button, Card, Col } from "react-bootstrap";

import { GiPositionMarker } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";

import Aos from "aos";
import "aos/dist/aos.css";

import "../../Style/pageSecondary.css";
import ProgressBarComponent from "./ProgressBarComponent";

const SingleInvestiment = ({ invest }) => {
  const navigate = useNavigate();

  const redirectDetailsInvestimentOnClick = () => {
    navigate("/detailsInvestiment", { replace: true });
    window.scrollTo(0, 0);
  };

  return (
    <Col lg={4} md={6} sm={12} xs={12} className="fade-in-element ">
      <Card className="w-100 mb-4 shadow colortext-blunav hover-card fade-in">
        <div className="d-flex justify-content-center align-items-center ">
          <Card.Img
            className="custom-card-img"
            variant="top"
            src={invest.image}
          />
        </div>

        <Card.Body>
          <Card.Title className="text-center fw-bold mb-1">
            {invest.name}
          </Card.Title>
          <Card.Text className="text-center colortext-blunav">
            {invest.typeOfIntervent}
          </Card.Text>
          <Card.Text className="d-flex flex-column justify-content-around align-items-center gap-2">
            <div className="d-flex justify-content-center align-items-center gap-2 fw-bold fs-5">
              <GiPositionMarker className="fs-5" /> {invest.area}
            </div>
          </Card.Text>
        </Card.Body>
        <Card.Footer
          className={`d-flex justify-content-center align-items-center fw-bold ${
            invest.state === "ATTIVO"
              ? "bg-success"
              : invest.state === "IN CORSO"
              ? "bg-warning"
              : ""
          }`}
        >
          {invest.state}
        </Card.Footer>
        <Card.Footer className="d-flex justify-content-around align-items-start">
          <Link
            className="decorationNone"
            to={`/detailsInvestiment/${invest._id}`}
          >
            <Button
              onClick={redirectDetailsInvestimentOnClick}
              className="button-linkStyle"
            >
              Dettagli
            </Button>
          </Link>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default SingleInvestiment;
