import React from "react";
import { Button, Container } from "react-bootstrap";
import LogoPng from "../asset/logo-srl-png.png";
import "../Style/intro.css";
import background from "../asset/background-intro.png";
import backgroundMobile from "../asset/intro-mobile.png";
import { useNavigate } from "react-router-dom";
import ScrollToContactButton from "./ScrollToContactButton";

const Intro = () => {
  const introStyle = {
    backgroundImage: `url(${background})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "80vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const introStyleMobile = {
    backgroundImage: `url(${backgroundMobile})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",

    minHeight: "80vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const navigate = useNavigate();

  const redirectContactOnClick = () => {
    navigate("/contatti");
    window.scrollTo(0, 0);
  };

  return (
    <Container
      fluid
      style={window.innerWidth < 768 ? introStyleMobile : introStyle} // Usa introMobileStyle per schermi mobili
      className="overflowHiddenIntro"
    >
      <div className="mt-3 d-flex flex-column justify-content-end align-items-end color-title text-center w-100  overflowHiddenIntro logo-container">
        <img className="logo-welcome mt-5 pt-5 " src={LogoPng} alt="logo" />
      </div>
      <div className="my-5 pt-5 d-flex flex-column justify-content-end align-items-center color-title text-center  h-100 colortext-blunav">
        {/* <h1 className="mt-5 fw-bold ">
          Il nostro core businnes è l'acquistare asset immobiliare, valorizzarli
          e generare importanti rendimenti per i nostri investitori e partner{" "}
        </h1> */}

        <ScrollToContactButton />
      </div>
    </Container>
  );
};

export default Intro;
