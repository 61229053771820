import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const endpoint = process.env.REACT_APP_SERVER_BASE_URL;

const initialState = {
  updateArray: [],
  update: null,
  status: "idle",
  singleUpdate: {},
  error: null, // Aggiungi uno stato per l'errore
};

const updateInvestSlice = createSlice({
  name: "updateInvest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //CHIAMTA POST PER CREARE AGGIORNAMENTO
      .addCase(createUpdate.pending, (state) => {
        state.status = "loading";
        state.error = null; // Resetta l'errore quando inizia la chiamata
      })
      .addCase(createUpdate.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Aggiungi il nuovo aggiornamento all'array
        state.updateArray.push(action.payload);
      })
      .addCase(createUpdate.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload; // Salva il messaggio di errore
      })
      //CHIAMATA GET PER AVERE TUTTI GLI AGGIORNAMENTI DI UN INVESTIMENTO
      .addCase(getUpdateByInvest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUpdateByInvest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.updateArray = action.payload; // Imposta gli aggiornamenti ricevuti
      })
      .addCase(getUpdateByInvest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(patchSingleUpdate.pending, (state) => {
        state.status = "loading";
        state.error = null; // Resetta l'errore quando inizia la chiamata
      })
      .addCase(patchSingleUpdate.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Trova l'aggiornamento e sostituiscilo con quello modificato
        const index = state.updateArray.findIndex(
          (update) => update._id === action.payload._id
        );
        if (index !== -1) {
          state.updateArray[index] = action.payload;
        }
      })
      .addCase(patchSingleUpdate.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload; // Salva il messaggio di errore
      })
      // CHIAMATA DELETE PER ELIMINARE AGGIORNAMENTO
      .addCase(deleteSingleUpdate.pending, (state) => {
        state.status = "loading";
        state.error = null; // Resetta l'errore quando inizia la chiamata
      })
      .addCase(deleteSingleUpdate.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Rimuovi l'aggiornamento eliminato dall'array
        state.updateArray = state.updateArray.filter(
          (update) => update._id !== action.payload
        );
      })
      .addCase(deleteSingleUpdate.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload; // Salva il messaggio di errore
      });
  },
});
//CHIAMATA POST PER CREARE AGGIORNAMENTO
export const createUpdate = createAsyncThunk(
  "updateInvest/createUpdate",
  async (updateData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${endpoint}/updates/create-update`,
        updateData
      );
      return response.data.update; // Restituisci il nuovo aggiornamento
    } catch (error) {
      // Ritorna un messaggio di errore
      return rejectWithValue(
        error.response.data.error ||
          "Errore durante la creazione dell'aggiornamento"
      );
    }
  }
);

// CHIAMATA GET PER AVERE TUTTI GLI AGGIORNAMENTI DI UN INVESTIMENTO
export const getUpdateByInvest = createAsyncThunk(
  "updateInvest/getUpdateByInvest",
  async (investId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${endpoint}/updates/${investId}`);
      return response.data; // Restituisci gli aggiornamenti
    } catch (error) {
      return rejectWithValue(
        error.response.data.error ||
          "Nessun aggiornamento disponibile al momento."
      );
    }
  }
);
//CHIAMATA PATCH PER MODIFICARE AGGIORNAMENTO
export const patchSingleUpdate = createAsyncThunk(
  "updateInvest/patchSingleUpdate",
  async ({ id, updateData }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${endpoint}/updates/${id}`, // Assicurati che l'ID sia incluso nell'URL
        updateData
      );
      return response.data.update; // Restituisci l'aggiornamento modificato
    } catch (error) {
      return rejectWithValue(
        error.response.data.error ||
          "Errore durante la modifica dell'aggiornamento"
      );
    }
  }
);

// CHIAMATA DELETE PER ELIMINARE AGGIORNAMENTO
export const deleteSingleUpdate = createAsyncThunk(
  "updateInvest/deleteSingleUpdate",
  async (updateId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${endpoint}/updates/${updateId}`);
      return updateId; // Restituisci solo l'ID dell'aggiornamento eliminato
    } catch (error) {
      return rejectWithValue(
        error.response.data.error ||
          "Errore durante l'eliminazione dell'aggiornamento"
      );
    }
  }
);

export default updateInvestSlice.reducer;
