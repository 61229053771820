import React from "react";
import Card from "react-bootstrap/Card";
import article18Img from "../../asset/article18-img.png";

import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Article18() {
  const navigate = useNavigate();

  const redirectArticle18 = () => {
    navigate("/quanto-costa-donare-un-immobile-ad-un-parente");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article18Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Quanto costa donare un’immobile a un parente?
          </Card.Title>

          <Button onClick={redirectArticle18} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article18;
