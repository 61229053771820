import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateUser, userById } from "../../reducer/userSlice";
import { FaPencilAlt } from "react-icons/fa";
import { toast } from "react-toastify";

const PatchUserModal = ({ user }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: user.name,
    surname: user.surname,
    phoneNumber: user.phoneNumber,

    address: user.address,
    city: user.city,
    gender: user.gender,
    birthdayDate: user.birthdayDate,
    experienceInvestiment: user.experienceInvestiment,
    motivationToInvestiment: user.motivationToInvestiment,
    contactSociety: user.contactSociety,
    dispositionCapital: user.dispositionCapital,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(updateUser({ id: user._id, updates: formData }));
      await dispatch(userById(user._id)); // Ricarica il profilo utente dopo l'aggiornamento
      handleClose();
      toast.success("modifica effettuata!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        className="d-flex align-items-center justify-content-center me-4"
        onClick={handleShow}
      >
        <FaPencilAlt className="me-2" />
        Modifica Informazioni
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Modifica</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName" className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci il nome"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formSurname" className="mb-3">
              <Form.Label>Cognome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci il cognome"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formPhoneNumber" className="mb-3">
              <Form.Label>Numero di telefono</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci il numero di telefono"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formAddress" className="mb-3">
              <Form.Label>Indirizzo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inserisci l'indirizzo"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formAddress" className="mb-3">
              <Form.Label>Città di residenza</Form.Label>
              <Form.Control
                type="text"
                placeholder="inserisci la città"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formGender" className="mb-3">
              <Form.Label>Genere</Form.Label>
              <Form.Select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <option value="">Seleziona il genere</option>
                <option value="Maschio">Maschio</option>
                <option value="Femmina">Femmina</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formBirthdayDate" className="mb-3">
              <Form.Label>Data di nascita</Form.Label>
              <Form.Control
                type="date"
                name="birthdayDate"
                value={formData.birthdayDate}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formExperienceInvestiment" className="mb-3">
              <Form.Label>Esperienza di Investimento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Esperienza di Investimento"
                name="experienceInvestiment"
                value={formData.experienceInvestiment}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formMotivationToInvestiment"
              className="mb-3"
            >
              <Form.Label>Motivazione all'Investimento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Motivazione all'Investimento"
                name="motivationToInvestiment"
                value={formData.motivationToInvestiment}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formContactSociety" className="mb-3">
              <Form.Label>Contatto con Società</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contatto con Società"
                name="contactSociety"
                value={formData.contactSociety}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formDispositionCapital" className="mb-3">
              <Form.Label>Disposizione di Capitale</Form.Label>
              <Form.Control
                type="text"
                placeholder="Disposizione di Capitale"
                name="dispositionCapital"
                value={formData.dispositionCapital}
                onChange={handleChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mb-3">
              Salva Modifiche
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PatchUserModal;
