import React, { useRef } from "react";

import { GiPositionMarker } from "react-icons/gi";
import { MdOutlineEmail } from "react-icons/md";

import {
  Button,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  FormCheck,
} from "react-bootstrap";
import "../Style/styleForm.css";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

export const ContactFormSmall = () => {
  const onChange = () => {};

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_EMAILJS,
        "template_4j1ye6q",
        form.current,
        process.env.REACT_APP_PUBLIC_KEY_EMAILJS
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success(
            "Richiesta inoltrata con successo! Sarai ricontattata/o al piu presto dal nostro team. Controlla la tua email nelle prossime 24 ore",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        },

        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <section id="SectionForm" className="bgBluOpacity07 py-5">
        <Container className=" text-white  rounded mt-5   ">
          <Row className="d-flex justify-content-center align-items-start">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="d-flex flex-column justify-content-center align-items-center  mb-5 "
            >
              <h2 className="text-center text-white fs-1 my-3">
                Ti interessa saperne di più?
              </h2>
              <p className=" text-center fs-5 mb-5">
                Compila il form e verrai ricontattato dal nostro team
              </p>

              <div className="d-flex flex-column justify-content-center align-items-center gap-3 my-4 p-2">
                <GiPositionMarker className="icon-medie" />
                <span className="fs-3 text-center">
                  Piazzale G. delle Bande Nere 7, Milano 20146
                </span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center gap-3 mb-4">
                <MdOutlineEmail className="icon-medie" />
                <span className="fs-5">info@1122sviluppoimmobiliare.com</span>
              </div>
            </Col>
            <Col
              className="animated-gradientForm wd100Smarphone width50Pc mt-5 radius-10 "
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <h3 className="text-center fw-bold fs-2 my-3">Compila il form</h3>
              <form
                className="form-style  d-flex flex-column border-box p-3"
                ref={form}
                onSubmit={sendEmail}
              >
                <label>Nome </label>
                <input className="input-style" type="text" name="user_name" />
                <label>Cognome</label>
                <input
                  className="input-style"
                  type="text"
                  name="user_surname"
                />
                <label>Numero di telefono</label>
                <input
                  className="input-style"
                  type="number"
                  name="user_number"
                />
                <label>Email</label>
                <input className="input-style" type="email" name="user_email" />
                <label>La tua richiesta</label>
                <textarea className="input-style" name="message" />
                <div className=" d-flex justify-content-start align-items-center my-3">
                  <FormCheck />
                  <span className="mx-2">
                    Autorizzo il trattamento dei miei dati personali ai sensi
                    del Regolamento UE n. 679/2016
                  </span>
                </div>
                <div className=" d-flex justify-content-start align-items-center">
                  <FormCheck />
                  <span className="mx-2">
                    Autorizzo il trattamento dei dati per finalità di marketing,
                    attività promozionali, offerte commerciali e ricerche di
                    mercato.
                  </span>
                </div>
                <div className=" d-flex justify-content-center my-3">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onChange}
                    className=" "
                  />
                </div>
                <div className=" d-flex justify-content-center my-3">
                  <input
                    className="button-form px-3 py-2 fs-4 rounded"
                    type="submit"
                    value="Invia"
                  />
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ContactFormSmall;
