import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Accordion,
  Card,
  Row,
  Modal,
  Button,
} from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import SpinnerLoading from "../Components/SpinnerLoading";
import ContactFormSmall from "../Components/ContactFormSmall";
import { useDispatch, useSelector } from "react-redux";
import { userById, deleteUser } from "../reducer/userSlice";
import { useSession } from "../middlewares/ProtectedRoutes";
import { useParams } from "react-router-dom";
import { GiPositionMarker } from "react-icons/gi";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import "../Style/pageSecondary.css";
import { useNavigate } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import PatchUserModal from "../Components/UserBackendComponents/PatchUserModal";
import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import BarraLaterale from "../Components/BarraLaterale";
import ChangeImageProfileUser from "../Components/UserBackendComponents/ChangeImageProfileUser";
import { MdOutlineChangeCircle } from "react-icons/md";
import UserInvestments from "../Components/UserBackendComponents/UserInvestiment";
import DownloadPDFButton from "../Components/DownloadPDFButton";
import { BiSolidCity } from "react-icons/bi";
import { PiGenderIntersexFill } from "react-icons/pi";
import avatarVuotoImg from "../asset/img-avatarVuoto.jpg";

const ProfileUser = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChangeImageModal, setShowChangeImageModal] = useState(false); // Stato per il modal di cambio immagine
  // Funzione per formattare la data
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const session = useSession();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { singleUser } = useSelector((state) => state.users);

  useEffect(() => {
    // Chiamata per recuperare i dati dell'utente quando il componente è montato
    dispatch(userById(session.id)); // Supponendo che session.userId contenga l'ID dell'utente corrente
  }, [dispatch, session.id]);

  const { id } = useParams();

  const handleDeleteUser = async () => {
    dispatch(deleteUser(id)); // Utilizza l'ID dall'URL per eliminare l'utente
    localStorage.clear(); // Pulisce tutto il local storage
    setShowDeleteModal(false);
    navigate("/");
    toast.success("Account eliminato!", {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    // Potresti anche aggiungere una funzione per reindirizzare l'utente ad una pagina di logout o ad un'altra pagina appropriata dopo l'eliminazione dell'account
  };

  return (
    <>
      <NavigationBar />
      {session.role === "investitore" ? <BarraLaterale /> : ""}
      <Container fluid className=" bgBluOpacity05 py-5">
        <Container>
          <h1 className="text-center fw-bold my-5 pt-5 colortext-blunav ">
            IL MIO PROFILO
          </h1>
          {singleUser.usersById ? (
            <Row>
              <Col lg={8} md={12} sm={12} xs={12} className="fade-in-element  ">
                <Card className="w-100 mb-4 shadow colortext-blunav fade-in">
                  <div className="d-flex justify-content-center align-items-center my-2 flex-column">
                    <Card.Img
                      className="imgSingleUserGestion "
                      src={
                        singleUser.usersById.avatar
                          ? singleUser.usersById.avatar
                          : avatarVuotoImg
                      }
                    />
                    <Button
                      className="mt-1"
                      variant="primary"
                      style={{ bottom: "10px", right: "10px" }}
                      onClick={() => setShowChangeImageModal(true)}
                    >
                      <MdOutlineChangeCircle className="fs-4 me-1" />
                      Cambia Immagine
                    </Button>
                    {/* Modal per cambiare l'immagine del profilo */}
                    <ChangeImageProfileUser
                      show={showChangeImageModal}
                      handleClose={() => setShowChangeImageModal(false)}
                      userId={singleUser.usersById._id}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title className="text-center fw-bold mb-1">
                      {singleUser.usersById.name} {singleUser.usersById.surname}
                    </Card.Title>

                    <Card.Text className="d-flex flex-column justify-content-around align-items-start gap-2">
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <GiPositionMarker /> {singleUser.usersById.address}
                      </div>
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <BiSolidCity />
                        {singleUser.usersById.city}
                      </div>
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <LiaBirthdayCakeSolid />{" "}
                        {formatDate(singleUser.usersById.birthdayDate)}
                      </div>
                    </Card.Text>
                    <Card.Text className="d-flex flex-column justify-content-around align-items-start gap-2">
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <MdEmail /> {singleUser.usersById.email}
                      </div>
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <FaPhone />{" "}
                        <span>+ {singleUser.usersById.phoneNumber}</span>
                      </div>

                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <PiGenderIntersexFill />

                        {singleUser.usersById.gender}
                      </div>
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Accordion defaultActiveKey="0" flush>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Domande Aggiuntive</Accordion.Header>
                        <Accordion.Body className="zindexAccordion">
                          <div
                            className="d-flex flex-column  flex-justify-content-center align-items-start gap-2"
                            id="example-fade-text"
                          >
                            <p>
                              <span className="fw-bold me-2">
                                Esperienza investimenti:
                              </span>{" "}
                              {singleUser.usersById.experienceInvestiment}
                            </p>
                            <p>
                              <span className="fw-bold me-2">
                                Motivazione investimento:
                              </span>{" "}
                              {singleUser.usersById.motivationToInvestiment}
                            </p>
                            <p>
                              <span className="fw-bold me-2">
                                Come ci hai conosciuti:
                              </span>{" "}
                              {singleUser.usersById.contactSociety}
                            </p>
                            <p>
                              <span className="fw-bold me-2">
                                Capitale a disposizione:
                              </span>{" "}
                              {singleUser.usersById.dispositionCapital} €
                            </p>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Card.Footer>
                  <div className=" my-2 px-3 d-flex align-items-end justify-content-end">
                    <PatchUserModal user={singleUser.usersById} />
                    <Button
                      className="buttonDeleteAccount d-flex align-items-center justify-content-center"
                      variant="danger"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      {" "}
                      <FaTrashAlt />
                    </Button>
                  </div>
                  <Modal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Conferma Eliminazione Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Sei sicuro di voler eliminare il tuo account? Questa
                      azione non può essere annullata.
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => setShowDeleteModal(false)}
                      >
                        Annulla
                      </Button>
                      <Button variant="danger" onClick={handleDeleteUser}>
                        Elimina
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Card>
              </Col>
              <Col lg={4} md={12} sm={12} xs={12}>
                <Card className="fade-in">
                  <Card.Title className="text-center fw-bold mt-2">
                    I MIEI INVESTIMENTI
                  </Card.Title>
                  <Card.Body>
                    {singleUser.usersById ? (
                      // ... il tuo codice esistente
                      <UserInvestments userId={session.id} />
                    ) : (
                      <SpinnerLoading />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <SpinnerLoading />
          )}
        </Container>
        <Container className="d-flex flex-column align-items-center justify-content-center mt-5">
          <p className="text-center  bold-900 fs-4 colortext-blunav">
            Scarica ora il nostro Ebook gratuito per iniziare a scoprire il
            mondo degli investimenti immobiliari
          </p>
          <DownloadPDFButton />
        </Container>
      </Container>
      <CallToActionNumberGreen />
      <ContactFormSmall />
      <Footer />
    </>
  );
};

export default ProfileUser;
